import { message, Select } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createOrder, getOrderStatus, uploadFile } from '../../../utils/api';
import { QRCodeCanvas } from 'qrcode.react';
import styles from '../index.module.scss';
import { Input } from '../../../components/input';
import { Button } from '../../../components/button';
import { EditAddress } from '../../my-center/components/edit-address';
import { Panel } from '../../../components/panel';

interface Iprop {
  planId: string;
  evaluatorInfo: any;
  subjectInfo: any;
  levelIndex: number;
  sessionIndex: number;
  organizationId: string;
  isCrossLevel: boolean;
  crossLevelInfo: any;
  onPre?: () => void;
}

export const SubmitOrder = (props: Iprop) => {
  const {
    planId,
    evaluatorInfo,
    subjectInfo,
    levelIndex,
    sessionIndex,
    organizationId,
    isCrossLevel,
    crossLevelInfo,
    onPre
  } = props;
  const userInfo = useSelector((state: any) => state.userInfo);
  const [orderInfo, setOrderInfo] = useState<any>(null);
  const [payType, setPayType] = useState(2); // 1-微信支付 2-支付宝支付
  const [isLoading, setIsLoading] = useState(false);
  const [paySuccess, setPaySuccess] = useState(false);
  const iframe = useRef<any>(null);
  const [wxQRCodeValue, setWxQRCodeValue] = useState('');
  const navigate = useNavigate();
  const [showEditAddress, setShowEditAddress] = useState(false);

  useEffect(() => {
    // if (isCrossLevel) {
    //   submitOrder();
    // }
  }, []);

  const submitOrder = () => {
    let param = {
      planId,
      subjectId: subjectInfo.subjectId,
      subjectLevelId: subjectInfo.subjectLevels[levelIndex].subjectLevelId,
      level: subjectInfo.subjectLevels[levelIndex].level,
      sessionsId: subjectInfo.subjectLevels[levelIndex].sessionsList[sessionIndex].sessionsId,
      evaluatorId: evaluatorInfo.evaluatorId,
      evaluatorName: evaluatorInfo.evaluatorName,
      userId: userInfo.id,
      payType,
      osType: '3', // 3-PC
      deviceType: 'web',
      payFrom: 4,
      organizationId, // 机构ID 个人报名传0，机构报名传具体ID
    }
    if (isCrossLevel) {
      param = { ...param, ...crossLevelInfo };
    }
    setIsLoading(true);
    createOrder(param).then((result: any) => {
      setIsLoading(false);
      setOrderInfo(result.body);
      // if (isCrossLevel) {
      //   setPaySuccess(true);
      // } else {
        if (payType === 1) {
          setWxQRCodeValue(result.body.payInfo);
        }
        if (payType === 2) {
          const doc = iframe.current.contentWindow.document;
          doc.open();
          doc.write(result.body.payInfo);
          doc.close();
        }
      // }
    }).catch((error: any) => {
      setIsLoading(false);
      message.error(error.msg);
    });
  };

  const checkOrderStatus = () => {
    const param = {
      orderId: orderInfo.orderId,
      evaluatorId: evaluatorInfo.evaluatorId,
      userId: userInfo.id,
    };
    getOrderStatus(param).then((result: any) => {
      // result.body.status 支付状态（0-未支付 1-待审核 2-审核失败 3-已支付 4-订单取消）
      if (result.body.status === 3) {
        setPaySuccess(true);
      } else {
        message.error([
          '订单还未支付，请扫码支付',
          '订单待审核，请稍后再试',
          '订单审核失败，请联系客服',
          '订单已支付',
          '订单已取消，请重新下单',
        ][result.body.status]);
      }
    }).catch((error: any) => {
      message.error(error.msg);
    });
  };

  return (
    <div className={styles.form_wrapper} style={{width:orderInfo ? 515 : 255}}>
      {
        orderInfo ? (
          <div className={styles.success_bar}>
            {/* {paySuccess ? (isCrossLevel ? '订单已提交审核，请耐心等待' : '支付成功') : '订单提交成功，请尽快付款'} */}
            {paySuccess ? '支付成功' : '订单提交成功，请尽快付款'}
          </div>
        ) : null
      }
      {
        !paySuccess ? (
          <>
            <div className={styles.order_info}>
              <div className={styles.left_side}>
                {
                  // isCrossLevel ? null : (
                    <>
                      <div className={styles.row}>
                        <span className={styles.label}>应付金额：</span>
                        <span className={styles.price}>¥{(subjectInfo.subjectLevels[levelIndex].amount/100).toFixed(2)}</span>
                      </div>
                      {
                        orderInfo ? (
                          <div className={styles.row}>
                            <span className={styles.label}>订单编号：</span>
                            <span className={styles.value}>{orderInfo.orderId}</span>
                          </div>
                        ) : null
                      }
                      <div className={styles.row}>
                        <span className={styles.label}>{orderInfo ? '' : '请选择'}支付方式：</span>
                      </div>
                      <div className={orderInfo ? styles.disable : ''}>
                        <div
                          className={`${styles.pay_type} ${styles.wechat} ${payType===1 ? styles.active : ''}`}
                          onClick={() => setPayType(1)}
                        >
                          微信支付
                        </div>
                        <div
                          className={`${styles.pay_type} ${styles.zfb} ${payType===2 ? styles.active : ''}`}
                          onClick={() => setPayType(2)}
                        >
                          支付宝支付
                        </div>
                      </div>
                    </>
                  // )
                }
              </div>
              <div className={styles.right_side} style={{display:orderInfo ? 'flex' : 'none'}}>
                {
                  payType === 1 ? (
                    <div className={styles.qrcode}>
                      <QRCodeCanvas value={wxQRCodeValue} size={184} />
                    </div>
                  ) : null
                }
                {
                  payType === 2 ? (
                    <div className={styles.frame_border}>
                      <iframe frameBorder={0} ref={iframe} />
                    </div>
                  ) : null
                }
              </div>
            </div>
            <div
              className={`${styles.btn_next} ${isLoading ? styles.disable : ''}`}
              style={{width:240, margin:'0 auto'}}
              onClick={() => orderInfo ? checkOrderStatus() : submitOrder()}
              >
                {/* { orderInfo ? '确认已支付' : (isLoading ? '处理中...' : (isCrossLevel ? '提交审核' : '支付')) } */}
                { orderInfo ? '确认已支付' : (isLoading ? '处理中...' : '支付') }
            </div>
            {
              // orderInfo || isCrossLevel ? null : (
                orderInfo ? null : (
                <div className={styles.form_item} style={{marginTop:24}}>
                  <div className={styles.btn_tip_bar}>
                    <div className={styles.btn_tip} onClick={() => onPre && onPre()}>
                      <span className={`${styles.link} ${styles.arrow}`}>返回上一步</span>
                    </div>
                  </div>
                </div>
              )
            }
          </>
        ) : (
          <>
            <div
              className={styles.order_info}
              style={{
                alignItems: 'center',
                flexDirection:'column',
              }}
            >
              {
                // !isCrossLevel ? (
                  <div className={styles.row}>
                    <span className={styles.label}>支付金额：</span>
                    <span className={styles.price}>¥{(subjectInfo.subjectLevels[levelIndex].amount/100).toFixed(2)}</span>
                  </div>
                // ) : null
              }
              <div className={styles.row}>
                <span className={styles.label}>订单编号：</span>
                <span className={styles.value}>{orderInfo.orderId}</span>
              </div>
              {
                // !isCrossLevel ? (
                  <div className={styles.row}>
                    <span className={styles.label}>支付方式：</span>
                    <span className={styles.value}>{['微信支付', '支付宝支付'][payType-1]}</span>
                  </div>
                // ) : null
              }
            </div>
            <div className={styles.paysuccess_guide}>
              <Button type="outline" text="查看报名订单" width={240} onClick={() => navigate('/orderlist', { replace: true })} />
              <div className={styles.important_tip}>【请重视！】请务必认真填写邮寄地址，以免影响证书邮寄。</div>
              <Button
                text="填写证书邮寄地址"
                size="big"
                width={240}
                onClick={() => setShowEditAddress(true)} />
            </div>
            {
              showEditAddress ? (
                <Panel
                  title="填写证书邮寄地址"
                  width={560}
                  onClose={() => setShowEditAddress(false)}
                >
                  <EditAddress
                    showCancel
                    onCancel={() => setShowEditAddress(false)}
                    onSuccess={() => setShowEditAddress(false)}
                  />
                </Panel>
              ) : null
            }
          </>
        )
      }
    </div>
  );
};
