import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';

export interface IMonitorInfo {
  exitFullScreen: number;
  documentHidden: number;
  offline: number;
}

let _monitorInfo: IMonitorInfo = {
  exitFullScreen: 0,
  documentHidden: 0,
  offline: 0,
};

export enum MonitorType {
  EXIT_FULLSCREEN = 'exitFullScreen',
  DOCUMENT_HIDDEN = 'documentHidden',
  OFFLINR = 'offline',
}

interface Iprop {
  onExitFullScreen?: () => void;
  onChange?: (type: MonitorType, value: IMonitorInfo) => void;
}

/**
 * 考试中的异常监测组件
 * @returns JSX
 */
export const ExamMonitor = (prop: Iprop) => {
  const { onExitFullScreen, onChange } = prop;
  const [monitorInfo, setMonitorInfo] = useState<IMonitorInfo>(_monitorInfo);

  useEffect(() => {
    document.addEventListener('fullscreenchange', onFullScreenChange);
    window.addEventListener("visibilitychange", onDocumentHideChange);
    window.addEventListener('online', onOnlineChange);
    window.addEventListener('offline', onOnlineChange);
    return () => {
      document.removeEventListener('fullscreenchange', onFullScreenChange);
      window.removeEventListener("visibilitychange", onDocumentHideChange);
      window.removeEventListener('online', onOnlineChange);
      window.removeEventListener('offline', onOnlineChange);
      _monitorInfo = {
        exitFullScreen: 0,
        documentHidden: 0,
        offline: 0,
      };
    }
  }, []);

  const onFullScreenChange = () => {
    const isFullScreen = document.fullscreenElement !== null;
    if (!isFullScreen) {
      _monitorInfo = {
        ..._monitorInfo,
        exitFullScreen: _monitorInfo.exitFullScreen + 1
      };
      setMonitorInfo(_monitorInfo);
      onChange && onChange(MonitorType.EXIT_FULLSCREEN, _monitorInfo);
    }
  };

  const onDocumentHideChange = () => {
    if (document.hidden) {
      _monitorInfo = {
        ..._monitorInfo,
        documentHidden: _monitorInfo.documentHidden + 1
      };
      setMonitorInfo(_monitorInfo);
      onChange && onChange(MonitorType.DOCUMENT_HIDDEN, _monitorInfo);
    }
  };

  const onOnlineChange = () => {
    if (!navigator.onLine) {
      _monitorInfo = {
        ..._monitorInfo,
        offline: _monitorInfo.offline + 1
      };
      setMonitorInfo(_monitorInfo);
      onChange && onChange(MonitorType.OFFLINR, _monitorInfo);
    }
  };

  return <></>;
};
