export const menus: any[] = [
  {
    name: '首页',
    route: '/',
  },
  {
    name: '测评报名',
    route: '/enter-online_parent',
    children: [
      {
        name: '个人报名',
        route: '/enter-online',
      },
      {
        name: '团体报名',
        route: '/?posto=groupchannel',
      }
    ],
  },
  {
    name: '成绩查询',
    route: '/examination',
    children: [
      {
        name: '成绩查询',
        route: '/examination',
      },
      {
        name: '证书查询',
        route: '/certificate-query', // 路由待定
      }
    ],
  },
  {
    name: '关于测评',
    route: '/about',
  },
  {
    name: '在线测评',
    route: '/onlinetest',
    children: [
      {
        name: '正式测评',
        route: '/examination',
      },
      {
        name: '模拟练习',
        route: '/exam-mock',
      }
    ],
  },
];
