import React, { useEffect } from 'react';
import styles from './index.module.scss';

interface Iprop {
  title: string;
  width?: number;
  alignTop?: boolean;
  children?: any;
  topChildren?: any;
  onClose?: () => void;
}
      
export const Panel = (props: Iprop) => {
  const { title, width=633, alignTop=false, topChildren, children, onClose } = props;

  useEffect(() => {
    document.body.style.height = '100%';
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.height = 'auto';
      document.body.style.overflow = 'inherit';
    }
  }, []);

  return (
    <div className={`${styles.wrapper} ${alignTop ? styles.align_top : ''}`}>
      { topChildren }
      <div className={styles.panel} style={{width}}>
        <div className={styles.btn_close} onClick={() => onClose && onClose()} />
        <div className={styles.form_title}>{title}</div>
        <div className={styles.content}>
          {children}
        </div>
      </div>
    </div>
  );
};
