import { message, Modal } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ActionType } from "../../store";
import { exitFullScreen, getDisplayMediaVideo, requestFullscreen } from "../../utils/util";
import { ExamMonitor, MonitorType } from "../exam-monitor";
import { AliRTS } from 'aliyun-rts-sdk';
import styles from './index.module.scss';

interface Iprop {

}

let screenTrack: any = null;
let audioTrack: any = null;
const aliRts: any = AliRTS.createClient();

export const ScreenSharePublisher = (props: Iprop) => {
  const publishInfo = useSelector((state: any) => state.publishInfo);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      // 此组件被销毁时：离开测评相关页面；
      clearTracks();
      dispatch({
        type: ActionType.UPDATE_PUBLISHINFO,
        value: { url: '' },
      });
      exitFullScreen();
    };
  }, []);

  useEffect(() => {
    console.log('推流地址改变：', publishInfo.url);
    if (publishInfo.url) {
      // 推流地址改变：
      if (window.location.pathname === '/exam-formal') {
        // case2: 进入考试发现推流地址为空时弹出警告
        onEnvError('screenshare');
      } else {
        // case1: 环境检测点击屏幕分享时；
        startShareScreen(false);
      }
    } else {
      // 推流地址被清空（已交卷的场景）
      clearTracks();
    }
  }, [publishInfo.url]);

  const clearTracks = () => {
    if (screenTrack) {
      screenTrack.onended = null;
      screenTrack.stop();
      screenTrack = null;
    }
    if (audioTrack) {
      audioTrack.stop();
      audioTrack = null;
    }
    dispatch({
      type: ActionType.UPDATE_TRACKS,
      value: { screen: null, audio: null },
    });
    try {
      aliRts.unpublish();
      // message.info('推流已停止');
    } catch (error) {}
  };

  const startShareScreen = (fullscreenOnSuccess: boolean = true) => {
    if (screenTrack) {
      return;
    }
    if (publishInfo.url === 'mock') {
      getDisplayMediaVideo().then((stream: any) => {
        const tracks =  stream.getTracks();
        screenTrack = tracks[0];
        // audioTrack = tracks[1]; // 正式推流才有音频track
        dispatch({
          type: ActionType.UPDATE_TRACKS,
          value: { screen: screenTrack, audio: null },
        });
        Modal.destroyAll();
        if (fullscreenOnSuccess) {
          requestFullscreen(() => {
            Modal.info({
              content: `您刷新了页面，全屏未开启，请点击“打开全屏”`,
              okText: '打开全屏',
              onOk: () => requestFullscreen(),
            });
          });
        }
        screenTrack.onended = () => {
          screenTrack.onended = null;
          screenTrack.stop();
          screenTrack = null;
          // 如果存在推流地址，说明是用户手动停止屏幕共享
          if (publishInfo.url) {
            if (window.location.pathname === '/exam-formal') {
              onEnvError('screenshare');
            } else {
              dispatch({
                type: ActionType.UPDATE_PUBLISHINFO,
                value: { url: '' },
              });
            }
          }
        }
      }).catch(() => {
        if (window.location.pathname === '/exam-formal') {
          onEnvError('screenshare');
        } else {
          dispatch({
            type: ActionType.UPDATE_PUBLISHINFO,
            value: { url: '' },
          });
        }
      });
    } else {
      AliRTS.createStream({
        audio: true,
        video: false,
        screen: true,
      }).then((localStream: any) => {
        screenTrack = localStream.videotrack; // 录屏视频流
        audioTrack = localStream.audiotrack; // 麦克风音频流
        dispatch({
          type: ActionType.UPDATE_TRACKS,
          value: { screen: screenTrack, audio: audioTrack },
        });
        Modal.destroyAll();
        if (fullscreenOnSuccess) {
          requestFullscreen(() => {
            Modal.info({
              content: `您刷新了页面，全屏未开启，请点击“打开全屏”`,
              okText: '打开全屏',
              onOk: () => requestFullscreen(),
            });
          });
        }
        aliRts.publish( // 开始推流
          publishInfo.url,
          localStream,
        ).then(() => {
          message.success('录屏已开始');
        }).catch((error: any) => {
          audioTrack.stop();
          audioTrack = null;
          screenTrack.onended = null;
          screenTrack.stop();
          screenTrack = null;
          if (window.location.pathname === '/exam-formal') {
            onEnvError('screenshare');
          } else {
            dispatch({
              type: ActionType.UPDATE_PUBLISHINFO,
              value: { url: '' },
            });
          }
          message.error(`推流失败 ${error.errorCode} ${error.message}`);
        });
        // 监听录屏结束
        screenTrack.onended = () => {
          audioTrack.stop();
          audioTrack = null;
          screenTrack.onended = null;
          screenTrack.stop();
          screenTrack = null;
          // 如果存在推流地址，说明是用户手动停止屏幕共享
          if (publishInfo.url) {
            if (window.location.pathname === '/exam-formal') {
              onEnvError('screenshare');
            } else {
              dispatch({
                type: ActionType.UPDATE_PUBLISHINFO,
                value: { url: '' },
              });
            }
          }
          try {
            aliRts.unpublish();
            // message.info('推流已停止');
          } catch (error) {} // 停止推流
        }
      }).catch((error: any) => {
        // message.error(`创建本地流失败 ${error.errorCode} ${error.message}`);
        // 如果存在推流地址，说明是用户手动停止屏幕共享
        if (publishInfo.url) {
          if (window.location.pathname === '/exam-formal') {
            onEnvError('screenshare');
          } else {
            dispatch({
              type: ActionType.UPDATE_PUBLISHINFO,
              value: { url: '' },
            });
          }
        }
      })
    }
  };

  const onEnvError = (type: 'screenshare' | 'documenthide' | 'fullscreen' | 'offline') => {
    if (type !== 'screenshare' && !screenTrack) {
      return;
    }
    if (window.location.pathname !== '/exam-formal') {
      return;
    }
    const errorName = {
      screenshare: '退出屏幕共享',
      documenthide: '离开当前测评界面',
      fullscreen: '退出全屏',
      offline: '断开网络',
    };
    Modal.destroyAll();
    Modal.info({
      title: '异常警告',
      content: `异常原因：${errorName[type]}！\n异常警告：测评过程中不得${errorName[type]}，若${errorName[type]}将严重影响测评成绩，情节严重直接作废此次测评资格。\n点击下方“我已知晓”回到考试。`,
      okText: '我已知晓',
      onOk: () => {
        if (type === 'screenshare') {
          startShareScreen();
        } else {
          requestFullscreen();
        }
      },
    });
    const errorMap = { screenshare:0, offline:1, documenthide:2, fullscreen:2 };
    dispatch({
      type: ActionType.ADD_EXAM_ERROR,
      value: { type: errorMap[type], time: (new Date()).getTime() },
    });
  };

  return (
    <ExamMonitor
      onExitFullScreen={() => requestFullscreen()}
      onChange={(type) => {
        if (type === MonitorType.DOCUMENT_HIDDEN) {
          onEnvError('documenthide');
        }
        if (type === MonitorType.EXIT_FULLSCREEN) {
          onEnvError('fullscreen');
        }
        if (type === MonitorType.OFFLINR) {
          onEnvError('offline');
        }
      }}
    />
  );
};
