import { message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Empty } from '../../components/empty';
import { PageBar } from '../../components/page-bar';
import { getEvaluatorList } from '../../utils/api';
import styles from './index.module.scss';
      
export const Evaluator = (props: any) => {
  const navigate = useNavigate();
  const [userList, setUserList] = useState<any[]>([]);

  useEffect(() => {
    getEvaluatorData();
  }, []);

  const getEvaluatorData = () => {
    getEvaluatorList({}).then((result: any) => {
      setUserList(result.body.list || []);
    }).catch((error: any) => message.error(error.msg));
  };

  const gotoEdit = (user: any = null) => {
    navigate('/evaluator-edit', { state: user });
  };

  return (
    <div className={styles.wrapper}>
      <PageBar title="测评人信息" />
      <div className={`${styles.content_area} ${userList.length==0 ? styles.empty : ''}`}>
        {
          userList.length > 0 ? (
            <>
              <div className={styles.user_list}>
              {
                userList.map((user: any) => (
                  <div className={styles.user} key={user.evaluatorId} onClick={() => gotoEdit(user)}>
                    <img className={styles.avator} src={user.avatar} alt="测评人头像" />
                    <div className={styles.info}>
                      <div className={styles.label}>姓名</div>
                      <div className={styles.username}>{user.evaluatorName}</div>
                    </div>
                  </div>
                ))
              }
              </div>
              <div className={styles.btn_add} onClick={() => gotoEdit()}>添加测评人</div>
            </>
          ) : (
            <Empty
              message="当前无测评人信息"
              btnText="添加测评人"
              onBtnClick={() => gotoEdit()}
            />
          )
        }
      </div>
    </div>
  );
};
