import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../../components/button';
import { getOrganizationList } from '../../../../utils/api';
import styles from './index.module.scss';

interface Iprop {
  userInfo: any;
  onConfirm?: () => void;
}

export const CheckUser = (props: Iprop) => {
  const navigate = useNavigate();
  const { userInfo, onConfirm } = props;
  const [organizations, setOrganizations] = useState<any[]>([]);

  useEffect(() => {
    getOrganizationData();
  }, []);

  const getOrganizationData = () => {
    getOrganizationList({}).then((result: any) => {
      let list = result.body || [];
      setOrganizations(list);
    }).catch((error: any) => {
      console.log('获取机构列表失败', error);
    });
  };

  const getOrganizationName = (id: any) => {
    if (id && id !== 0 && organizations.length > 0) {
      const theorg = organizations.find((org: any) => org.id === id);
      return theorg ? theorg.name : '团体报名'
    } else {
      return '个人报名';
    }
  };

  const gotoUserEdit = () => {
    navigate('/evaluator-edit', { state: userInfo });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.form_item} style={{alignItems:'flex-start'}}>
        <div className={styles.label} style={{marginTop:9}}>本人照片</div>
        <div className={styles.photo_wrapper}>
          <img className={styles.photo} src={userInfo.avatar} />
          <p className={styles.photo_tip}>此照片将是您的测评证照片和证书照片（如通过测评），请务必按要求提供！</p>
        </div>
      </div>
      <div className={styles.form_item}>
        <div className={styles.label}>姓名</div>
        <div className={styles.input}>{userInfo.evaluatorName}</div>
      </div>
      <div className={styles.form_item}>
        <div className={styles.label}>证件类型</div>
        <div className={styles.input}>{['','居民身份证','港澳通行证', '台湾通行证', '护照'][userInfo.idType]}</div>
      </div>
      <div className={styles.form_item}>
        <div className={styles.label}>证件号码</div>
        <div className={styles.input}>{userInfo.cardNumber}</div>
      </div>
      {/* <div className={styles.form_item}>
        <div className={styles.label}>性别</div>
        <div className={styles.input}>{['女','男'][userInfo.sex]}</div>
      </div> */}
      {/* <div className={styles.form_item}>
        <div className={styles.label}>所在地区</div>
        <div className={styles.input}>
          {`${userInfo.province}/${userInfo.city}/${userInfo.area}`}
        </div>
      </div> */}
      {/* <div className={styles.form_item}>
        <div className={styles.label}>详细住址</div>
        <div className={styles.input}>{userInfo.address}</div>
      </div> */}
      {/* <div className={styles.form_item}>
        <div className={styles.label}>就读学校</div>
        <div className={styles.input}>{userInfo.schoolName}</div>
        <div className={styles.tip}>请准确填写所在地区和详细地址，将用于邮寄等级证书。</div>
      </div> */}
      <div className={styles.form_item}>
        <div className={styles.label}>报名团体</div>
        <div className={styles.input}>{getOrganizationName(userInfo.organizationId)}</div>
        <div className={styles.tip}>如您是团体报名的学生，请务必选择您的团体名称</div>
      </div>
      {/* <div className={styles.form_item}>
        <div className={styles.label}>紧急联系人</div>
        <div className={styles.input}>{userInfo.liaison}</div>
      </div> */}
      {/* <div className={styles.form_item}>
        <div className={styles.label}>联系人号码</div>
        <div className={styles.input}>{userInfo.liaisonPhone}</div>
        <div className={styles.tip}>此手机号仅作为邮寄证书使用，不作为登录账号。</div>
      </div> */}
      <div className={styles.form_item} style={{marginTop:60, marginBottom:12}}>
        <div className={styles.label_empty}></div>
        <Button text="确认无误" onClick={onConfirm} size="big" />
      </div>
      <div className={styles.form_item} style={{marginTop:0}}>
        <div className={styles.label_empty}></div>
        {/* <div className={styles.btn_tip_bar}>
          <span className={styles.icon} />
          <div className={styles.btn_tip}>
            如报名身份信息有误，请前往
            <span className={styles.link} onClick={gotoUserEdit}>个人资料</span>
            处重新认证。报名成功后信息将不允许修改。
          </div>
        </div> */}
      </div>
    </div>
  );
};
