import { message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Empty } from '../../components/empty';
import { NavigationBar } from '../../modules/navigation-bar';
import { createMock, getUserSubject } from '../../utils/api';
import { subjectCover, SubjectLevelName } from '../../utils/constant';
import styles from './index.module.scss';

interface Iprop {

}

export const ExamMockStart = (props: Iprop) => {
  const location = useLocation();
  const navigate = useNavigate();
  const userInfo = useSelector((state: any) => state.userInfo);
  const evaluation = location.state?.user;
  const [subjectList, setSubjectList] = useState<any[]>([]);

  useEffect(() => {
    if (!evaluation) {
      message.error('未选择测评人');
      setTimeout(() => {
        navigate('/', { replace: true });
      }, 1000);
    } else {
      getUserSubjectData();
    }
  }, []);

  const getUserSubjectData = () => {
    const param = {
      userId: userInfo.id,
      evaluatorId: evaluation.evaluatorId,
    };
    getUserSubject(param).then((result: any) => {
      const subjects: any[] = [];
      (result.body || []).forEach((item: any) => {
        const { subjectId, subjectName, id, level } = item;
        let subObj: any = subjects.find((sub: any) => sub.subjectId === item.subjectId);
        if (!subObj) {
          subObj = { subjectId, subjectName, levelList: [{ subjectLevelId: id, level }] };
          subjects.push(subObj);
        } else {
          subObj.levelList.push({ subjectLevelId: id, level });
        }
      });
      setSubjectList(subjects);
      console.log(subjects);
    }).catch((error: any) => message.error(error.msg));;
  };

  const onLevelClick = (subject: any, levelObj: any) => {
    const { subjectId } = subject;
    const { subjectLevelId, level } = levelObj;
    const param = {
      subjectId,
      subjectLevelId,
      level,
      userId: userInfo.id,
      evaluatorId: evaluation.evaluatorId,
    };
    createMock(param).then((result: any) => {
      const { evaluationId, paperId } = result.body;
      console.log(evaluationId, paperId);
      navigate(
        `/exam-formal?evaluationId=${evaluationId}&paperId=${paperId}&mock=1`,
        { state: { evaluation } }
      );
    }).catch((error: any) => message.error(error.msg));
  };

  return (
    <div className={styles.wrapper}>
      <NavigationBar easyMode />
      <div className={styles.content}>
        {
          subjectList.length ? (
            <>
              <h1 className={styles.title}>请选择模拟试题</h1>
              <div className={styles.subject_wrapper}>
                {
                  subjectList.map((subject: any, index: number) => (
                    <div className={styles.card} key={subject.subjectId}>
                      <div className={styles.cover}>
                        <img src={subjectCover[index]} />
                      </div>
                      <div className={styles.level_list}>
                        {
                          subject.levelList.map((level: any) => (
                            <div
                              className={styles.level}
                              key={level.subjectLevelId}
                              onClick={() => onLevelClick(subject, level)}
                            >
                              {`${subject.subjectName}${SubjectLevelName[level.level]}级`}
                            </div>
                          ))
                        }
                      </div>
                    </div>
                  ))
                }
              </div>
            </>
          ) : (
            <Empty
              message="暂无报名信息，无法进行模拟练习"
              btnText="返回"
              onBtnClick={() => navigate(-1)}
            />
          )
        }
      </div>
    </div>
  );
};
