import { Divider, message, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/button';
import { Input } from '../../components/input';
import { Panel } from '../../components/panel';
import { numbCertificate, userCertificate } from '../../utils/api';
import { SubjectLevelName } from '../../utils/constant';
import styles from './index.module.scss';

interface Iprop {

}

export const CertificateQuery = (props: Iprop) => {
  const navigate = useNavigate();
  const [queryType, setQueryType] = useState(1); // 1-证书编号查询 2-身份证号查询
  const [queryData, setQueryData] = useState<any>({
    certificateNum: '',
    evaluatorName: '',
    idCard: '',
  });
  const [dataList, setDataList] = useState<any[]>([]);
  const [currentData, setCurrentData] = useState<any>(null);

  const onQueryClick = () => {
    let { certificateNum, evaluatorName, idCard } = queryData;
    let param = {};
    let apiMethod;
    if (queryType === 1) {
      certificateNum = certificateNum.trim();
      if (!certificateNum) {
        message.error('请输入证书编号');
        return;
      }
      param = { certificateNum }
      apiMethod = numbCertificate;
    } else {
      evaluatorName = evaluatorName.trim();
      idCard = idCard.trim();
      if (!evaluatorName) {
        message.error('请输入姓名');
        return;
      }
      if (!idCard) {
        message.error('请输入身份证号');
        return;
      }
      param = { evaluatorName, idCard }
      apiMethod = userCertificate;
    }
    apiMethod(param).then((result: any) => {
      const arr = result.body || [];
      setDataList(arr);
      if (arr.length === 0) {
        message.info('未查询到证书');
      }
    }).catch((error: any) => {
      message.error(error.msg);
    });
  };

  const changeQueryType = (type: number) => {
    setQueryType(type);
    setDataList([]);
    setQueryData({
      certificateNum: '',
      evaluatorName: '',
      idCard: '',
    });
  };

  return (
    <div className={styles.wrapper}>
      <Panel
        title="证书查询"
        width={617}
        onClose={() => navigate(-1)}
      >
        <div className={styles.tabbar}>
          <div
            className={`${styles.tab} ${queryType===1 ? styles.active : ''}`}
            onClick={() => changeQueryType(1)}
          >
            证书编号查询
          </div>
          <div
            className={`${styles.tab} ${queryType===2 ? styles.active : ''}`}
            onClick={() => changeQueryType(2)}
          >
            身份证号查询
          </div>
        </div>
        {
          queryType === 1 ? (
            <Input
              label="证书编号"
              maxLength={20}
              type="number"
              // labelWidth={100}
              value={queryData.certificateNum}
              // errorInfo=""
              onChange={(value: any) => setQueryData({ ...queryData, certificateNum: value })}
            />
          ) : (
            <>
              <Input
                label="姓名"
                maxLength={50}
                // labelWidth={100}
                value={queryData.evaluatorName}
                // errorInfo=""
                onChange={(value: any) => setQueryData({ ...queryData, evaluatorName: value })}
              />
              <Input
                label="身份证号"
                maxLength={18}
                // labelWidth={100}
                value={queryData.idCard}
                // errorInfo=""
                onChange={(value: any) => setQueryData({ ...queryData, idCard: value })}
              />
            </>
          )
        }
        <div className={styles.btn_wrapper}>
          <Button
            text="验证"
            size="big"
            width={368}
            onClick={onQueryClick}
          />
        </div>
        {
          dataList.length ? (
            <>
              <div className={styles.result_space} />
              <Table
                dataSource={dataList}
                columns={[
                  {
                    title: '科目',
                    dataIndex: 'subjectName',
                    key: 'subjectName',
                  },
                  {
                    title: '级别',
                    dataIndex: 'level',
                    key: 'level',
                    render: (text: any) => SubjectLevelName[text]+'级'
                  },
                  {
                    title: '操作',
                    width: 100,
                    render: (text: any, record: any) => (
                      <div className={styles.btn_detail} onClick={() => setCurrentData(record)}>查看详情</div>
                    )
                  },
                ]}
                rowKey="id"
                pagination={false}
              />
            </>
          ) : null
        }
      </Panel>
      {/* 证书信息 */}
      {
        currentData ? (
          <Panel title="证书信息" width={617} onClose={() => setCurrentData(null)}>
            <div className={styles.detail_info}>
              <div className={styles.avatar}>
                <img src={currentData.evaluationImageUrl} />
              </div>
              <div className={styles.baseinfo}>
                <div className={styles.row}><label>姓名：</label><span>{currentData.evaluatorName}</span></div>
                <div className={styles.row}><label>身份证号：</label><span>{currentData.idCard}</span></div>
                <div className={styles.row}><label>科目等级：</label><span>{currentData.subjectName}{SubjectLevelName[currentData.level]}级</span></div>
                <div className={styles.row}><label>证书编号：</label><span>{currentData.certificateNum}</span></div>
                <div className={styles.row}><label>测评成绩：</label><span>{currentData.evaluationResult}</span></div>
              </div>
            </div>
          </Panel>
        ) : null
      }
    </div>
  );
};
