import React from 'react';
import styles from './index.module.scss';

interface Iprop {
  message: string;
  btnText?: string;
  style?: 'child' | 'box';
  onBtnClick?: () => void;
}
      
export const Empty = (props: Iprop) => {
  const { message, btnText, onBtnClick, style='child' } = props;
  return (
    <div className={styles.wrapper}>
      {
        style === 'child' ? <div className={styles.image} /> : null
      }
      {
        style === 'box' ? <div className={styles.image2} /> : null
      }
      <div className={styles.message}>{message}</div>
      {
        btnText ? (
          <div className={styles.btn} onClick={onBtnClick}>{btnText}</div>
        ) : null
      }
    </div>
  );
};
