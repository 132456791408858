import React from 'react';
import styles from './index.module.scss';
      
export const FooterBar = (props: any) => {
  return (
    <div className={styles.wrapper}>
      {/* <div className={styles.info_bar}>
        <div className={styles.info_tree}>
          <div className={styles.item}>
            <div className={styles.title}>介绍及报名</div>
            <div className={styles.link}>关于CPA</div>
            <div className={styles.link}>考生报名</div>
          </div>
          <div className={styles.item}>
            <div className={styles.title}>联系我们</div>
            <div className={styles.text}>邮箱：<a href="mailto:NCTA@biancetec.com">NCTA@biancetec.com</a></div>
            <div className={styles.text}>微信公众号：NCT青少年编程能力等级测试</div>
            <div className={styles.text}>咨询电话：<a href="tel:4009903330">4009903330</a></div>
          </div>
          <div className={styles.item}>
            <div className={styles.title}>合作咨询</div>
            <div className={styles.text}>合作申请入口</div>
            <div className={styles.text}>合作咨询邮箱：<a href="mailto:NCTSK2020@biancetec.com">NCTSK2020@biancetec.com</a></div>
          </div>
          <div className={styles.item}>
            <div className={styles.title}>关注公众号</div>
            <div className={`${styles.text} ${styles.flex}`}>
              <img className={styles.qrcode} src="https://static.cpa-test.cn/web-assets/image/img_index_footer_qrcode.png" alt="关注公众号" />
              <span>微信扫一扫，关注公众号，<br />咨询考试详情。</span>
            </div>
          </div>
        </div>
        <div className={styles.organization}>
          中国软件行业协会 | 教育部教育信息化技术标准委员会 | 全国高等院校计算机基础教育研究会 | 全国高等学校计算机教育研究会 | 清华大学 | 北京理工大学 | 北京航空航天大学 | 香港理工大学
        </div>
      </div> */}
      <div className={styles.copyrights}>
        <div>
          2022 © <a href="http://www.cnypa.org/" target="_blank">青少年宫协会</a>
        </div>
        <div className={styles.copyinfo}>
          <a className={styles.police} href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502051421" target="_blank">京公安网备 11010502051421号</a>
          <span className={styles.line}>|</span>
          <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备 15008436号</a>
          <span style={{ marginLeft: 20 }}>乾行讯科（北京）科技有限公司</span>
        </div>
      </div>
    </div>
  );
};
