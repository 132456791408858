import { message } from 'antd';
import React, { useEffect, useState } from 'react';
import { getOrganizationList } from '../../../../utils/api';
import styles from './index.module.scss';

interface Iprop {
  onChange?: (value: any) => void;
  onClose?: () => void;
}

export const OrganizationList = (props: Iprop) => {
  const { onChange, onClose } = props;
  const [organizations, setOrganizations] = useState<any[]>([]);

  useEffect(() => {
    getOrganizationData();
  }, []);

  const getOrganizationData = () => {
    getOrganizationList({}).then((result: any) => {
      setOrganizations(result.body || []);
    }).catch((error: any) => {
      message.error(error.msg);
    });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.panel}>
        <div className={styles.panel_name}>
          <span className={styles.title}>请选择就读机构</span>
          <div className={styles.btn_close} onClick={onClose} />
        </div>
        <div className={styles.content}>
          {
            organizations.map((org: any) => (
              <div className={styles.card} key={org.id} onClick={() => onChange && onChange(org)}>
                <img src={org.imageUrl} />
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
};
