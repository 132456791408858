import { Cascader, ConfigProvider, DatePicker, message, Radio, Select } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Input } from '../../components/input';
import { PageBar } from '../../components/page-bar';
import zhCN from 'antd/lib/locale/zh_CN';
import { checkImage, createEvaluator, getCitys, getOrganizationList, updateEvaluator, uploadFile } from '../../utils/api';
import styles from './index.module.scss';
import { LoadingOutlined } from '@ant-design/icons';
// import EXIF from 'exif-js';

const IdTypeMap: any = {
  '1': '居民身份证',
  '2': '港澳通行证',
  '3': '台湾通行证',
  '4': '护照',
};
const IdTypeData: any[] =[];
for (let key in IdTypeMap) {
  IdTypeData.push({ value: key, name: IdTypeMap[key] });
}

const ErrorTips: any = {
    evaluatorName: '请输入测评人真实姓名',
    cardNumber: '请准确填写，将用于测评证、成绩单、等级证书等展示',
    avatar: '请上传符合要求的照片',
    province: '此地址为证书邮寄地址，请正确填写真实地址',
    address: '请准确填写所在地区和详细地址，将用于邮寄等级证书。',
    schoolName: '请输入就读学校',
    liaison: '请输入紧急联系人姓名',
    liaisonPhone: '请输入联系人的电话号码',
    birthday: '请选择出生日期',
  };
      
export const EvaluatorEdit = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const defaultInfo = location.state ? { ...location.state } : {
    evaluatorName: "",
    birthday: '',
    idType: '1',
    cardNumber: '',
    sex: '1',
    province: '',
    city: '',
    area: '',
    address: '',
    schoolName: '',
    liaison: '',
    liaisonPhone: '',
    avatar: '',
    organizationId: '0',
  };
  const submitValiedKey = ['idType', 'evaluatorName', 'cardNumber', 'avatar', 'organizationId']; // 提交时验证的key
  const [evaluatorInfo, setEvaluatorInfo] = useState<any>(defaultInfo);
  const [errorInfo, setErrorInfo] = useState<any>({});
  const isEdit = location.state !== null;
  const [cityData, setCityData] = useState<any[]>([]);
  const [organizations, setOrganizations] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    getCitys().then((result: any) => {
      setCityData(result.cityData);
    });
    getOrganizationData();
  }, []);

  const getOrganizationData = () => {
    getOrganizationList({}).then((result: any) => {
      let list = result.body || [];
      list = [ { id: '0', name: '个人报名' }, ...list ];
      setOrganizations(list);
    }).catch((error: any) => {
      console.log('获取机构列表失败', error);
    });
  };

  const onEditClick = () => {
    // 编辑的入参
    const param: any = {};
    let valied = true;
    for (let i=0; i<submitValiedKey.length; i++) {
      const key = submitValiedKey[i];
      param[key] = (evaluatorInfo[key] || '').toString().trim();
      if (['age', 'mechanism', 'organizationId'].includes(key)) {
        continue;
      }
      if (!param[key]) {
        setErrorInfo({ ...errorInfo, [key]: ErrorTips[key] });
        valied = false;
        break;
      }
      if (['cardNumber', 'liaisonPhone'].includes(key)) {
        if (
          (param[key].length < 6) ||
          (key === 'cardNumber' && param[key].length !== 18 && param.idType === '1') ||
          (key === 'liaisonPhone' && param[key].length !== 11)
        ) {
          setErrorInfo({ ...errorInfo, [key]: ErrorTips[key] });
          valied = false;
          break;
        }
      }
    }
    if (valied) {
      // param.age = 0;
      if (evaluatorInfo.evaluatorId) {
        param.evaluatorId = evaluatorInfo.evaluatorId;
      }
      console.log(param);
      const apiMethod = isEdit ? updateEvaluator : createEvaluator;
      setIsLoading(true);
      apiMethod(param).then(() => {
        setIsLoading(false);
        message.success(`${isEdit ? '修改' : '提交'}成功`);
        navigate(-1);
      }).catch((error: any) => {
        setIsLoading(false);
        message.error(error.msg);
      });
    }
  };

  const onInputChange = (key: string, value: any) => {
    if (key === 'cardNumber') {
      value = value.replace(/[^a-zA-z\d]/g, '');
    }
    setEvaluatorInfo({ ...evaluatorInfo, [key]: value });
    setErrorInfo({ ...errorInfo, [key]: '' });
  };

  const onFileChange = (evt: any) => {
    let file = evt.target.files[0];
    if (file.size/1024/1024 > 1) {
      message.error('图片大小不得超过1M');
      return;
    }
    const reader = new FileReader();
    reader.onload = (evt: any) => {
      const img: any = document.createElement('img');
      img.onload = (e: any) => {
        if (e.target.width >= e.target.height) {
          message.error('图片宽高比例不符合要求');
          setIsUploading(false);
          return;
        }
        if (e.target.width < 400 || e.target.height < 600) {
          message.error('图片分辨率不符合要求');
          setIsUploading(false);
          return;
        }
        uploadImgFile(file);
        // EXIF.getData(img, () => {
        //   const allTags = EXIF.getAllTags(img);
        //   if ([6,8].includes(allTags.Orientation)) {
        //     console.log('图片方向不正确，纠正一下', allTags);
        //     const canvas = document.createElement('canvas');
        //     canvas.width = e.target.height;
        //     canvas.height = e.target.width;
        //     const ctx: any = canvas.getContext('2d');
        //     switch(allTags.Orientation) {
        //       case 6:
        //         ctx.transform(0, 1, -1, 0, e.target.height, 0); // 需要顺时针(向左)90度旋转
        //         break;
        //       case 8:
        //         ctx.transform(0, -1, 1, 0, 0, e.target.width); // 需要逆时针(向右)90度旋转
        //         break;
        //     }
        //     ctx.drawImage(img, 0, 0);
        //     canvas.toBlob((blob: any) => {
        //       file = new File([blob], file.name);
        //       uploadImgFile(file);
        //     });
        //   } else {
        //     uploadImgFile(file);
        //   }
        // })
      }
      img.src = evt.target.result;
    };
    reader.readAsDataURL(file);
    setIsUploading(true);
  };

  const uploadImgFile = (file: any) => {
    uploadFile(file, undefined, undefined, true).then((result: any) => {
      const imageUrl = result.url;
      checkImage({ imageUrl }).then(() => {
        onInputChange('avatar', imageUrl);
        setIsUploading(false);
      }).catch((error: any) => {
        message.error(error.msg);
        setIsUploading(false);
      });
    }).catch((error: any) => {
      message.error(error.msg);
      setIsUploading(false);
    });
  };

  return (
    <div className={styles.wrapper}>
      <PageBar title={isEdit ? '测评人信息详情' : '添加测评人'} />
      <div className={styles.content_area}>
        <div className={styles.form_row}>
          {/* 姓名 */}
          <div className={styles.form_item}>
            <Input
              label="姓名"
              maxLength={50}
              labelWidth={85}
              mini
              disable={isEdit}
              value={evaluatorInfo.evaluatorName}
              errorInfo={errorInfo.evaluatorName}
              onChange={(value: any) => onInputChange('evaluatorName', value)}
            />
          </div>
          {/* 证件类型 */}
          <div className={styles.form_item}>
            {
              isEdit ? (
                <Input
                  label="证件类型"
                  labelWidth={85}
                  mini
                  disable
                  value={IdTypeMap[evaluatorInfo.idType]}
                />
              ) : (
                <div className={styles.input_border}>
                  <div className={styles.label}>证件类型</div>
                  <div className={styles.content}>
                    <Select
                      style={{width:320}}
                      value={evaluatorInfo.idType}
                      onChange={(value: any) => onInputChange('idType', value)}
                    >
                      {
                        IdTypeData.map((item: any) => (
                          <Select.Option key={item.value} value={item.value}>{item.name}</Select.Option>
                        ))
                      }
                    </Select>
                  </div>
                </div>
              )
            }
          </div>
          {/* 性别 */}
          {/* <div className={styles.form_item}>
            <div className={styles.input_border}>
              <div className={styles.label}>性别</div>
              <div className={styles.content}>
                <Radio.Group
                  onChange={(evt: any) => onInputChange('sex', evt.target.value)}
                  value={evaluatorInfo.sex}
                  disabled={isEdit}
                >
                  <Radio value={'1'}>男</Radio>
                  <Radio value={'0'}>女</Radio>
                </Radio.Group>
              </div>
            </div>
          </div> */}
          {/* 出生日期 */}
          {/* <div className={styles.form_item}>
            <div className={styles.input_border} style={{marginBottom:24}}>
              <div className={styles.label}>出生日期</div>
              <div className={styles.content}>
                <ConfigProvider locale={zhCN}>
                  <DatePicker
                    style={{width:320}}
                    placeholder="请选择出生日期"
                    value={evaluatorInfo.birthday ? moment(evaluatorInfo.birthday) : null}
                    onChange={(value: any) => onInputChange('birthday', value.format('YYYY-MM-DD'))}
                  />
                </ConfigProvider>
              </div>
              <div className={styles.error}>{errorInfo.birthday}</div>
            </div>
          </div> */}
          {/* 证件号码 */}
          <div className={styles.form_item}>
            <Input
              label="证件号码"
              tipInfo="请准确填写，将用于测评证、成绩单、等级证书等展示"
              // type="number"
              maxLength={18}
              labelWidth={85}
              mini
              disable={isEdit}
              value={evaluatorInfo.cardNumber}
              errorInfo={errorInfo.cardNumber}
              onChange={(value: any) => onInputChange('cardNumber', value)}
            />
          </div>
          {/* 身份认证提示文字 */}
          <div className={styles.input_border}>
            <div className={`${styles.label} ${styles.unrequired}`}></div>
            <div className={styles.content}>
              <div className={styles.tip_text}>身份认证通过后，不可修改。将用于测评证，成绩单和等级证书等信息展示。</div>
            </div>
          </div>
          {/* 分割线 */}
          <div className={styles.line} />
          {/* 所在地区 */}
          {/* <div className={styles.form_item}>
            <div className={styles.input_border}>
              <div className={styles.label}>所在地区</div>
              <div className={styles.content}>
                <Cascader
                  fieldNames={{ value: 'label' }}
                  style={{width:320}}
                  options={cityData}
                  allowClear={false}
                  onChange={(value: any) => {
                    setEvaluatorInfo({
                      ...evaluatorInfo,
                      province: value[0],
                      city: value[1],
                      area: value[2],
                    });
                    setErrorInfo({ ...errorInfo, province: '' });
                  }}
                  placeholder="请选择"
                  value={[evaluatorInfo.province, evaluatorInfo.city, evaluatorInfo.area]}
                />
              </div>
              <div className={styles.error}>{errorInfo.province}</div>
            </div>
          </div> */}
          {/* 紧急联系人 */}
          {/* <div className={styles.form_item}>
            <Input
              label="紧急联系人"
              maxLength={50}
              labelWidth={85}
              mini
              value={evaluatorInfo.liaison}
              errorInfo={errorInfo.liaison}
              onChange={(value: any) => onInputChange('liaison', value)}
            />
          </div> */}
          {/* 详细住址 */}
          {/* <div className={styles.form_item}>
            <Input
              label="详细住址"
              tipInfo="请准确填写所在地区和详细地址，将用于邮寄等级证书。"
              maxLength={200}
              labelWidth={85}
              mini
              value={evaluatorInfo.address}
              errorInfo={errorInfo.address}
              onChange={(value: any) => onInputChange('address', value)}
            />
          </div> */}
          {/* 联系人号码 */}
          {/* <div className={styles.form_item}>
            <Input
              label="联系人号码"
              type="number"
              maxLength={11}
              tipInfo="此手机号仅作为邮寄证书使用，不作为登录账号。"
              labelWidth={85}
              mini
              value={evaluatorInfo.liaisonPhone}
              errorInfo={errorInfo.liaisonPhone}
              onChange={(value: any) => onInputChange('liaisonPhone', value)}
            />
          </div> */}
          {/* 就读学校 */}
          {/* <div className={styles.form_item}>
            <Input
              label="就读学校"
              maxLength={100}
              labelWidth={85}
              mini
              value={evaluatorInfo.schoolName}
              errorInfo={errorInfo.schoolName}
              onChange={(value: any) => onInputChange('schoolName', value)}
            />
          </div> */}
          {/* 分割线 */}
          {/* <div className={styles.line} /> */}
          {/* 本人照片 */}
          <div className={styles.form_item} style={{width:'100%'}}>
            <div className={styles.input_border} style={{marginBottom:24}}>
              <div className={styles.label}>本人照片</div>
              <div className={styles.content}>
                <div className={`${styles.avator} ${evaluatorInfo.avatar ? '' : styles.dashed}`} style={{marginRight:80}}>
                  {
                    evaluatorInfo.avatar ? <img src={evaluatorInfo.avatar} /> : null
                  }
                  <input type="file" onChange={onFileChange} />
                  {
                    isUploading ? (
                      <div className={styles.mask_loading}>
                        <LoadingOutlined style={{ fontSize:30, color: 'white' }} />
                      </div>
                    ) : null
                  }
                </div>
                <div className={`${styles.avator} ${styles.demo}`}>
                  <img src="https://static.cpa-test.cn/web-assets/image/evaltor_avator_demo.png" />
                </div>
                <div className={styles.avator_tip}>
                  <div className={styles.area}>
                    <div className={styles.main}>照片示例及要求</div>
                    <div className={styles.summary} style={{whiteSpace:'nowrap'}}>
                      底色：蓝底/红底<br />
                      宽高：413*626（标准大二寸像 3.5cm*5.3cm）<br />
                      格式：jpg/png<br />
                      大小：1M以内<br />
                      分辨率：300dpi～400dpi<br />
                    </div>
                  </div>
                  <div className={styles.area}>
                    <div className={styles.main} />
                    <div className={styles.summary}>
                      此照片将是您的测评证照片和证书照片<br />
                      （如通过测评），请务必按要求提供！
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.error} style={{top:153}}>{errorInfo.avatar}</div>
            </div>
          </div>
          {/* 分割线 */}
          <div className={styles.line} />
          {/* 就读机构 */}
          <div className={styles.form_item}>
            <div className={styles.input_border}>
              <div className={`${styles.label} ${styles.unrequired}`}>报名团体</div>
              <div className={styles.content}>
                <Select
                  style={{flex:1}}
                  value={evaluatorInfo.organizationId || '0'}
                  onChange={(value: any) => onInputChange('organizationId', value)}
                >
                  {
                    organizations.map((org: any) => (
                      <Select.Option key={org.id} value={org.id}>{org.name}</Select.Option>
                    ))
                  }
                </Select>
              </div>
            </div>
          </div>
          {/* 提交按钮 */}
          <div className={styles.form_item} style={{width:'100%'}}>
            <div className={styles.input_border}>
              <div className={`${styles.label} ${styles.unrequired}`}></div>
              <div className={styles.content}>
                <div
                  className={`${styles.btn_submit} ${isLoading ? styles.disable: ''}`}
                  onClick={onEditClick}
                >
                  { isEdit ? (
                    isLoading ? '修改中...' : '修改'
                  ) : (
                    isLoading ? '提交中...' : '提交'
                  ) }
                </div>
              </div>
            </div>
          </div>
          {/* END */}
        </div>
      </div>
    </div>
  );
};
