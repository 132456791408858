import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { FooterBar } from "../../modules/footer-bar";
import { Login } from "../../modules/login";
import { ActionType } from '../../store';
import { NavigationBar } from "../../modules/navigation-bar";
import styles from './index.module.scss';
import { message, Modal } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { MenuBar } from "../../modules/menu-bar";
import { logout } from "../../utils/api";
import { getQuery } from "../../utils/util";
import { ScreenSharePublisher } from "../../modules/screen-share-publisher";

const NO_LOGIN_ROUTES = ['/', '/about'];

interface Iprop {
  children: any;
  styleType?: number; // 样式类型 1-首页版式 2-带左侧菜单版式 3-纯净版式
}

export default function Layout (props: Iprop) {
  const { children, styleType=1 } = props;
  const dispatch = useDispatch();
  let redirect = getQuery('redirect');
  redirect = redirect ? decodeURIComponent(redirect) : '';
  const [showLogin, setShowLogin] = useState(redirect ? true : false);
  const navigate = useNavigate();
  const location = useLocation();

  const onLogout = () => {
    Modal.confirm({
      title: '确定退出登录吗？',
      okText: '确定',
      cancelText: '取消',
      onOk: () => {
        dispatch({
          type: ActionType.UPDATE_USERINFO,
          value: { id: null, telphone: '' },
        });
        localStorage.removeItem('userInfo');
        localStorage.removeItem('token');
        logout({}).then(() => {
          message.success('已退出');
        }).catch((error: any) => {
          console.log('退出失败', error);
        });
        if (location.pathname !== '/') {
          navigate('/');
        }
      }
    });
  };

  const onMenuChange = (menu: any) => {
    if (menu.children && menu.children.length) {
      return;
    }
    const menuRoute = menu.route.split('?')[0];
    const token = localStorage.getItem('token');
    const isLogin = token !== null && token !== undefined && token != '';
    if (!isLogin && NO_LOGIN_ROUTES.indexOf(menuRoute) === -1) {
      navigate(`/?redirect=${encodeURIComponent(menu.route)}`, { replace: true });
      setShowLogin(true);
      return;
    }
    if (menuRoute !== location.pathname) {
      navigate(menu.route);
    } else {
      navigate(menu.route, { replace: true });
    }
  };

  return (
    <div className={styles.wrapper}>
      {
        styleType !== 3 ? (
          <NavigationBar
            onLogin={() => setShowLogin(true)}
            onLogout={onLogout}
            onMenuChange={onMenuChange}
          />
        ) : null
      }
      {
        styleType === 1 ? (
          <>
            <div className={styles.content_wrapper}>
              {children}
            </div>
            <FooterBar />
          </>
        ) : null
      }
      {
        styleType === 2 ? (
          <div className={styles.content_wrapper2}>
            <div className={styles.left_area}>
              <MenuBar />
            </div>
            <div className={styles.right_area}>
              <div className={styles.right_content}>{children}</div>
              <FooterBar />
            </div>
          </div>
        ) : null
      }
      {
        styleType === 3 ? (
          <>
            { children }
            <ScreenSharePublisher />
          </>
        ) : null
      }
      {
        showLogin ? (
          <Login
            onClose={() => {
              setShowLogin(false);
              navigate('/', { replace: true });
            }}
            onLoginSuccess={(data: any) => {
              setShowLogin(false);
              dispatch({
                type: ActionType.UPDATE_USERINFO,
                value: data,
              });
              localStorage.setItem('userInfo', JSON.stringify(data));
              if (redirect) {
                navigate(redirect, { replace: true });
              }
            }}
          />
        ) : null
      }
    </div>
  );
}