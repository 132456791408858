import React, { useEffect, useState } from 'react';
import { Button } from '../../../../components/button';
import { ExamStatusName, SubjectLevelName } from '../../../../utils/constant';
import { dateStr2Date } from '../../../../utils/util';
import { Timer } from '../../../exam-formal/components/timer';
import styles from './index.module.scss';

interface Iprop {
  exam: any;
  onlyShowStart?: boolean;
  onShowExamCard?: () => void;
  onEnvCheck?: () => void;
  onStartExam?: () => void;
  onTimeup?: () => void;
}

export const ExamItem = (props: Iprop) => {
  const { exam, onlyShowStart=false, onShowExamCard, onEnvCheck, onStartExam, onTimeup } = props;
  const envCheckSuccess = sessionStorage.getItem('env-check-success') === '1';
  const [offsetTime, setOffsetTime] = useState(0);

  useEffect(() => {
    const nowTimeMillionSeconds = new Date(Number(exam.timestamp)).getTime();
    const endTimeMillionSeconds = dateStr2Date(exam.sessionsTime).getTime();
    const offsetSeconds = Math.floor((endTimeMillionSeconds - nowTimeMillionSeconds) / 1000);
    if(offsetSeconds > 0) {
      setOffsetTime(offsetSeconds);
    }
  }, []);

  return (
    <div className={styles.exam_card}>
      <div className={styles.title}>{exam.planName} {exam.subjectName}（{SubjectLevelName[exam.level]}级）</div>
      <div className={styles.content}>
        <div className={styles.leftarea}>
          <div className={styles.info}>
            <label>测评时间：</label>
            <span>{exam.sessionsTime}</span>
          </div>
          <div className={styles.info}>
            <label>测评状态：</label>
            <span>{ExamStatusName[exam.status]}</span>
          </div>
          <div className={styles.info}>
            <label>测评环境：</label>
            {
              envCheckSuccess ? (
                <span style={{color:'#52C41A'}}>环境检测成功</span>
              ) : (
                <span style={{color:'#F5222D'}}>未完成检测</span>
              )
            }
          </div>
        </div>
        <div className={styles.rightarea}>
          {
            [2,5].includes(exam.status) ? (
              offsetTime > 0 ? (
                <Timer
                  totalTime={offsetTime}
                  className={styles.timer}
                  onTimeup={() => {
                    setOffsetTime(0);
                    onTimeup && onTimeup();
                  }}
                />
              ) : (
                <div className={styles.timer}>考试已开始</div>
              )
            ) : null
          }
          {
            exam.status === 1 ? (
              <div className={styles.no_timer}>
                未到测评时间{onlyShowStart ? '' : '，您可以完成以下操作'}
              </div>
            ) : null
          }
          <div className={styles.btn_group}>
            {
              [1,2,5].includes(exam.status) && !onlyShowStart ? (
                <>
                  <Button
                    width={120}
                    text="下载测评证"
                    type="outline"
                    onClick={() => onShowExamCard && onShowExamCard()}
                  />
                </>
              ) : null
            }
            {
              exam.status === 1 && !onlyShowStart ? (
                <Button
                  width={120}
                  text="开始环境检测"
                  style={{marginLeft:8}}
                  onClick={() => onEnvCheck && onEnvCheck()}
                />
              ) : null
            }
            {
              [2,5].includes(exam.status) || (exam.status===1 && onlyShowStart) ? (
                <Button
                  width={120}
                  type="green"
                  disable={offsetTime>0 && onlyShowStart}
                  text="开始正式测评"
                  style={{marginLeft:8}}
                  onClick={() => onStartExam && onStartExam()}
                />
              ) : null
            }
          </div>
        </div>
      </div>
    </div>
  );
};
