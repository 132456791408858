import { message, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { checkCanOrder, getEvaluatorList, getExamPlan } from '../../utils/api';
import { CheckUserInfo } from './components/checkUserInfo';
import { ChooseSubjectLevel } from './components/chooseSubjectLevel';
import styles from './index.module.scss';
import { SubmitOrder } from './components/submitOrder';
import { getQuery } from '../../utils/util';
import { useNavigate } from 'react-router-dom';

export const EnterForm = (props: any) => {
  const [planInfo, setPlanInfo] = useState<any>(null);
  const [evaluatorList, setEvaluatorList] = useState<any[]>([]);
  const [evaluatorIndex, setEvaluatorIndex] = useState(0);
  let subidx: any = getQuery('subjectindex');
  subidx = subidx ? parseInt(subidx) : 0;
  const [subjectIndex, setSubjectIndex] = useState(subidx);
  const [levelIndex, setLevelIndex] = useState(0);
  const [stepIndex, setStepIndex] = useState(0);
  const [sessionIndex, setSessionIndex] = useState(0);
  const [organizationId, setOrganizationId] = useState(getQuery('oranizationId') || '0');
  const [isCrossLevel, setIsCrossLevel] = useState(false); // 是否跨级报名
  const [crossLevelInfo, setCrossLevelInfo] = useState<any>({ // 跨级报名审核信息
    certificateUrl: '',
    certificateNum: '',
    evaluationResult: '',
  });
  const navigate = useNavigate();

  useEffect(() => {
    getExamPlanData();
    getEvaluatorData();
  }, []);

  const getExamPlanData = () => {
    getExamPlan({}).then((result: any) => {
      setPlanInfo(result.body);
    }).catch((error: any) => {
      message.error(error.message || error.msg);
    });
  };

  const getEvaluatorData = () => {
    getEvaluatorList({}).then((result: any) => {
      const list = result.body.list || [];
      setEvaluatorList(list);
      if (organizationId === '0' && list.length > 0) {
        if (list[evaluatorIndex].organizationId) {
          setOrganizationId(list[evaluatorIndex].organizationId.toString());
        }
      }
    }).catch((error: any) => console.log('获取测评人数据失败', error.msg));
  };

  const onLevelNext = () => {
    checkCanAddorder(levelIndex, sessionIndex, true);
  };

  const checkCanAddorder = (levIdx: number, sesIdx: number, toNext: boolean = false) => {
    const param: any = {
      evaluatorId: evaluatorList[evaluatorIndex].evaluatorId,
      planId: planInfo.planId,
      subjectId: planInfo.subjects[subjectIndex].subjectId,
      subjectLevelId: planInfo.subjects[subjectIndex].subjectLevels[levIdx].subjectLevelId,
      sessionsId: planInfo.subjects[subjectIndex].subjectLevels[levIdx].sessionsList[sesIdx].sessionsId,
      level: planInfo.subjects[subjectIndex].subjectLevels[levIdx].level,
    };
    checkCanOrder(param).then((result: any) => {
      // result.body.result   0-可以报名  1-跨级报名 2-存在待考同等测评 3-场次时间冲突
      if (result.body.result === 0) {
        setIsCrossLevel(false);
        if (toNext) {
          setStepIndex(stepIndex + 1);
        }
      } else if (result.body.result === 1) {
        setIsCrossLevel(true);
        if (toNext) {
          // if (!crossLevelInfo.certificateNum.trim()) {
          //   message.error('证书编号不能为空');
          //   return;
          // }
          // if (!crossLevelInfo.evaluationResult) {
          //   message.error('证书成绩不能为空');
          //   return;
          // }
          // if (!crossLevelInfo.certificateUrl) {
          //   message.error('证书照片不能为空');
          //   return;
          // }
          setStepIndex(stepIndex + 1);
        }
      } else {
        setIsCrossLevel(false);
        message.error(
          ['', '', '已存在待考同等测评', '报考科目存在时间冲突，请重新选择'][result.body.result],
        );
      }
    }).catch((error: any) => {
      console.log(error);
      message.error(error.msg);
    });
  };

  return (
    <div className={styles.wrapper}>
      {/* 步骤条 */}
      <div className={styles.step_bar}>
        <div className={`${styles.step} ${stepIndex===0 ? styles.active : ''} ${stepIndex>0 ? styles.complete : ''}`}>
          <span className={styles.num}><i>1</i></span>
          <span className={styles.name}>核对报名信息</span>
        </div>
        <div className={`${styles.line} ${stepIndex>0 ? styles.active : ''}`} />
        <div className={`${styles.step} ${stepIndex===1 ? styles.active : ''} ${stepIndex>1 ? styles.complete : ''}`}>
          <span className={styles.num}><i>2</i></span>
          <span className={styles.name}>选择科目等级</span>
        </div>
        <div className={`${styles.line} ${stepIndex>1 ? styles.active : ''}`} />
        <div className={`${styles.step} ${stepIndex===2 ? styles.active : ''}`}>
          <span className={styles.num}><i>3</i></span>
          <span className={styles.name}>提交订单</span>
        </div>
      </div>
      {/* 核对报名信息 */}
      {
        stepIndex === 0 ? (
          <CheckUserInfo
            evaluatorList={evaluatorList}
            evaluatorIndex={evaluatorIndex}
            organizationId={organizationId}
            onEvaluatorChange={(value:any) => {
              setEvaluatorIndex(value);
              const orgid = evaluatorList[value].organizationId;
              if (!getQuery('oranizationId')) {
                setOrganizationId(orgid ? orgid.toString() : '0');
              }
            }}
            onOrganizationChange={(value:any) => setOrganizationId(value)}
            onNext={() => {
              if (!planInfo) {
                message.error('考试计划不存在');
                return;
              }
              setStepIndex(stepIndex + 1);
            }}
          />
        ) : null
      }
      {/* 选择科目等级 */}
      {
        stepIndex === 1 ? (
          <ChooseSubjectLevel
            onPre={() => setStepIndex(stepIndex - 1)}
            onNext={onLevelNext}
            subjectInfo={planInfo.subjects[subjectIndex]}
            levelIndex={levelIndex}
            sessionIndex={sessionIndex}
            isCrossLevel={isCrossLevel}
            crossLevelInfo={crossLevelInfo}
            onCrossInfoChange={(value: any) => setCrossLevelInfo(value)}
            onLevelChange={(value: number) => {
              setLevelIndex(value);
              checkCanAddorder(value, sessionIndex);
            }}
            onSessionChange={(value: number) => {
              setSessionIndex(value);
              checkCanAddorder(levelIndex, value);
            }}
          />
        ) : null
      }
      {/* 提交订单 */}
      {
        stepIndex === 2 ? (
          <SubmitOrder
            planId={planInfo.planId}
            evaluatorInfo={evaluatorList[evaluatorIndex]}
            subjectInfo={planInfo.subjects[subjectIndex]}
            levelIndex={levelIndex}
            sessionIndex={sessionIndex}
            organizationId={organizationId}
            isCrossLevel={isCrossLevel}
            crossLevelInfo={crossLevelInfo}
            onPre={() => setStepIndex(stepIndex - 1)}
          />
        ) : null
      }
    </div>
  );
};
