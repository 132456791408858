import { Carousel } from 'antd';
import React, { useRef } from 'react';
import styles from './index.module.scss';

interface Iprop {
  data: any[];
  imageKey?: string;
  urlKey?: string;
  borderRadius?: number;
}

export const Swiper = (props: Iprop) => {
  const { data, imageKey='imageUrl', urlKey='jumpUrl', borderRadius=0 } = props;
  const carousel = useRef<any>(null);

  const onSlideClick = (item: any) => {
    if (item[urlKey]) {
      window.open(item[urlKey]);
    }
  };

  return (
    <div className={styles.wrapper} style={{borderRadius}}>
      <Carousel autoplay ref={carousel}>
        {
          data.map(item => (
            <div className={styles.slide} key={item.id} onClick={() => onSlideClick(item)}>
              <img src={item[imageKey]} style={{cursor:item[urlKey] ? 'pointer' : 'default'}} />
            </div>
          ))
        }
      </Carousel>
      {
        data.length > 1 ? (
          <>
            <div className={styles.btn_area} style={{left:50}}>
              <div
                className={`${styles.btn} ${styles.prev}`}
                onClick={() => carousel.current.prev()}
              />
            </div>
            <div className={styles.btn_area} style={{right:50}}>
              <div
                className={`${styles.btn} ${styles.next}`}
                onClick={() => carousel.current.next()}
              />
            </div>
          </>
        ) : null
      }
    </div>
  );
};
