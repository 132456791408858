import { message, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Empty } from '../../components/empty';
import { PageBar } from '../../components/page-bar';
import { Panel } from '../../components/panel';
import { getEvaluatorList, getExamGuideList } from '../../utils/api';
import { ExamLogin } from '../examination/components/exam-login';
import { CheckUser } from './components/check-user';
import { EnterCard } from './components/enter-card';
import { ExamItem } from './components/exam-item';
import styles from './index.module.scss';
      
export const ExamGuide = (props: any) => {
  const navigate = useNavigate();
  const guideList = [
    {
      title: '报名信息填写',
      summary: '填写真实姓名及身份证号，并上传照片，此信息将展示在成绩及证书上。',
    },
    {
      title: '测评在线报名',
      summary: '选择报考的专业及时间点报名，报名后24小时内完成缴费。',
    },
    {
      title: '测评环境测试及模拟练习',
      summary: '报名后，随时开始模拟练习。模拟练习时同时需要验证监控环境。',
    },
    {
      title: '参与正式测评<br>颁发证书',
      summary: '准时参与正式测评，确保测评环境有效，提交有效成绩后，通过者予以颁发证书。',
    },
  ];
  const userInfo = useSelector((state: any) => state.userInfo);
  const [examList, setExamList] = useState<any[]>([]);
  const [userList, setUserList] = useState<any[]>([]);
  const [evaluator, setEvaluator] = useState<any>(null);
  const [examInfo, setExamInfo] = useState<any>(null);
  const [showCheckUser, setShowCheckUser] = useState(false);
  const [showEnterCard, setShowEnterCard] = useState(false);
  const [showLogin, setShowLogin] = useState(false);

  useEffect(() => {
    getExamGuideData();
    getEvaluatorData();
  }, []);

  const getExamGuideData = () => {
    const param = {
      userId: userInfo.id,
    };
    getExamGuideList(param).then((result: any) => {
      const arr = result.body || [];
      arr.forEach((item:any) => item.timestamp = result.timestamp);
      setExamList(arr);
    }).catch((error: any) => {
      message.error(error.msg);
    });
  };

  const getEvaluatorData = () => {
    getEvaluatorList({}).then((result: any) => {
      const list = result.body.list || [];
      setUserList(list);
      if (list.length === 0) {
        Modal.confirm({
          title: '您还未完善个人资料',
          okText: '去填写',
          cancelText: '取消',
          onOk: () => navigate('/evaluator-edit'),
        });
      }
    }).catch((error: any) => console.log('获取测评人失败', error.msg));
  };

  const getEvaluatorById = (evaluatorId: any) => {
    if (userList.length > 0) {
      const curEva = userList.find((eva: any) => eva.evaluatorId === evaluatorId);
      return curEva;
    }
    return null;
  };

  return (
    <div className={styles.wrapper_group}>
      {/* 考试列表 */}
      <div className={`${styles.wrapper} ${examList.length===0 ? styles.empty : ''}`} style={{marginBottom:24}}>
        <PageBar title="当前状态" />
        <div className={styles.content_wrapper}>
          {
            examList.length===0 ? (
              <Empty
                message="当前无测评，您未报名！"
                style="box"
                btnText="去报名"
                onBtnClick={() => {
                  if (userList.length === 0) {
                    Modal.confirm({
                      title: '您还未完善个人资料',
                      okText: '去填写',
                      cancelText: '取消',
                      onOk: () => navigate('/evaluator-edit'),
                    });
                    return;
                  }
                  navigate('/enter-online');
                }}
              />
            ) : (
              <>
                <div className={styles.count_bar}>
                  <div className={styles.message}>
                    您当前有<span className={styles.count}>{examList.length}</span>个测评待开始
                  </div>
                  <div className={styles.btn_link} onClick={() => navigate('/examination')}>我的全部测评</div>
                </div>
                {
                  examList.slice(0,3).map((exam: any) => (
                    <React.Fragment key={exam.evaluationId}>
                      <ExamItem
                        exam={exam}
                        onShowExamCard={() => {
                          setExamInfo(exam);
                          const curEva = getEvaluatorById(exam.evaluatorId);
                          if (curEva) {
                            setEvaluator(curEva);
                            setShowCheckUser(true);
                          }
                        }}
                        onEnvCheck={() => {
                          const curEva = getEvaluatorById(exam.evaluatorId);
                          if (curEva) {
                            navigate(`/env-check`, { state: { exam, user: curEva } });
                          }
                        }}
                        onStartExam={() => {
                          setExamInfo(exam);
                          const curEva = getEvaluatorById(exam.evaluatorId);
                          if (curEva) {
                            setEvaluator(curEva);
                            setShowLogin(true);
                          } else {
                            message.error('测评人不存在');
                          }
                        }}
                      />
                    </React.Fragment>
                  ))
                }
              </>
            )
          }
        </div>
      </div>
      {/* CPA测评流程图 */}
      <div className={styles.wrapper} style={{height:279}}>
        <PageBar title="青少年编程能力等级测评（CPA）测评流程图" />
        <div className={styles.content_wrapper2}>
          {
            guideList.map((item: any, index: number) => (
              <React.Fragment key={index}>
                <div className={styles.step_card}>
                  <div className={styles.card_border}>
                    <span className={styles.step_num}>{`0${index+1}`}</span>
                    <span className={styles.title} dangerouslySetInnerHTML={{__html:item.title}} />
                  </div>
                  <div className={styles.summary}>{item.summary}</div>
                </div>
                {
                  index < guideList.length - 1 ? <div className={styles.next_icon} /> : null
                }
              </React.Fragment>
            ))
          }
        </div>
      </div>
      {/* 核对报名信息 */}
      {
        showCheckUser ? (
          <Panel
            title="核对报名信息"
            width={575}
            onClose={() => setShowCheckUser(false)}
          >
            <CheckUser
              userInfo={evaluator}
              onConfirm={() => {
                setShowCheckUser(false);
                setShowEnterCard(true);
              }}
            />
          </Panel>
        ) : null
      }
      {/* 测评证 */}
      {
        showEnterCard ? (
          <Panel
            title="打印/下载测评证"
            width={740}
            alignTop={true}
            onClose={() => setShowEnterCard(false)}
            topChildren={<div className={styles.toptip}>请核对信息与参与测评人信息一致且照片为本人一寸证件照（若通过测评，此照片会用于证书照片，请按要求上传！）如信息或照片与本人不符，请重新点击查看测评证并修改个人信息。如有疑问，请拨打 4009903330 咨询。</div>}
          >
            <EnterCard userInfo={evaluator} examInfo={examInfo} />
          </Panel>
        ) : null
      }
      {/* 登录 */}
      {
        showLogin && examInfo ? (
          <ExamLogin
            examInfo={examInfo}
            onClose={() => setShowLogin(false)}
            onSuccess={() => {
              navigate(`/env-check?type=exam`, { state: { exam: examInfo, user: evaluator } });
            }}
          />
        ) : null
      }
    </div>
  );
};
