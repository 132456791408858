import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../../components/button";
import styles from './index.module.scss';

export const SubmitSuccess = (props: any) => {
  const { isMock } = props;

  const navigate = useNavigate();

  return (
    <div className={styles.wrapper}>
      <div className={styles.image} />
      <div>
        <h1>测评结束！</h1>
        <p>测评结束，预祝您取得佳绩！</p>
        <Button
          text="返回"
          size="big"
          width={120}
          onClick={() => {
            if (isMock) {
              navigate('/exam-mock', { replace: true });
            } else {
              navigate('/examination', { replace: true });
            }
          }}
        />
      </div>
    </div>
  );
};
