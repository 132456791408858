export const SubjectLevelName = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九', '十'];

export const subjectCover = [
'https://static.cpa-test.cn/web-assets/image/img-subject-1.png',
'https://static.cpa-test.cn/web-assets/image/img-subject-2.png',
'https://static.cpa-test.cn/web-assets/image/img-subject-3.png',
'https://static.cpa-test.cn/web-assets/image/img-subject-1.png',
'https://static.cpa-test.cn/web-assets/image/img-subject-2.png',
'https://static.cpa-test.cn/web-assets/image/img-subject-3.png',
];

export const ExamStatusName = ['未知状态', '未开始', '测评中', '已结束', '缺考', '待补考'];