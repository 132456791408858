import { message, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Input } from '../../../components/input';
import { uploadFile } from '../../../utils/api';
import { SubjectLevelName } from '../../../utils/constant';
import styles from '../index.module.scss';

interface Iprop {
  subjectInfo: any;
  levelIndex: number;
  sessionIndex: number;
  isCrossLevel: boolean;
  crossLevelInfo: any;
  onCrossInfoChange?: (value: any) => void;
  onLevelChange?: (level: number) => void;
  onSessionChange?: (session: number) => void;
  onNext?: () => void;
  onPre?: () => void;
}

export const ChooseSubjectLevel = (props: Iprop) => {
  const {
    subjectInfo,
    levelIndex,
    sessionIndex,
    onNext,
    onPre,
    isCrossLevel,
    crossLevelInfo,
    onCrossInfoChange,
    onLevelChange,
    onSessionChange,
  } = props;

  const userInfo = useSelector((state: any) => state.userInfo);
  const whiteList = useSelector((state: any) => state.whiteList);
  const isWhiteUser = whiteList.includes(userInfo.id);

  const onFileChange = (evt: any) => {
    const file = evt.target.files[0];
    uploadFile(file, 'user/evaluator/certificate/').then((result: any) => {
      onCrossInfoChange && onCrossInfoChange({ ...crossLevelInfo, certificateUrl: result.url });
    }).catch((error: any) => message.error(error.msg));
  };

  return (
    <div className={styles.form_wrapper}>
      <div className={styles.form_item}>
        <div className={styles.label}>测评科目</div>
        <div className={styles.input}>{subjectInfo.subjectName}</div>
      </div>
      <div className={styles.form_item}>
        <div className={styles.label}>科目等级</div>
        <Select
          style={{flex:1}}
          value={levelIndex}
          onChange={(value: any) => onLevelChange && onLevelChange(value)}
        >
          {
            (subjectInfo.subjectLevels || []).map((levelInfo: any, lidx: number) => (
              <Select.Option
                key={levelInfo.subjectLevelId}
                value={lidx}
              >
                {`${SubjectLevelName[levelInfo.level]}级`}
              </Select.Option>
            ))
          }
        </Select>
      </div>
      <div className={styles.form_item}>
        <div className={styles.label}>测评时间</div>
        <Select
          style={{flex:1}}
          value={sessionIndex}
          onChange={(value: any) => onSessionChange && onSessionChange(value)}
        >
          {
            (subjectInfo.subjectLevels[levelIndex].sessionsList || [])
              .filter((item: any) => item.useTest !== 1 || isWhiteUser)
              .map((sessionInfo: any, sidx: number) => (
              <Select.Option
                key={sessionInfo.sessionsId}
                value={sidx}
              >
                {sessionInfo.sessionsTime}{sessionInfo.useTest ? <span style={{color:'#999999'}}> (内测)</span> : ''}
              </Select.Option>
            ))
          }
        </Select>
      </div>
      {/* 跨级考审核信息 */}
      {
        isCrossLevel ? (
          <>
            <div className={styles.form_item}>
              <div className={`${styles.label} ${styles.unrequired}`}></div>
              <div className={styles.cross_level_tip}>
                如果选择二级及以上的考试，请填写前一级别的证书信息并上传证书。
              </div>
            </div>
            <Input
              label="证书编号"
              placeholder="请输入证书编号"
              required={false}
              maxLength={50}
              labelWidth={80}
              mini
              value={crossLevelInfo.certificateNum}
              // errorInfo=""
              onChange={(value: any) => onCrossInfoChange && onCrossInfoChange({ ...crossLevelInfo, certificateNum: value })}
            />
            <div className={styles.form_item}>
              <div className={`${styles.label} ${styles.unrequired}`}>证书成绩</div>
              <Select
                style={{width:320}}
                value={crossLevelInfo.evaluationResult}
                onChange={(value: any) => onCrossInfoChange && onCrossInfoChange({ ...crossLevelInfo, evaluationResult: value })}
              >
                <Select.Option value="优秀">优秀</Select.Option>
                <Select.Option value="合格">合格</Select.Option>
              </Select>
            </div>
            <div className={styles.form_item}>
              <div className={`${styles.label} ${styles.unrequired}`}>证书照片</div>
              <div className={`${styles.uploader} ${crossLevelInfo.certificateUrl ? '' : styles.dashed}`}>
                {
                  crossLevelInfo.certificateUrl ? <img src={crossLevelInfo.certificateUrl} /> : null
                }
                <input type="file" onChange={onFileChange} />
              </div>
            </div>
          </>
        ) : null
      }
      <div className={styles.form_item} style={{marginTop:40, marginBottom:24}}>
        <div className={styles.label_empty}></div>
        <div className={styles.btn_next} onClick={onNext}>下一步</div>
      </div>
      <div className={styles.form_item} style={{marginTop:0}}>
        <div className={styles.label_empty}></div>
        <div className={styles.btn_tip_bar}>
          <div className={styles.btn_tip}>
            <span className={`${styles.link} ${styles.arrow}`} onClick={onPre}>返回上一步</span>
          </div>
        </div>
      </div>
    </div>
  );
};
