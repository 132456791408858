import { message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '../../components/button';
import { NavigationBar } from '../../modules/navigation-bar';
import { getTodayExam } from '../../utils/api';
import { ExamItem } from '../exam-guide/components/exam-item';
import styles from './index.module.scss';

interface Iprop {

}

export const ExaminationStart = (props: Iprop) => {
  const location = useLocation();
  const navigate = useNavigate();
  const evaluation = location.state?.user;
  const examInfo = location.state?.exam;
  const [todayExamList, setTodayExamList] = useState<any[]>([]);

  useEffect(() => {
    if (!evaluation) {
      message.error('未选择测评人');
      setTimeout(() => {
        navigate('/', { replace: true });
      }, 1000);
    } else {
      getTodayExamData();
    }
  }, []);

  const onLogoutClick = () => {
    localStorage.removeItem('cpatoken');
    navigate(-1);
  };

  const getTodayExamData = () => {
    getTodayExam({ evaluatorId: evaluation.evaluatorId }).then((result: any) => {
      const arr = result.body || [];
      if (arr.length === 0 && process.env.NODE_ENV === 'development') {
        arr.push({...examInfo, sessionsTime:'2022-12-19 21:53:00'});
      }
      arr.forEach((item:any) => item.timestamp = result.timestamp);
      setTodayExamList(arr);
    }).catch((error: any) => message.error(error.msg));
  };

  return (
    <div className={styles.wrapper}>
      <NavigationBar easyMode />
      <div className={`${styles.content} ${todayExamList.length===0 ? styles.empty : ''}`}>
        {
          todayExamList.map((exam: any) => (
            <React.Fragment key={exam.evaluationId}>
              <ExamItem
                exam={exam}
                onlyShowStart
                onStartExam={() => {
                  const { evaluationId } = exam;
                  navigate(
                    `/exam-formal?evaluationId=${evaluationId}`,
                    { state: { evaluation, exam } }
                  );
                }}
              />
            </React.Fragment>
          ))
        }
        {
          todayExamList.length > 0 ? (
            <Button
              text="返回"
              size="big"
              width={140}
              style={{ marginTop: 120 }}
              onClick={onLogoutClick}
            />
          ) : null
        }
        {/* 空状态 */}
        {
          todayExamList.length === 0 ? (
            <div className={styles.empty_wrapper}>
              <div className={styles.image} />
              <div className={styles.rightarea}>
                <p className={styles.title}>您今日无测评</p>
                <p>您好，您今日无测评</p>
                <Button
                  text="返回"
                  size="big"
                  width={160}
                  onClick={onLogoutClick}
                />
                {
                  process.env.NODE_ENV==='development' && examInfo ? (
                    <Button
                      text={`${examInfo.subjectName}(${examInfo.level}级) ${examInfo.sessionsTime}`}
                      style={{ marginTop: 16 }}
                      width={300}
                      onClick={() => {
                        const { evaluationId } = examInfo;
                        navigate(
                          `/exam-formal?evaluationId=${evaluationId}`,
                          { state: { evaluation, exam: examInfo } }
                        );
                      }}
                    />
                  ) : null
                }
              </div>
            </div>
          ) : null
        }
      </div>
    </div>
  );
};
