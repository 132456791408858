import { message, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '../../../../components/button';
import { getSessionList } from '../../../../utils/api';
import { dateStr2Date } from '../../../../utils/util';
import styles from './index.module.scss';

interface Ipro {
  examInfo: any;
  onCancel?: () => void;
  onConfirm?: (sessionId: string) => void;
}
      
export const EditTime = (props: Ipro) => {
  const { examInfo, onCancel, onConfirm } = props;
  const [timeList, setTimeList] = useState<any[]>([]);
  const [sessionId, setSessionId] = useState('');
  const [errorInfo, setErrorInfo] = useState('');
  const nowDateTime = (new Date()).getTime();

  const userInfo = useSelector((state: any) => state.userInfo);
  const whiteList = useSelector((state: any) => state.whiteList);
  const isWhiteUser = whiteList.includes(userInfo.id);

  useEffect(() => {
    const { planId, subjectId, subjectLevelId } = examInfo;
    const param = { planId, subjectId, subjectLevelId };
    getSessionList(param).then((result: any) => {
      const arr = result.body || [];
      arr.forEach((item: any) => {
        const endTime = dateStr2Date(item.sessionsEndTime).getTime();
        item.disabled = nowDateTime > endTime;
      });
      setTimeList(arr);
    }).catch((error: any) => message.error(error.msg));
  }, []);

  const onConfirmClick = () => {
    if (!sessionId) {
      setErrorInfo('请选择测评时间');
      return;
    }
    onConfirm && onConfirm(sessionId);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.input_border}>
          <div className={styles.label}>测评时间</div>
          <div className={styles.content}>
            <div>
              <Select
                style={{width:320}}
                value={sessionId}
                onChange={(value: any) => {
                  setSessionId(value);
                  setErrorInfo('');
                }}
              >
                {
                  timeList
                    .filter((item: any) => item.useTest !== 1 || isWhiteUser)
                    .map((item: any) => (
                    <Select.Option
                      key={item.sessionsId}
                      value={item.sessionsId}
                      disabled={item.disabled}
                    >
                      {item.sessionsTime}{item.useTest ? <span style={{color:'#999999'}}> (内测)</span> : ''}
                    </Select.Option>
                  ))
                }
              </Select>
            </div>
            <div className={styles.btn_group}>
              <Button text="确定" width={120} size="big" onClick={onConfirmClick} />
              <Button text="取消" width={120} type="outgrayline" size="big" style={{marginLeft:8}} onClick={onCancel} />
            </div>
          </div>
          <div className={styles.error}>{errorInfo}</div>
        </div>
    </div>
  );
};
