import { message } from 'antd';
import React, { useEffect, useState } from 'react';
import { AWSCslide } from '../../../../components/AWSC';
import { sendSms, updatePhone } from '../../../../utils/api';
import styles from './index.module.scss';
import { Input } from '../../../../components/input';
import { useSelector } from 'react-redux';
import { Panel } from '../../../../components/panel';

interface LoginProps {
  onClose?: () => void;
}

let _timer: any = 0;

export const EditPhone = (props: LoginProps) => {
  const userInfo = useSelector((state: any) => state.userInfo);
  const { onClose } = props;
  const [formData, setFormData] = useState<any>({
    phone: { value: userInfo.telphone, error: '' },
    smsCode: { value: '', error: '' },
    phone2: { value: '', error: '' },
    smsCode2: { value: '', error: '' },
  });
  const [awscInfo, setAwscInfo] = useState<any>({ sessionId: '', sig: '', token: '' });
  const [isLoging, setIsLoging] = useState(false);
  const [codeTimer, setCodeTimer] = useState(0); // 验证码倒计时
  const [nextStep, setNextStep] = useState(false);

  useEffect(() => {
    return () => {
      clearTimeout(_timer);
    };
  }, []);

  const canLogin = (step1: boolean=false) => {
    if (step1) {
      return  formData.phone.value.trim() &&
        formData.smsCode.value.trim();
    } else {
      return  formData.phone.value.trim() &&
        formData.smsCode.value.trim() &&
        formData.phone2.value.trim() &&
        formData.smsCode2.value.trim();
    }
  };

  const onSmsCodeClick = (key: string) => {
    const phone = formData[key].value;
    if (phone.length !== 11) {
      setFormData({ ...formData, [key]: { value: phone, error: '手机号格式有误' } });
      return;
    }
    const param = {
      ...awscInfo,
      phone,
      scene: 'UPDATE_PHONE',
    };
    sendSms(param).then(() => {
      message.success('短信已发送');
      startSmsTimer();
    }).catch((error: any) => {
      message.error(error.msg);
      window.nc.reset();
      setAwscInfo({ sessionId: '', sig: '', token: '' });
    });
  };

  // 验证码倒计时
  const startSmsTimer = () => {
    const timeFunc = (t: number) => {
      _timer = setTimeout(() => {
        if (t > 0) {
          setCodeTimer(t - 1);
          timeFunc(t - 1);
        } else {
          console.log('倒计时结束', t);
        }
      }, 1000);
    };
    const time = 60;
    setCodeTimer(time);
    timeFunc(time);
  };

  /** 点击下一步 */
  const onNextClick = () => {
    const oldSmsCode = formData.smsCode.value;
    if (oldSmsCode.length !== 6) {
      setFormData({ ...formData, smsCode: { value: oldSmsCode, error: '原验证码格式有误' } });
      return;
    }
    setNextStep(true);
    clearTimeout(_timer);
    setCodeTimer(0);
    window.nc.reset();
    setAwscInfo({ sessionId: '', sig: '', token: '' });
  };

  /** 点击提交 */
  const onLoginClick = () => {
    const oldPhone = formData.phone.value;
    const oldSmsCode = formData.smsCode.value;
    const newPhone = formData.phone2.value.trim();
    const newSmsCode = formData.smsCode2.value.trim();
    // 验证入参合法性
    if (newPhone.length !== 11) {
      setFormData({ ...formData, phone2: { value: newPhone, error: '新手机号格式有误' } });
      return;
    } 
    if (newSmsCode.length !== 6) {
      setFormData({ ...formData, smsCode2: { value: newSmsCode, error: '新验证码格式有误' } });
      return;
    }
    // 根据类型组织参数
    let param: any = { oldPhone, oldSmsCode, newPhone, newSmsCode };
    setIsLoging(true);
    updatePhone(param).then(() => {
      message.success('手机号修改成功');
      setTimeout(() => {
        setIsLoging(false);
        onClose && onClose();
      }, 800);
    }).catch((error: any) => {
      setIsLoging(false);
      setNextStep(false);
      clearTimeout(_timer);
      setCodeTimer(0);
      window.nc.reset();
      setAwscInfo({ sessionId: '', sig: '', token: '' });
      message.error(error.msg);
      setFormData({
        ...formData,
        smsCode: { value: '', error: '' },
        smsCode2: { value: '', error: '' },
      });
    });
  };
  
  return (
    <Panel title="修改手机号" onClose={onClose}>
      {
        nextStep ? (
          <Input
            label="新手机号"
            labelWidth={95}
            type="number"
            maxLength={11}
            value={formData.phone2.value}
            onChange={(value: string) => {
              setFormData({ ...formData, phone2: { value, error: ''} });
            }}
            errorInfo={formData.phone2.error}
          />
        ) : (
          <Input
            label="原手机号"
            labelWidth={95}
            type="number"
            disable
            value={formData.phone.value}
          />
        )
      }
      <div className={styles.row} style={{marginBottom:24}}>
        <div className={styles.space} />
        <div>
          <AWSCslide
            appkey={process.env.REACT_APP_AWSC_APPKEY || ''}
            scene={process.env.REACT_APP_AWSC_SCENE || ''}
            success={data => {
              setAwscInfo(data);
            }}
          />
        </div>
      </div>
      <Input
        label="验证码"
        labelWidth={95}
        type="number"
        // icon="smscode"
        placeholder="请输入验证码"
        maxLength={6}
        value={nextStep ? formData.smsCode2.value: formData.smsCode.value}
        onChange={(value: string) => {
          setFormData({ ...formData, [nextStep ? 'smsCode2' : 'smsCode']: { value, error: ''} });
        }}
        errorInfo={nextStep ? formData.smsCode2.error: formData.smsCode.error}
      >
        <div
          className={`
            ${styles.btn_smscode}
            ${(formData.phone.value.trim() && awscInfo.token && codeTimer===0) ? '' : styles.disable}
          `}
          onClick={() => onSmsCodeClick(nextStep ? 'phone2' : 'phone')}
        >
          { codeTimer>0 ? `${codeTimer}s重新获取` : '获取验证码' }
        </div>
      </Input>
      
      <div className={styles.row} style={{marginTop:40}}>
        <div className={styles.space} />
        <div>
          {
            !nextStep ? (
              <div
                className={`${styles.btn_login} ${canLogin(true) ? '' : styles.disable}`}
                onClick={onNextClick}
              >下一步</div>
            ) : (
              <div
                className={`${styles.btn_login} ${(canLogin() && !isLoging) ? '' : styles.disable}`}
                onClick={onLoginClick}
              >
                { isLoging ? '提交中...' : '提交' }
              </div>
            )
          }
        </div>
      </div>
    </Panel>
  );
};
