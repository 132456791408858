import { createStore, combineReducers } from 'redux';

let cacheUserInfo: any = localStorage.getItem('userInfo');
if (cacheUserInfo) {
  cacheUserInfo = JSON.parse(cacheUserInfo);
} else {
  cacheUserInfo = {};
}

const AppStateInit = {
  userInfo: {
    telphone: cacheUserInfo.telphone || '',
    id: cacheUserInfo.id || null,
  },
}

export enum ActionType {
  UPDATE_USERINFO = 'update_userinfo',
  UPDATE_PUBLISHINFO = 'update_publishinfo',
  UPDATE_TRACKS = 'update_tracks',
  ADD_EXAM_ERROR = 'add_exam_error',
  CLEAR_EXAM_ERROR = 'clear_exam_error',
}

export interface IAction {
  type: ActionType;
  value: any;
}

const userInfo = (userInfo: any = AppStateInit.userInfo, action: IAction) => {
  switch (action.type) {
    case ActionType.UPDATE_USERINFO:
      return { ...userInfo, ...action.value };
    default:
      return userInfo;
  }
};

const publishInfo = (publishInfo: any = { url: '' }, action: IAction) => {
  switch (action.type) {
    case ActionType.UPDATE_PUBLISHINFO:
      return { ...publishInfo, ...action.value };
    default:
      return publishInfo;
  }
};

const tracks = (tracks: any = { screen: null, audio: null }, action: IAction) => {
  switch (action.type) {
    case ActionType.UPDATE_TRACKS:
      return { ...tracks, ...action.value };
    default:
      return tracks;
  }
};

/**
 * 内测用户白名单
 * @returns 
 */
const whiteList = () => {
  return [
    "3435931f818441eeb2f54ce2e9d5881b",
    "6709a0256b81413d8d476c7cf494da70",
    "dc32c2f94bbd45759e75e3e4a44cdfe1",
    "5368c858f4d74247988d6817be21d45c",
    "774590f9a20941ea9c9509390130f0a5",
    "acba128c362d4d3e9266adf11c81434d",
    '0104efda236643189cadfb86b7f5d100',
    'ff5b9a31f5b347eba6cae4b554ceed82'
  ];
};

const examErrors = (examErrors: any = [], action: IAction) => {
  switch (action.type) {
    case ActionType.ADD_EXAM_ERROR:
      return [action.value, ...examErrors];
    case ActionType.CLEAR_EXAM_ERROR:
      return [];
    default:
      return examErrors;
  }
};

const reducer = combineReducers({ userInfo, publishInfo, tracks, examErrors, whiteList });
export const store = createStore(reducer);