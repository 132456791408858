import React from 'react';
import { EQuestionType } from '../..';
import styles from './index.module.scss';

interface Iprop {
  questionList: any[];
  questionIndex: number;
  isReview?: boolean;
  onChange?: (value: number) => void;
}

export const AnswerCard = (props: Iprop) => {
  const { questionList, questionIndex, isReview, onChange } = props;
  const singleList = questionList.filter(item => item.questionType === EQuestionType.Single);
  const multiList = questionList.filter(item => item.questionType === EQuestionType.Multi);
  const judgeList = questionList.filter(item => item.questionType === EQuestionType.Judge);
  const codeList = questionList.filter(item => item.questionType === EQuestionType.Code);
  const allList = [];
  if (singleList.length) {
    allList.push({ title: '单选题', list: singleList });
  }
  if (multiList.length) {
    allList.push({ title: '多选题', list: multiList });
  }
  if (judgeList.length) {
    allList.push({ title: '判断题', list: judgeList });
  }
  if (codeList.length) {
    allList.push({ title: '编程题', list: codeList });
  }

  const userAnswerCorrect = (item: any) => {
    if (item.questionType === EQuestionType.Multi) {
      const userArr = (item.userAnswer || '').split(',').sort();
      const rightArr = (item.rightAnswer.answer || '').split(',').sort();
      return userArr.join(',') === rightArr.join(',');
    } else {
      return item.userAnswer === item.rightAnswer.answer;
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>答题卡</div>
      {
        allList.map((data: any) => (
          <React.Fragment key={data.title}>
            <div className={styles.type_title}>{data.title}</div>
            <div className={styles.btn_box}>
            {
              data.list.map((item: any, index: number) => (
                <span
                    key={item.questionId}
                    className={`${styles.box}
                      ${questionIndex===item.questionIndex ? styles.current : ''}
                      ${isReview ? (userAnswerCorrect(item) ? styles.filled : styles.wrong) : (item.complete ? styles.filled : '')}
                      ${item.check ? styles.check : ''}
                    `}
                    onClick={() => onChange && onChange(item.questionIndex)}
                  >
                    {index+1}
                  </span>
              ))
            }
            </div>
          </React.Fragment>
        ))
      }
    </div>
  );
};

export const AnswerCardLegend = (props: { isReview?:boolean }) => {
  const { isReview=false } = props;
  return (
    <div className={styles.legend}>
      {
        isReview ? (
          <>
            <span className={styles.label}>答对</span>
            <span className={`${styles.box} ${styles.filled}`} />
            <span className={styles.label}>答错</span>
            <span className={`${styles.box} ${styles.wrong}`} />
          </>
        ) : (
          <>
            <span className={styles.label}>未做</span>
            <span className={styles.box} />
            <span className={styles.label}>当前</span>
            <span className={`${styles.box} ${styles.current}`} />
            <span className={styles.label}>已做</span>
            <span className={`${styles.box} ${styles.filled}`} />
            <span className={styles.label}>待查</span>
            <span className={`${styles.box} ${styles.check}`} />
          </>
        )
      }
    </div>
  );
};
