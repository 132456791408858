import { Modal } from "antd";
import JSZip from 'jszip';

/**
  * 检测当前浏览器是否为Chrome以及版本号
  */
 export const getChromeVersion = () => {
  const isChrome = window.navigator.userAgent.indexOf("Chrome") !== -1;
  if (isChrome) {
    const arr = navigator.userAgent.split(' '); 
    let chromeVersion: any = '';
    for (var i=0; i<arr.length; i++) {
      if(/chrome/i.test(arr[i]))
      chromeVersion = arr[i]
    }
    // 最低版本要求：90？
    return Number(chromeVersion.split('/')[1].split('.')[0]);
  } else {
    return 0;
  }
};

/**
 * 全屏
 */
export const requestFullscreen = (onError: any = null) => {
  const docElm: any = document.documentElement;
  if (docElm.requestFullscreen) {
    docElm.requestFullscreen();
  } else if (docElm.msRequestFullscreen) {
    docElm.msRequestFullscreen();
  } else if (docElm.mozRequestFullScreen) {
    docElm.mozRequestFullScreen();
  } else if (docElm.webkitRequestFullScreen) {
    docElm.webkitRequestFullScreen();
  }
  setTimeout(() => {
    const isFullScreen = document.fullscreenElement !== null;
    if (!isFullScreen) {
      onError && onError();
    }
  }, 100);
};

/**
 * 退出全屏
 */
export const exitFullScreen = () => {
  const isFullScreen = document.fullscreenElement !== null;
  if (!isFullScreen) return;
  const _document: any = document;
  if (_document.exitFullscreen) {
    _document.exitFullscreen()
  } else if (_document.msExitFullscreen) {
    _document.msExitFullscreen()
  } else if (_document.mozCancelFullScreen) {
    _document.mozCancelFullScreen()
  } else if (_document.webkitCancelFullScreen) {
    _document.webkitCancelFullScreen()
  }
};

/**
 * 获取用户摄像头
 * @param audio 检测时，要开启麦克风。 真正推流时，不用开启，因为阿里推流SDK开启了。
 * @returns Promise(stream)
 */
export const getUserMediaVideo = async (audio: boolean = false) => {
  return new Promise((resolve, reject) => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({
        video: true,
        audio,
      }).then(function (stream) {
        resolve(stream);
      }).catch(function (err) {
        console.log('捕获摄像头失败', err);
        if (err.toString().includes('Permission denied')) {
          reject('您拒绝了摄像头访问权限，请前往浏览器设置中开启');
        } else {
          reject('摄像头开启失败，请检查您的设备');
        }
      })
    } else {
      reject('当前浏览器不支持获取摄像头');
    }
  });
};

/**
 * 开始屏幕共享
 * @returns Promise(stream)
 */
export const getDisplayMediaVideo = () => {
  const displayMediaOptions = {
    // audio: true,
    video: { width: 1280, height: 720 }
  };
  return new Promise((resolve, reject) => {
    if (navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia) {
      navigator.mediaDevices.getDisplayMedia(displayMediaOptions).then((stream: any) => {
        resolve(stream);
      }).catch((error: any) => {
        console.log('屏幕共享失败', error);
        if (error.toString().includes('Permission denied by system')) {
          reject('您拒绝了屏幕共享权限，请前往系统设置中开启');
        } else if (error.toString().includes('Permission denied')) {
          reject('您取消了屏幕共享');
        } else {
          reject('屏幕共享失败，请检查您的设备');
        }
      });
    } else {
      reject('当前浏览器不支持屏幕共享');
    }
  });
}

/**
 * 获取url上的参数
 * @param key 参数key
 * @returns 参数值
 */
 export const getQuery = (key: string) =>
  new URLSearchParams(window.location.search.substring(1)).get(key);

/**
 * 把日期字符串转换为日期对象
 * @param datestr 日期字符串
 * @returns 日期对象
 */
export const dateStr2Date = (datestr: string) => {
  const tmpArr = datestr.split(' ');
  const dateArr = tmpArr[0].split('-');
  const timeArr = tmpArr[1].split(':');
  const resDate = new Date(
    parseInt(dateArr[0]), parseInt(dateArr[1])-1, parseInt(dateArr[2]),
    parseInt(timeArr[0]), parseInt(timeArr[1]), parseInt(timeArr[2])
  );
  return resDate;
};

/**
 * 解析Scratch图形编辑器的sb3文件
 * @param sb3Blob 
 * @returns 图形数据
 */
export const analyzeSb3 = (sb3Blob: Blob) => {
  return new Promise<any>((resolve, reject) => {
    const zip = new JSZip()
    zip.loadAsync(sb3Blob).then(() => {
      const projectJson = zip.file('project.json');
      if (projectJson) {
        projectJson.async('string').then((value: any) => {
          try {
            const projectData = JSON.parse(value);
            resolve(projectData);
          } catch (error) {
            const msg = '解析sb3文件失败';
            console.log(msg, error);
            reject(msg);
          }
        })
      } else {
        const msg = 'sb3文件中没有project.json';
        console.log(msg);
        reject(msg);
      }
    }).catch(error => {
      const msg = '解压sb3文件失败';
      console.log(msg, error);
      reject(msg);
    })
  });
};
