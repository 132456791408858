import React from 'react';
import styles from './index.module.scss';

interface Iprop {
  text: string;
  label?: string;
  toLeft?: boolean;
}

export const Reason = (props: Iprop) => {
  const { text, label='审核失败原因', toLeft=false } = props;
  return (
    <div className={`${styles.reason} ${toLeft ? styles.toleft : ''}`}>
      <div className={styles.popup}>{label}：<br/>{text}</div>
    </div>
  );
};
