import axios from 'axios';

export const request = async (path:string, param:any, method:string = 'post') => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: '',
  };
  const token = localStorage.getItem('token');
  headers.Authorization = 'Bearer ' + token;
  return new Promise(async (resolve, reject) => {
    const payload: any = {
      url: apiUrl + path,
      method,
      headers,
      data: param || {},
      responseType: 'json',
      timeout: 8000,
    }
    axios(payload).then((result: any) => {
      if (result.data.code === 200) {
        resolve(result.data);
      } else if (result.data.code === 1001) {
        const route = `${window.location.pathname}${window.location.search}`;
        const redirect = encodeURIComponent(route);
        window.location.replace(`/?redirect=${redirect}`);
      } else {
        result.data.msg = result.data.msg || result.data.message;
        reject(result.data);
      }
    }).catch((error: any) => {
      reject({ code: -1, msg: error.message || '网络错误' });
    });
  });
};

export const getStatic = async (url: string, options: any = {}) => {
  return new Promise(async (resolve, reject) => {
    axios({
      url,
      ...options,
    }).then((result: any) => {
      resolve(result.data);
    }).catch((error: any) => {
      reject({ code: '500', msg: error.message });
    });
  });
};
