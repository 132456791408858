import { message, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Empty } from '../../../components/empty';
import { getOrganizationList } from '../../../utils/api';
import { getQuery } from '../../../utils/util';
import styles from '../index.module.scss';

interface Iprop {
  evaluatorList: any[];
  evaluatorIndex: number;
  organizationId: string;
  onNext?: () => void;
  onEvaluatorChange?: (idx:any) => void;
  onOrganizationChange?: (id:any) => void;
}

export const CheckUserInfo = (props: Iprop) => {
  const navigate = useNavigate();
  const {
    onNext,
    evaluatorList,
    evaluatorIndex,
    organizationId,
    onEvaluatorChange,
    onOrganizationChange,
  } = props;
  // const oranization = getQuery('oranization') === '1';
  const [organizations, setOrganizations] = useState<any[]>([]);

  useEffect(() => {
    // if (oranization) {
      getOrganizationData();
    // }
  }, []);

  const getOrganizationData = () => {
    getOrganizationList({}).then((result: any) => {
      let list = result.body || [];
      list.forEach((item: any) => item.id = item.id.toString());
      list = [ { id: '0', name: '个人报名' }, ...list ];
      setOrganizations(list);
    }).catch((error: any) => {
      console.log('获取机构列表失败', error);
    });
  };

  const gotoUserEdit = () => {
    const user = evaluatorList[evaluatorIndex];
    navigate('/evaluator-edit', { state: user });
  };

  return (
    <div className={styles.form_wrapper}>
      {
        evaluatorList.length > 0 ? (
          <>
          <div className={styles.form_item}>
            <div className={styles.label}>选择测评人</div>
            <Select
              style={{flex:1}}
              value={evaluatorIndex}
              onChange={onEvaluatorChange}
            >
              {
                evaluatorList.map((evaluator: any, eidx: number) => (
                  <Select.Option key={evaluator.evaluatorId} value={eidx}>{evaluator.evaluatorName}</Select.Option>
                ))
              }
            </Select>
          </div>
          <div className={styles.cutline} />
            <div className={styles.form_item} style={{alignItems:'flex-start'}}>
              <div className={styles.label} style={{marginTop:9}}>本人照片</div>
              <div className={styles.photo_wrapper}>
                <img className={styles.photo} src={evaluatorList[evaluatorIndex].avatar} />
                <p className={styles.photo_tip}>此照片将是您的测评证照片和证书照片（如通过测评），请务必按要求提供！</p>
              </div>
            </div>
            <div className={styles.form_item}>
              <div className={styles.label}>姓名</div>
              <div className={styles.input}>{evaluatorList[evaluatorIndex].evaluatorName}</div>
            </div>
            <div className={styles.form_item}>
              <div className={styles.label}>证件类型</div>
              <div className={styles.input}>{['','居民身份证','港澳通行证', '台湾通行证', '护照'][evaluatorList[evaluatorIndex].idType]}</div>
            </div>
            <div className={styles.form_item}>
              <div className={styles.label}>证件号码</div>
              <div className={styles.input}>{evaluatorList[evaluatorIndex].cardNumber}</div>
            </div>
            {/* <div className={styles.form_item}>
              <div className={styles.label}>性别</div>
              <div className={styles.input}>{['女','男'][evaluatorList[evaluatorIndex].sex]}</div>
            </div> */}
            {/* <div className={styles.form_item}>
              <div className={styles.label}>所在地区</div>
              <div className={styles.input}>
                {`${evaluatorList[evaluatorIndex].province}/${evaluatorList[evaluatorIndex].city}/${evaluatorList[evaluatorIndex].area}`}
              </div>
            </div> */}
            {/* <div className={styles.form_item}>
              <div className={styles.label}>详细住址</div>
              <div className={styles.input}>{evaluatorList[evaluatorIndex].address}</div>
            </div> */}
            {/* <div className={styles.form_item}>
              <div className={styles.label}>就读学校</div>
              <div className={styles.input}>{evaluatorList[evaluatorIndex].schoolName}</div>
              <div className={styles.tip}>请准确填写所在地区和详细地址，将用于邮寄等级证书。</div>
            </div> */}
            {
              // oranization ? (
                <div className={styles.form_item}>
                  <div className={`${styles.label} ${styles.unrequired}`}>报名团体</div>
                  <Select
                    style={{flex:1}}
                    value={organizationId}
                    onChange={onOrganizationChange}
                  >
                    {
                      organizations.map((org: any) => (
                        <Select.Option key={org.id} value={org.id}>{org.name}</Select.Option>
                      ))
                    }
                  </Select>
                  <div className={styles.tip}>如您是团体报名的学生，请务必选择您的团体名称</div>
                </div>
              // ) : null
            }
            {/* <div className={styles.form_item}>
              <div className={styles.label}>紧急联系人</div>
              <div className={styles.input}>{evaluatorList[evaluatorIndex].liaison}</div>
            </div> */}
            {/* <div className={styles.form_item}>
              <div className={styles.label}>联系人号码</div>
              <div className={styles.input}>{evaluatorList[evaluatorIndex].liaisonPhone}</div>
              <div className={styles.tip}>此手机号仅作为邮寄证书使用，不作为登录账号。</div>
            </div> */}
            <div className={styles.form_item} style={{marginTop:60, marginBottom:12}}>
              <div className={styles.label_empty}></div>
              <div
                className={styles.btn_next}
                onClick={() => {
                  // if (oranization && organizationId === '0') {
                  //   message.error('请选择就读机构');
                  //   return;
                  // }
                  onNext && onNext();
                }}
              >
                下一步
              </div>
            </div>
            <div className={styles.form_item} style={{marginTop:0}}>
              <div className={styles.label_empty}></div>
              <div className={styles.btn_tip_bar}>
                <span className={styles.icon} />
                <div className={styles.btn_tip}>
                  如报名身份信息有误，请前往
                  <span className={styles.link} onClick={gotoUserEdit}>个人资料</span>
                  处重新认证。报名成功后信息将不允许修改。
                </div>
              </div>
            </div>
          </>
        ) : (
          <Empty
            message="当前无测评人信息"
            btnText="添加测评人"
            onBtnClick={() => navigate('/evaluator-edit')}
          />
        )
      }
    </div>
  );
};
