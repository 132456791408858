import { message, Select } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../components/button';
import { Panel } from '../../components/panel';
import { ActionType } from '../../store';
import { getStatic } from '../../utils/request';
import { getChromeVersion, getUserMediaVideo } from '../../utils/util';
import styles from './index.module.scss';
import { checkWhiteUser } from '../../utils/api';

let videoTrack: any = null;
let audioTrack: any = null;

interface Iprop {
  pushUrl: string;
  onComplete?: () => void;
}

export const EnviromentCheck = (props: Iprop) => {
  const { pushUrl, onComplete } = props;
  const isMac = window.navigator.userAgent.indexOf("Mac") >- 1;
  const [browserOK, setBrowserOK] = useState(false);
  const videoCamera = useRef<any>(null);
  const videoScreen = useRef<any>(null);
  const [stepIndex, setStepIndex] = useState(0);
  const [cameraList, setCameraList] = useState<any[]>([]);
  const [cannotSee, setCannotSee] = useState(false);
  const [showGuideVideo, setShowGuideVideo] = useState(false);
  const [screenShareState, setScreenShareState] = useState(0); // 0-指导 1-成功 2-取消 3-停止
  const screenStateText = ['', '屏幕实时分享通过审核', '未同意屏幕实时分享，无法参加测评', '屏幕实时分享关闭，未通过审核'];
  
  const publishInfo = useSelector((state: any) => state.publishInfo);
  const tracks = useSelector((state: any) => state.tracks);
  const dispatch = useDispatch();
  const userInfo = useSelector((state: any) => state.userInfo);

  useEffect(() => {
    checkBrowser();
    return () => {
      clearTracks();
    };
  }, []);

  const clearTracks = () => {
    if (videoTrack) {
      videoTrack.stop();
      videoTrack = null;
    }
    if (audioTrack) {
      audioTrack.stop();
      audioTrack = null;
    }
  };

  const checkBrowser = () => {
    const version = getChromeVersion();
    if (version > 0) {
      const miniVersion = Number(process.env.REACT_APP_CHROME_VERSION || 60);
      if (version >= miniVersion) {
        setBrowserOK(true);
      }
    }
  };

  const checkCameraMicrophone = () => {
    if (videoTrack || audioTrack) {
      return;
    }
    // 开启摄像头和麦克风
    getUserMediaVideo(true).then((stream: any) => {
      videoCamera.current.srcObject = stream;
      videoCamera.current.play();
      const tracks =  stream.getTracks();
      videoTrack = tracks[0];
      audioTrack = tracks[1];
      // 获取设备摄像头列表
      navigator.mediaDevices.enumerateDevices().then(devices => {
        if (devices) {
          const camera = devices.find(item => item.kind === 'videoinput');
          if (camera) {
            setCameraList([camera]);
            console.log(camera);
          }
        }
      });
    }).catch(error => message.error(error));
  };

  useEffect(() => {
    if (tracks.screen) {
      setScreenShareState(1);
    } else {
      if (screenShareState === 1) {
        setScreenShareState(3);
      }
    }
    // setScreenShareState(2);
  }, [tracks.screen]);

  const checkShareScreen = () => {
    dispatch({
      type: ActionType.UPDATE_PUBLISHINFO,
      value: { url: pushUrl },
    });
  };

  return (
    <div className={styles.wrapper}>
      {/* 步骤条 */}
      <div className={styles.step_bar}>
        <div className={`${styles.step} ${stepIndex===0 ? styles.active : ''} ${stepIndex>0 ? styles.complete : ''}`}>
          <span className={styles.num}><i>1</i></span>
          <span className={styles.name}>浏览器检测</span>
          <span className={styles.summary}>需使用高版本现代浏览器<br />（如Chrome谷歌浏览器）</span>
        </div>
        <div className={`${styles.line} ${stepIndex>0 ? styles.active : ''}`} />
        <div className={`${styles.step} ${stepIndex===1 ? styles.active : ''} ${stepIndex>1 ? styles.complete : ''}`}>
          <span className={styles.num}><i>2</i></span>
          <span className={styles.name}>摄像头检测</span>
          <span className={styles.summary}>需要打开摄像头才可进行测评</span>
        </div>
        <div className={`${styles.line} ${stepIndex>1 ? styles.active : ''}`} />
        <div className={`${styles.step} ${stepIndex===2 ? styles.active : ''}`}>
          <span className={styles.num}><i>3</i></span>
          <span className={styles.name}>屏幕实时分享检测</span>
          <span className={styles.summary}>需授权屏幕分享才可进行测评</span>
        </div>
      </div>
      {/* 三个检测模块 */}
      {
        stepIndex === 0 ? (
          <div className={styles.step_wrapper}>
            <div className={`${styles.check_title} ${browserOK ? '' : styles.error}`}>
              {browserOK ? '您的浏览器通过检测，请点击下一步进行摄像头检测' : '您的浏览器未通过检测，请下载安装谷歌浏览器'}
            </div>
            {
              browserOK ? (
                <Button
                  size="big"
                  width={160}
                  text="下一步"
                  onClick={() => {
                    setStepIndex(1);
                    checkWhiteUser({ pType:1, phone:userInfo.telphone }).then((result:any) => {
                      if (result.code === 200 && result.body.result) {
                        message.success('摄像头检测通过，请点击“能看到”');
                      } else {
                        checkCameraMicrophone();
                      }
                    }).catch(() => {
                      checkCameraMicrophone();
                    });
                  }}
                />
              ) : (
                <div className={styles.browser_group}>
                  {/* <div className={styles.item}>
                    <img className={styles.browser} src="https://static.cpa-test.cn/web-assets/image/360-logo-icon.png" />
                    <Button
                      size="big"
                      width={160}
                      text="下载360浏览器"
                      onClick={() => window.open('https://browser.360.cn/se/')}
                    />
                  </div> */}
                  <div className={styles.item}>
                    <img className={styles.browser} src="https://static.cpa-test.cn/web-assets/image/chrome-logo-m100.svg" />
                    <Button
                      size="big"
                      width={160}
                      text="下载谷歌浏览器"
                      onClick={() => window.open('https://www.google.cn/intl/zh-CN/chrome/')}
                    />
                  </div>
                </div>
              )
            }
          </div>
        ) : null
      }
      {
        stepIndex === 1 ? (
          <div className={styles.step_wrapper2}>
            <div>
              <div className={styles.title}>摄像头影像</div>
              <video ref={videoCamera} className={styles.video} muted />
            </div>
            <div className={styles.right_area}>
              <div className={styles.title}>摄像头</div>
              <Select value={cameraList.length ? cameraList[0].deviceId : ''} style={{width:320}}>
                {
                  cameraList.map((camera: any) => (
                    <Select.Option key={camera.deviceId} value={camera.deviceId}>{camera.label}</Select.Option>
                  ))
                }
              </Select>
              <div className={`${styles.notice} ${cannotSee ? styles.brightness : ''}`}>
                <div className={styles.second_title}>注意事项</div>
                <p>
                  <span>1.</span>如果您不能看到自己的图像，可能是系统存在多个摄像头设备，请尝试选择不同的摄像头直到能看到自己的图像。
                </p>
                <p>
                  <span>2.</span>如果多次尝试后扔看不到自己的图像，可能摄像头损坏，请更换摄像头或有摄像头的电脑设备。
                </p>
                <p>
                  <span>3.</span>考试过程中全程人脸识别，如不能使用摄像头则无法参加考试。
                </p>
                <p>
                  <span>4.</span>如检测遇到异常请查看问题处理视频。
                  <a className={styles.btn_link} onClick={() => setShowGuideVideo(true)}>点击查看</a>
                </p>
              </div>
              <div className={styles.title}>能否看到自己的图像？</div>
              <div className={styles.btn_group}>
                <Button
                  size="big"
                  width={160}
                  text="能看到"
                  onClick={() => {
                    clearTracks();
                    setStepIndex(2);
                  }}
                />
                <Button
                  size="big"
                  type="outline"
                  width={160}
                  style={{marginLeft:8}} text="不能看到"
                  onClick={() => setCannotSee(true)}
                />
              </div>
            </div>
          </div>
        ) : null
      }
      {
        stepIndex === 2 ? (
          !screenShareState ? (
            <div className={styles.step_wrapper2}>
              <div>
                <div className={styles.title}>指导视频</div>
                <video
                  ref={videoScreen}
                  className={styles.video}
                  src="https://static.cpa-test.cn/web-assets/video/guide2-screen_share.mp4"
                  controls
                />
              </div>
              <div className={styles.right_area}>
                <div className={styles.title}>注意事项</div>
                <div className={styles.notice}>
                  <p>
                    <span>1.</span>本测评需要全程屏幕分享才可以参加考试，考试途中不可以退出屏幕分享。
                  </p>
                  <p>
                    <span>2.</span>考试途中会对浏览器屏幕实时监控，若有离开考试界面的操作，将会影响考试成绩。
                  </p>
                  <p>
                    <span>3.</span>点击下方开启屏幕分享按钮，选择考试界面所用的屏幕，必须选择分享整个屏幕，且考试界面再次屏幕中。
                  </p>
                  <p>
                    <span>4.</span>分享成功后会出现屏幕分享的小浮窗，点击隐藏即可。
                  </p>
                  <p>
                    <span>5.</span>具体操作方法可以查看右侧的指导视频。
                  </p>
                </div>
                <div className={styles.btn_group}>
                  <Button
                    size="big"
                    width={160}
                    text="开启屏幕分享"
                    onClick={checkShareScreen}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.screen_check}>
              <div className={`${styles.state_img} ${screenShareState===1 ? '' : styles.error}`} />
              <div
                style={{marginTop:34}}
                className={`${styles.check_title} ${screenShareState===1 ? '' : styles.error}`}
              >
                {screenStateText[screenShareState]}
              </div>
              <Button
                size="big"
                width={160}
                text={['','完成检测','重新检测','重新分享'][screenShareState]}
                onClick={() => {
                  if (screenShareState === 1) {
                    onComplete && onComplete();
                  } else {
                    checkShareScreen();
                  }
                }}
              />
            </div>
          )
        ) : null
      }
      {/* 摄像头指导视频弹窗 */}
      {
        showGuideVideo ? (
          <Panel title="摄像头检测异常处理视频" width={800} onClose={() => setShowGuideVideo(false)}>
            <video
              ref={videoScreen}
              style={{ width:640, height:480, backgroundColor: '#000000' }}
              src={
                isMac ?
                  'https://static.cpa-test.cn/web-assets/video/guide1-video_audui-mac.mp4' :
                  'https://static.cpa-test.cn/web-assets/video/guide1-video_audui-win.mp4'
              }
              controls
            />
          </Panel>
        ) : null
      }
    </div>
  );
};
