import { message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '../../components/button';
import { EnviromentCheck } from '../../modules/environment-check';
import { FaceCompare } from '../../modules/face-compare';
import { NavigationBar } from '../../modules/navigation-bar';
import { getQuery } from '../../utils/util';
import styles from './index.module.scss';
      
export const EnvCheck = (props: any) => {
  const notice = [
    '青少年编程能力等级测评采用多维度的监查手段确保测评在公正的条件下进行，包括生物特征身份识别、AI监查、屏幕监控、掉线限制等方式方法；测评全程需要开启摄像头并保证能够实时抓取到参评人员测评画面。',
    '生物特征身份识别有概率识别失败，请关闭浏览器重新识别。',
    '本次测评具有异常状态提醒功能，如发现异常测评状态，提醒参与测评的青少年，给予自我纠正的机会，如果多次提醒没有纠正将取消该青少年本次测评成绩并记录在案。',
    '本次参与测评的青少年必须严格遵守测评纪律，开始测评三十分钟后方能交卷，三十分钟内交卷出现问题后果自负，青少年编程能力等级测评委员会不予负责。',
    '测评过程中参与测评的青少年若出现网络断开、网路掉线、失误删除答题界面等特殊情况只需要重新登录答题页面即可继续答题，本次测评必须使用谷歌浏览器 。',
    '测评过程中如出现图形化编程题目没有加载出预留程序或预留素材，可能是由于网络缓慢问题导致，请检查网络或更换其他网络后再关闭浏览器重新打开试卷。',
  ];
  const type = getQuery('type') || '';
  const [stepFlag, setStepFlag] = useState(type ? 0 : 1); // 0-须知 1-环境检测 2-人脸比对
  const navigate = useNavigate();
  const location = useLocation();
  const evaluation = location.state?.user;
  const exam = location.state?.exam;

  useEffect(() => {
    if (type && !evaluation) {
      message.error('未选择测评人');
      setTimeout(() => {
        navigate('/', { replace: true });
      }, 1000);
    }
  }, []);

  return (
    <div className={styles.wrapper}>
      <NavigationBar easyMode />
      <div className={styles.content}>
        {
          stepFlag === 0 ? (
            <>
              <div className={styles.notice}>
                <h1>线上测评须知</h1>
                {
                  notice.map((item: string, index: number) => (
                    <p key={index}><span>{index+1}.</span>{item}</p>
                  ))
                }
                <p className={styles.bottom}>
                  青少年编程能力等级测评委员会
                  <br />
                  二零二零年四月
                </p>
                <span className={styles.modal} />
              </div>
              <Button text="接受" size="big" width={160} style={{marginTop:56}} onClick={() => setStepFlag(1)} />
            </>
          ) : null
        }
        {
          stepFlag === 1 ? (
            <EnviromentCheck
              // pushUrl={type==='exam' ? exam.pushUrl : 'mock'} // 正式测评也不推流
              pushUrl="mock"
              onComplete={() => {
                sessionStorage.setItem('env-check-success', '1');
                if (['exam', 'mock', 'mock2'].includes(type)) {
                  setStepFlag(2);
                } else {
                  message.success('环境检测成功');
                  navigate(-1);
                }
              }}
            />
          ) : null
        }
        {
          stepFlag === 2 ? (
            <FaceCompare
              evaluation={evaluation}
              onComplete={() => {
                message.success('审核通过');
                if (type === 'exam') {
                  navigate('/examination-start', { state: { user: evaluation, exam }, replace: true });
                } else if (type === 'mock') {
                  navigate('/exam-mock-start', { state: { user: evaluation }, replace: true });
                } else if (type === 'mock2') {
                  const { evaluationId, paperId } = location.state.exam;
                  navigate(
                    `/exam-formal?evaluationId=${evaluationId}&paperId=${paperId}&mock=1`,
                    { state: { evaluation }, replace: true }
                  );
                } else {
                  navigate(-1);
                }
              }}
            />
          ) : null
        }
      </div>
    </div>
  );
};
