import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { menus } from './data';
import styles from './index.module.scss';
      
export const MenuBar = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();

  const getMenuIndexByLocation = () => {
    let menuIndex = 0;
    for (let i=0; i<menus.length; i++) {
      if (menus[i].route === location.pathname) {
        menuIndex = i;
        break;
      } else if (menus[i].children && menus[i].children.length) {
        for (let j=0; j<menus[i].children.length; j++) {
          if (menus[i].children[j].route === location.pathname) {
            menuIndex = i;
            break;
          }
        }
      }
    }
    return menuIndex;
  };

  
  const [currentIndex, setCurrentIndex] = useState(getMenuIndexByLocation());

  useEffect(() => {
    const menuIndex = getMenuIndexByLocation();
    if (menuIndex !== currentIndex) {
      setCurrentIndex(menuIndex);
    }
  }, [location.pathname]);

  // useEffect(() => {
  //   const menu = menus.find((menu: any) => menu.route === location.pathname);
  //   if (menu) {
  //     const idx = menus.indexOf(menu);
  //     if (idx !== currentIndex) {
  //       setCurrentIndex(idx);
  //     }
  //   }
  // }, [
  //   location.pathname
  // ]);

  const onMenuClick = (menu: any, index: number) => {
    const menuRoute = menu.route.split('?')[0];
    if (menuRoute !== location.pathname) {
      navigate(menu.route);
    } else {
      navigate(menu.route, { replace: true });
    }
  }
  
  return (
    <div className={styles.wrapper}>
      {
        menus.map((menu:any, index:number) => (
          <div
            className={`${styles.menu} ${currentIndex===index ? styles.active : ''}`}
            key={menu.key}
            onClick={() => onMenuClick(menu, index)}
          >
            <span className={`${styles.icon} ${styles[menu.key]}`} />{menu.name}
          </div>
        ))
      }
    </div>
  );
};
