import { getStatic, request } from './request';
import OSS from 'ali-oss';

export const getOrganizationList = (param: any) => request('/testapi/home/getorganizationlist/v1.0', param);
export const loginByPwd = (param: any) => request('/user/pwdlogin/v1.0', param);
export const loginBySmsCode = (param: any) => request('/user/smslogin/v1.0', param);
export const getExamPlan = (param: any) => request('/testapi/plan/getvalidplan/v1.0', param);
export const sendSms = (param: any) => request('/user/sendsms/v1.0', param);
export const register = (param: any) => request('/user/register/v1.0', param);
export const getUserInfo = (param: any) => request('/user/userinfo/v1.0', param);
export const updatePwd = (param: any) => request('/user/findpwd/v1.0', param);
export const updatePhone = (param: any) => request('/user/updatephone/v1.0', param);
export const logout = (param: any) => request('/user/logout/v1.0', param);
export const checkCanOrder = (param: any) => request('/testapi/evaluator/suresignplan/v1.0', param);
export const createOrder = (param: any) => request('/testapi/order/submit/v1.0', param);
export const getOrderStatus = (param: any) => request('/testapi/order/getstatus/v1.0', param);
export const getOrderList = (param: any) => request('/testapi/order/getuserorderlist/v1.0', param);
export const payOrder = (param: any) => request('/testapi/order/orderpay/v1.0', param);
export const cancelOrder = (param: any) => request('/testapi/order/cancelorder/v1.0', param);
export const getUploadInfo = (param: any) => request('/testapi/ali/getsts/v1.0', param);
export const getUploadInfoSH = (param: any) => request('/testapi/ali/getshanghaists/v1.0', param);
export const getEvaluatorList = (param: any) => request('/user/evaluator/list/v1.0', param);
export const getEvaluatorInfo = (param: any) => request('/user/userinfo/evaluatorinfo/v1.0', param);
export const updateEvaluator = (param: any) => request('/testapi/evaluator/update/v1.0', param);
export const createEvaluator = (param: any) => request('/testapi/evaluator/add/v1.0', param);
export const getExamList = (param: any) => request('/testapi/evaluation/getuserevaluationlist/v1.0', param);
export const getExamGuideList = (param: any) => request('/testapi/evaluation/waitevaluation/v1.0', param);
export const getAllSubjects = (param: any) => request('/testapi/subject/list/v1.0', param);
export const getSessionList = (param: any) => request('/testapi/plan/sessionslist/v1.0', param);
export const updateSessionId = (param: any) => request('/testapi/evaluation/updateevaluationtime/v1.0', param);
export const updateAddress = (param: any) => request('/user/updateaddress/v1.0', param);
export const faceCompare = (param: any) => request('/testapi/evaluator/compareface/v1.0', param);
export const examLogin = (param: any) => request('/testapi/evaluation/login/v1.0', param);
export const mockExamList = (param: any) => request('/testapi/evaluationsimulation/list/v1.0', param);
export const getSubjectLevelList = (param: any) => request('/testapi/subject/configlist/v1.0', param);
export const getTodayExam = (param: any) => request('/testapi/evaluation/todayevaluation/v1.0', param);
export const createMock = (param: any) => request('/testapi/evaluationsimulation/create/v1.0', param);
export const getMockAnswerInfo = (param: any) => request('/testapi/evaluationsimulation/answerinfo/v1.0', param);
export const getCitys = () => getStatic('/city.json');
export const submitUserAnswer = (param: any) => request('/testapi/evaluation/submitquestion/v1.0', param);
export const submitUserPaper = (param: any) => request('/testapi/evaluation/submitpaper/v1.0', param);
export const checkImage = (param: any) => request('/testapi/evaluator/checkimage/v1.0', param);
export const getUserSubject = (param: any) => request('/testapi/evaluationsimulation/usersubject/v1.0', param);
export const getCertificateList = (param: any) => request('/testapi/certificate/getcertificatelist/v1.0', param);
export const numbCertificate = (param: any) => request('/testapi/certificate/certificatenum/v1.0', param);
export const userCertificate = (param: any) => request('/testapi/certificate/usercertificate/v1.0', param);
export const getHomeBanner = (param: any) => request('/user/syshome/getcarouselchartlist/v1.0', param);
export const checkWhiteUser = (param: any) => request('/testapi/white/check/v1.0', param);
export const certificateOrder = (param: any) => request('/testapi/certificateorder/submit/v1.0', param);
export const certificateOrderStatus = (param: any) => request('/testapi/certificateorder/getstatus/v1.0', param);
export const certificateOrderList = (param: any) => request('/testapi/certificateorder/getuserorderlist/v1.0', param);
export const certificateOrderCancel = (param: any) => request('/testapi/certificateorder/cancelorder/v1.0', param);
export const certificateOrderPay = (param: any) => request('/testapi/certificateorder/orderpay/v1.0', param);

// 正式测评相关接口
export const getRealAnswerInfo = (param: any) => request('/testapi/evaluation/getanswerinfo/v1.0', param);
export const reportExamError = (param: any) => request('/testapi/evaluation/uploaderrorinfo/v1.0', param);
export const runCode = (param: any) => request('/judge/runcode', param);
export const runMockCode = (param: any) => request('/judge/mock/runcode', param);

const getOSSclient = async (isShangHai: boolean) => {
  try {
    const apiMethod = isShangHai ? getUploadInfoSH : getUploadInfo;
    const result: any = await apiMethod({});
    const { region, accessKeyId, accessKeySecret, securityToken:stsToken, bucket, cdnPath } = result.body;
    const client = new OSS({
      region,
      accessKeyId,
      accessKeySecret,
      stsToken,
      bucket,
    });
    return { client, cdnPath };
  } catch (error) {
    throw error;
  }
};

export const uploadFile = async (
  file: any,
  folder: string = 'user/evaluator/userpic/',
  fName: string = '',
  isShangHai: boolean = false,
) => {
  return new Promise(async (resolve, reject) => {
    let ossClient: any;
    let cdnPathUrl: string;
    try {
      const { client, cdnPath } = await getOSSclient(isShangHai);
      ossClient = client;
      cdnPathUrl = cdnPath;
    } catch (error) {
      console.log('初始化oss失败', error);
      reject({ code: '500', msg: '初始化oss失败'});
      return;
    }
    const extension = file.name.split('.').slice(-1)[0];
    const randName = (new Date()).getTime() + '-' + Math.round(Math.random()*1000000) + '.' + extension;
    const fileName = fName || randName;
    ossClient.put(`${folder}${fileName}`, file).then((result: any) => {
      resolve({ url: cdnPathUrl + '/' + result.name, ossUrl: result.url });
    }).catch((error: any) => {
      console.log(error);
      reject({ code: '500', msg: '上传失败'});
    });
  });
};
