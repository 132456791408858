import { message } from 'antd';
import React, { useState } from 'react';
import { Input } from '../../../../components/input';
import { examLogin } from '../../../../utils/api';
import styles from './index.module.scss';

interface Iprop {
  examInfo: any;
  onClose?: () => void;
  onSuccess?: () => void;
}

export const ExamLogin = (props: Iprop) => {
  const { examInfo, onClose, onSuccess } = props;
  const [formData, setFormData] = useState<any>({
    evaluationNum: { value:'', error: '' },
    password: { value:'', error: '' },
  });
  const [isLoging, setIsLoging] = useState(false);

  const canLogin = () => {
    const valied =
      formData.evaluationNum.value.trim() &&
      formData.password.value.trim()
    return valied;
  };

  const onLoginClick = () => {
    const evaluationNum = formData.evaluationNum.value;
    const pwd = formData.password.value.trim();
    if (pwd.length !== 6) {
      setFormData({ ...formData, password: { value: pwd, error: '密码为身份证/护照等证件号码的后六位' } });
      return;
    }
    const clickEvaluationNum = examInfo.evaluationNum;
    if (clickEvaluationNum !== evaluationNum) {
      // message.error('测评账号或密码输入不正确');
      setFormData({ ...formData, evaluationNum: { value: '', error: '输入的测评证号有误，请前往正式测评列表查看' } });
      return;
    }
    const param = { evaluationNum, pwd };
    setIsLoging(true);
    examLogin(param).then((result: any) => {
      setIsLoging(false);
      message.success('登录成功');
      localStorage.setItem('cpatoken', result.body.cpaTOken);
      onSuccess && onSuccess();
    }).catch((error: any) => {
      setIsLoging(false);
      // message.error(error.msg);
      setFormData({ ...formData, password: { value: pwd, error: '密码为身份证/护照等证件号码的后六位' } });
    })
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.panel}>
        <div className={styles.image} />
        <div className={styles.form_wrapper}>
          <div className={styles.logo} />
          <div style={{width:368}}>
            <h1 className={styles.title}>正式测评登录</h1>
            <Input
              icon="phone"
              type="number"
              placeholder="请输入测评证号"
              maxLength={20}
              value={formData.evaluationNum.value}
              onChange={(value: string) => {
                setFormData({ ...formData, evaluationNum: { value, error: ''} });
              }}
              errorInfo={formData.evaluationNum.error}
            />
            <Input
              icon="pwd"
              placeholder="请输入密码"
              type="password"
              maxLength={6}
              value={formData.password.value}
              onChange={(value: string) => {
                setFormData({ ...formData, password: { value, error: ''} });
              }}
              errorInfo={formData.password.error}
            />
            <div
              className={`${styles.btn_login} ${(canLogin() && !isLoging) ? '' : styles.disable}`}
              onClick={onLoginClick}
            >
              { isLoging ? `登录中...` : `登录` }
            </div>
            <div className={styles.remark}>
              用户名为测评证号，密码为身份证/护照后六位
              <span className={styles.tip}>(身份证号如含字母“X”，请统一使用大写；护照号如含括号请忽略括号)</span>
            </div>
          </div>
        </div>
        <div className={styles.btn_close} onClick={onClose} />
      </div>
    </div>
  );
};
