import React from 'react';
import { subjectCover, SubjectLevelName } from '../../../../utils/constant';
import styles from './index.module.scss';

interface Iprop {
  planInfo: any;
  onSelect?: (index: number) => void;
}

export const SubejctList = (props: Iprop) => {
  const { planInfo, onSelect } = props;

  return (
    <div className={styles.subjects_wrapper}>
      {
        (planInfo.subjects || []).map((subject: any, sidx: number) => (
          <div className={styles.subject_card} key={subject.subjectId}>
            <div className={styles.subject_cover}>
              <img src={subjectCover[sidx]} />
            </div>
            <div className={styles.subject_info}>
              <div className={styles.subject_name}>{subject.subjectName}</div>
              <div className={styles.subject_level}>
                科目等级：
                {
                  (subject.subjectLevels || []).map(
                    (level: any, lidx: number) => 
                      <span key={level.subjectLevelId}>
                        {SubjectLevelName[level.level]}级
                        {lidx===subject.subjectLevels.length-1 ? '' : '、'}
                      </span>
                  )
                }
              </div>
              <div
                className={`${styles.btn_enter} ${planInfo.status===1 ? '' : styles.disable}`}
                onClick={() => onSelect && onSelect(sidx)}
              >
                { planInfo.status===1 ? '报名' : '报名已结束' }
              </div>
            </div>
          </div>
        ))
      }
    </div>
  );
};
