import { message, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getEvaluatorList, getExamPlan } from '../../utils/api';
import { getQuery } from '../../utils/util';
import { OrganizationList } from './components/organizition-list';
import { SubejctList } from './components/subject-list';
import styles from './index.module.scss';
import { PageBar } from '../../components/page-bar';
import { Empty } from '../../components/empty';
      
export const EnterOnline = (props: any) => {
  const oranization = getQuery('oranization') === '1';
  const [organizationId, setOrganizationId] = useState(getQuery('organizationId') || '');
  const [planInfo, setPlanInfo] = useState<any>({});
  const [showOrganization, setShowOrganization] = useState(false);
  const [userList, setUserList] = useState<any[]>([]);
  
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    getExamPlan({}).then((result: any) => {
      setPlanInfo(result.body || {});
    }).catch((error: any) => {
      message.error(error.message || error.msg);
    });
    getEvaluatorData();
  }, []);

  useEffect(() => {
    setShowOrganization(oranization && !getQuery('organizationId'));
  }, [location.search]);

  const getEvaluatorData = () => {
    getEvaluatorList({}).then((result: any) => {
      const list = result.body.list || [];
      setUserList(list);
      if (list.length === 0) {
        Modal.confirm({
          title: '您还未完善个人资料',
          okText: '去填写',
          cancelText: '取消',
          onOk: () => navigate('/evaluator-edit'),
        });
      }
    }).catch((error: any) => console.log('获取测评人失败', error.msg));
  };

  return (
    <div className={styles.wrapper}>
      <PageBar title={planInfo.planName || '暂无报名计划'} />
      {
        planInfo.subjects && planInfo.subjects.length ? (
          <SubejctList
            planInfo={planInfo}
            onSelect={(index: number) => {
              if (userList.length === 0) {
                Modal.confirm({
                  title: '您还未完善个人资料',
                  okText: '去填写',
                  cancelText: '取消',
                  onOk: () => navigate('/evaluator-edit'),
                });
                return;
              }
              let toPath = `/enter-form?subjectindex=${index}`;
              if (oranization) {
                toPath += `&oranization=1`;
              }
              if (organizationId) {
                toPath += `&oranizationId=${organizationId}`;
              }
              navigate(toPath);
            }}
          />
        ) : null
      }
      {
        !planInfo.subjects || planInfo.subjects.length === 0 ? (
          <Empty message="暂无报名计划" />
        ) : null
      }
      {
        showOrganization ? (
          <OrganizationList
            onChange={(value: any) => {
              setOrganizationId(value.id);
              setShowOrganization(false);
            }}
            onClose={() => setShowOrganization(false)}
          />
        ) : null
      }
    </div>
  );
};
