import React, { useEffect } from 'react';
import styles from './index.module.scss';

interface Iprop {
  appkey: string;
  scene: string;
  id?: string;
  success?: (
    data: {
      sessionId: string;
      sig: string;
      token: string;
    }
  ) => void;
}

export const AWSCslide = (prop: Iprop) => {
  const {
    appkey,
    scene,
    id = 'AWSC_nc_dom',
    success,
  } = prop;

  useEffect(() => {
    if (appkey && scene) {
      initAWSC();
    }
  }, []);

  const initAWSC = () => {
    // 实例化nc
    window.AWSC.use("nc", function (state: any, module: any) {
      // 初始化
      window.nc = module.init({
          appkey,
          scene,
          renderTo: id,
          width: 368,
          success: function (data: any) {
            success && success(data);
            // window.nc.reset();
          },
          fail: function (failCode: any) {
            console.log(failCode)
          },
          error: function (errorCode: any) {
            console.log(errorCode)
          },
      });
    })
  };

  return (
    <div className={styles.wrapper}>
      <div id={id}></div>
    </div>
  )
}
