import html2canvas from 'html2canvas';
import React, { useEffect } from 'react';
import { Button } from '../../../../components/button';
import { SubjectLevelName } from '../../../../utils/constant';
import styles from './index.module.scss';

interface Iprop {
  userInfo: any;
  examInfo: any;
}

export const EnterCard = (props: Iprop) => {
  const { userInfo, examInfo } = props;
  const noticeContent = [
    '本次测评为在线测评，请务必先进行模拟练习，通过设备检测，提前熟悉环境。',
    '测评过程中如发生不能获取摄像头画面、不能共享屏幕等问题，请关闭所有杀毒软件，如金山毒霸、腾讯管家等。',
    '正式测评时，访问官网www.cpa-test.cn，点击“正式测评登录”，使用测评证号作为用户名，身份证号后六位作为密码登录系统。',
    '如您使用的证件是护照等，则默认密码为不包含括号的后六位字符。',
    '请提前10分钟登录系统，等待测评倒计时，避免迟到对孩子产生心理影响。',
    '测评开始30分钟后，才可以交卷，30分钟内不会显示“交卷”按钮。',
    '测评过程中出现任何异常状态提醒属于正常的监考环节，各参评人员不必紧张，继续答题即可，测评结束后由人工审核是否属于作弊。',
  ];

  useEffect(() => {
    const printdiv = document.getElementById('printdiv');
    if (printdiv) {
      html2canvas(printdiv, { useCORS: true }).then(canvas => {
        canvas.style.backgroundColor = '#ffffff';
        canvas.id = 'printcanvas';
        printdiv.appendChild(canvas);
      })
    }
  }, []);

  const onPrintClick = () => {
    let oWin: any = window.open('', 'pringwindow', 'menubar=no,location=no,resizable=yes,scrollbars=no,status=no,width=1000,height=660')
    oWin.document.fn = function() {
        if (oWin) {
            oWin.print()
            oWin.close()
        }
    }
    const canvas: any = document.getElementById('printcanvas');
    let imgSrc = canvas.toDataURL();
    let html = '<div style="height: 100%;width: 100%;">' + `<img src="${imgSrc}" onload="fn()" style="max-height:100%;max-width: 100%;" />` + '</div>'
    oWin.document.open()
    oWin.document.write(html)
    oWin.document.close()
  }

  return (
    <>
      <div className={styles.wrapper} id="printdiv">
        <h1>CPA青少年编程能力等级测评</h1>
        <h2>测评证</h2>
        <div className={styles.userinfo}>
          <div className={styles.photo}>
            <img src={userInfo.avatar} />
          </div>
          <div className={styles.otherinfo}>
            <div className={styles.row}>
              <label>测评证号</label>
              <span>{examInfo.evaluationNum}</span>
            </div>
            <div className={styles.row}>
              <label>姓名</label>
              <span>{userInfo.evaluatorName}</span>
            </div>
            {/* <div className={styles.row}>
              <label>性别</label>
              <span>{['未知', '男', '女'][parseInt(userInfo.sex)]}</span>
            </div> */}
            <div className={styles.row}>
              <label>证件号码</label>
              <span>{userInfo.cardNumber}</span>
            </div>
            <div className={styles.row}>
              <label>测评科目</label>
              <span>{examInfo.subjectName}{SubjectLevelName[examInfo.level]}级</span>
            </div>
            <div className={styles.row}>
              <label>测评时间</label>
              <span>{examInfo.sessionsTime}</span>
            </div>
          </div>
        </div>
        <div className={styles.notice_title}>
          <span className={styles.line} />
          <span className={styles.squre} />
          <span className={styles.title}>测评须知</span>
          <span className={styles.squre} />
          <span className={styles.line} />
        </div>
        <div className={styles.notice_content}>
          {
            noticeContent.map((item:any, index:number) => (
              <div className={styles.item} key={index}>
                <span className={styles.num}>{index+1}.</span>
                <span>{item}</span>
              </div>
            ))
          }
        </div>
      </div>
      <div className={styles.btn_wraper}>
        <Button width={320} text="打印测评证" size="big" onClick={onPrintClick} />
        <div className={styles.tip}>点击鼠标右键图片另存为，可保存到电脑</div>
      </div>
    </>
  );
};
