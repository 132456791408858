export const menus: any[] = [
  {
    key: 'guide',
    name: '考前引导',
    route: '/exam-guide'
  },
  {
    key: 'signin',
    name: '在线报名',
    route: '/enter-online',
    children: [
      {
        key: 'signform',
        name: '报名表单',
        route: '/enter-form',
      }
    ],
  },
  {
    key: 'order',
    name: '我的订单',
    route: '/orderlist'
  },
  {
    key: 'exam',
    name: '正式测评',
    route: '/examination'
  },
  {
    key: 'mock',
    name: '模拟练习',
    route: '/exam-mock'
  },
  {
    key: 'certifi',
    name: '测评证书',
    route: '/certificate',
    children: [
      {
        key: 'usersedit',
        name: '证书查询',
        route: '/certificate-query',
      }
    ],
  },
  {
    key: 'users',
    name: '测评人信息',
    route: '/evaluator',
    children: [
      {
        key: 'usersedit',
        name: '测评人信息编辑',
        route: '/evaluator-edit',
      }
    ],
  },
  {
    key: 'mycenter',
    name: '账号中心',
    route: '/my-center'
  },
];
