import { message, Modal } from "antd";
import React, { useEffect, useRef } from "react";
import { getUserMediaVideo } from "../../../../utils/util";
import styles from './index.module.scss';

interface Iprop {
  
}

let videoTrack: any = null;

export const VideoCamera = (props: Iprop) => {
  const videoCamera: any = useRef(null);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      startCameraMicrophone();
    }
    return () => {
      clearTracks();
    }
  }, []);

  const clearTracks = () => {
    if (videoTrack) {
      videoTrack.stop();
      videoTrack = null;
    }
  };

  const startCameraMicrophone = () => {
    if (videoTrack) {
      return;
    }
    // 开启摄像头和麦克风
    getUserMediaVideo(false).then((stream: any) => {
      videoCamera.current.srcObject = stream;
      videoCamera.current.play();
      const tracks =  stream.getTracks();
      videoTrack = tracks[0];
    }).catch(error => message.error(error));
  };

  return (
    <div className={styles.wrapper}>
      <video ref={videoCamera} className={styles.camera_video} />
      <div className={styles.video_tip}>
        如果看不到视频影像，请<a onClick={() => startCameraMicrophone()}>重新获取视频影像</a>
      </div>
    </div>
  );
};
