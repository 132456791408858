import { Checkbox, message } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '../../components/button';
import { checkWhiteUser, faceCompare, getEvaluatorInfo, uploadFile } from '../../utils/api';
import { getStatic } from '../../utils/request';
import { getUserMediaVideo } from '../../utils/util';
import styles from './index.module.scss';

interface Iprop {
  evaluation: any;
  onComplete?: () => void;
}

let videoTrack: any = null;

export const FaceCompare = (props: Iprop) => {
  const { evaluation, onComplete } = props;
  const [evaUserInfo, setEvaUserInfo] = useState(evaluation);
  const [agreeVideo, setAgreeVideo] = useState(false);
  const videoCamera = useRef<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const userInfo = useSelector((state: any) => state.userInfo);
  const [doNotCamera, setDoNotCamera] = useState(false);

  useEffect(() => {
    if (!evaUserInfo.avatar) {
      getEvaluatorInfo({ evaluatorId: evaluation.evaluatorId }).then((result: any) => {
        setEvaUserInfo(result.body || {});
      }).catch((error: any) => message.error(error.msg));
    }
    checkWhiteUser({ pType:1, phone:userInfo.telphone }).then((result:any) => {
      if (result.code === 200 && result.body.result) {
        setDoNotCamera(true);
      } else {
        startCamera();
      }
    }).catch(() => {
      startCamera();
    });
    return () => {
      if (videoTrack) {
        videoTrack.stop();
        videoTrack = null;
      }
    }
  }, []);

  const startCamera = () => {
    getUserMediaVideo(false).then((stream: any) => {
      videoCamera.current.srcObject = stream;
      videoCamera.current.play();
      const tracks =  stream.getTracks();
      videoTrack = tracks[0];
    }).catch(error => message.error(error));
  };

  const onCheckClick = () => {
    if (doNotCamera) { // 如果不检测摄像头白名单命中，直接完成人脸比对
      onComplete && onComplete();
      return;
    }
    checkWhiteUser({ pType:2, phone:userInfo.telphone }).then((result:any) => {
      if (result.code === 200 && result.body.result) {
        onComplete && onComplete();
      } else {
        doCheckFace();
      }
    }).catch(() => {
      doCheckFace();
    });
  };

  const doCheckFace = () => {
    const video = videoCamera.current;
    if (video) {
      const canvas = document.createElement('canvas');
      canvas.width = video.clientWidth;
      canvas.height = video.clientHeight;
      const ctx: any = canvas.getContext('2d');
      ctx.drawImage(video, 0, 0, video.clientWidth, video.clientHeight);
      setIsLoading(true);
      canvas.toBlob((data: any) => {
        const file = new File([data], 'cameraCapture.png');
        uploadFile(
          file,
          'user/evaluator/facecheck/',
          evaluation.evaluatorId+'.png',
          true
        ).then((result: any) => {
          const param = {
            evaluatorId: evaluation.evaluatorId,
            imageUrl: result.url,
          }
          faceCompare(param).then((result: any) => {
            setIsLoading(false);
            if (result.body.result === 1) {
              onComplete && onComplete();
            } else {
              message.error('人脸对比未通过审核');
            }
          }).catch((error: any) => {
            setIsLoading(false);
            message.error(error.msg);
          });
        }).catch((error: any) => {
          setIsLoading(false);
          message.error(error.msg);
        });
      });
    }
  }

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.main_title}>人脸比对</h1>
      <div className={styles.photo_video}>
        <div className={styles.sidearea}>
          <div className={styles.small_title}>当前证件照</div>
          <div className={styles.video_area}>
            <img src={evaUserInfo.avatar} />
          </div>
        </div>
        <div className={styles.sidearea}>
        <div className={styles.small_title}>当前视频影像</div>
          <div className={styles.video_area} style={{width:'100%'}}>
            <video ref={videoCamera} />
          </div>
        </div>
      </div>
      <div className={styles.btn_area}>
        <div className={styles.profile}>
          <Checkbox
            style={{marginRight:3}}
            checked={agreeVideo}
            onChange={(evt: any) => setAgreeVideo(evt.target.checked)}
          />
          <span>我已同意视频图像实时采集</span>
        </div>
        <Button
          text={isLoading ? '检测中...' : '立即检测'}
          width={320}
          size="big"
          disable={!agreeVideo || isLoading}
          onClick={onCheckClick}
        />
        <div className={styles.tip}>
          如果看不到视频影像，请<a>重新获取视频影像</a>
        </div>
      </div>
      {/* 须知内容 */}
      <div className={styles.notice_area}>
        <p><strong>认证须知</strong></p>
        <p style={{marginBottom:16}}>
          1. 保持肩部以上在视频可视范围内，露出完整五官（眼睛、鼻子、嘴）<br />
          2. 环境光线充足<br />
          3. 面部无遮挡
        </p>
        <p><strong>注意事项</strong></p>
        <p>
          1. 阅读隐私政策，确认无误且同意后勾选我已阅读并同意<br />
          2. 点击立即检测进行真人与证件照对比<br />
          3. 匹配失败后，调整人物在影像中的位置重新匹配<br />
          4. 多次匹配失败后，可能是上传的证件照与真人实时影像差距较大，将证件照片调整为近期照片，重新检测
        </p>
      </div>
    </div>
  );
};
