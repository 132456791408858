import { Cascader, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '../../components/button';
import { Input } from '../../components/input';
import { PageBar } from '../../components/page-bar';
import { EditAddress } from './components/edit-address';
import { EditPhone } from './components/edit-phone';
import { EditPwd } from './components/edit-pwd';
import styles from './index.module.scss';
      
export const MyCenter = (props: any) => {
  const userInfo = useSelector((state: any) => state.userInfo);
  const [showEditPwd, setShowEditPwd] = useState(false);
  const [showEditPhone, setShowEditPhone] = useState(false);

  return (
    <div className={styles.wrapper_group}>
      <div className={styles.wrapper}>
        <PageBar title="登录信息" />
        <div className={styles.content_area}>
          {/* <div className={styles.logo} /> */}
          <div className={styles.form_item}>
            <Input
              label="登录手机号"
              mini
              maxLength={50}
              labelWidth={100}
              disable={true}
              value={userInfo.telphone}
            />
          </div>
          <div className={styles.form_item}>
            <Input
              label="登录密码"
              mini
              maxLength={50}
              labelWidth={100}
              disable={true}
              value="**********"
            />
          </div>
          <div className={styles.btn_group}>
            <Button text="修改手机号" size="big" width={160} onClick={() => setShowEditPhone(true)} />
            <Button text="修改密码" size="big" type="outline" width={160} style={{marginLeft:8}} onClick={() => setShowEditPwd(true)} />
          </div>
        </div>
      </div>
      {
        showEditPwd ? (
          <EditPwd onClose={() => setShowEditPwd(false)} />
        ) : null
      }
      {
        showEditPhone ? (
          <EditPhone onClose={() => setShowEditPhone(false)} />
        ) : null
      }
      <div className={styles.wrapper}>
        <PageBar title="联系地址" />
        <div className={styles.content_area}>
          <EditAddress />
        </div>
      </div>
    </div>
  );
};
