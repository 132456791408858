import { Button, message, Modal, Select, Table } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import styles from './index.module.scss';
import { getAllSubjects, getEvaluatorList, getExamPlan, mockExamList } from '../../utils/api';
import { useSelector } from 'react-redux';
import { Empty } from '../../components/empty';
import { useNavigate } from 'react-router-dom';
import { PageBar } from '../../components/page-bar';
import { Button as CButton } from '../../components/button';
import { Panel } from '../../components/panel';
import { SubjectLevelName } from '../../utils/constant';
      
export const ExamMock = (props: any) => {
  const navigate = useNavigate();

  const userInfo = useSelector((state: any) => state.userInfo);
  const [examData, seExamData] = useState<any[]>([]);
  const [evaluatorList, setEvaluatorList] = useState<any[]>([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
    showTotal: (total: any) => `共 ${total} 条`,
  });
  const [evaluatorIndex, setEvaluatorIndex] = useState(0);
  const [showChooseUser, setShowChooseUser] = useState(false);

  useEffect(() => {
    getExamData();
    getEvaluatorData();
  }, []);

  const getExamData = (pageNum: number = 1) => {
    const param = {
      userId: userInfo.id,
      pageNum,
      pageSize: pagination.pageSize,
    };
    mockExamList(param).then((result: any) => {
      seExamData(result.body.rows);
      setPagination({ ...pagination, current: pageNum, total: result.body.total });
    }).catch((error: any) => {
      message.error(error.msg);
    });
  };

  const getEvaluatorData = () => {
    getEvaluatorList({}).then((result: any) => {
      const list = result.body.list || [];
      setEvaluatorList(list);
    }).catch((error: any) => console.log('获取测评人数据失败', error.msg));
  };

  const onStartMock = () => {
    checkHasExamPlan(() => {
      if (evaluatorList.length === 0) {
        Modal.confirm({
          title: '您还未完善个人资料',
          okText: '去填写',
          cancelText: '取消',
          onOk: () => navigate('/evaluator-edit'),
        });
        return;
      }
      Modal.info({
        title: '模拟练习提示',
        content: <span style={{color:'red', fontWeight: 'bolder'}}>您当前即将开始模拟练习，非正式测评。</span>,
        okText: '我已知晓',
        onOk: () => setShowChooseUser(true),
      });
    })
  };

  const checkHasExamPlan = (callback: ()=>void) => {
    getExamPlan({}).then((result: any) => {
      const hasExamPlan = result.body && result.body.subjects && result.body.subjects.length;
      if (hasExamPlan) {
        callback && callback();
      } else {
        message.info('暂无模拟练习');
      }
    });
  };

  const onContinuePaper = (data: any) => {
    checkHasExamPlan(() => {
      Modal.info({
        title: '模拟练习提示',
        content: <span style={{color:'red', fontWeight: 'bolder'}}>您当前即将开始模拟练习，非正式测评。</span>,
        okText: '我已知晓',
        onOk: () => {
          const { evaluationId, paperId, evaluatorId, evaluatorName } = data;
          const evaluation = { evaluatorId, evaluatorName };
          navigate(
            '/env-check?type=mock2', 
            {
              state: {
                user: evaluation,
                exam: { evaluationId, paperId },
              }
            }
          );
        },
      });
    });
  };

  const onViewPaper = (data: any) => {
    const { evaluationId, paperId, evaluatorId, evaluatorName, totalScore, subjectName, level } = data;
    const evaluation = { evaluatorId, evaluatorName, totalScore, subjectName, level };
    navigate(
      `/exam-formal?evaluationId=${evaluationId}&paperId=${paperId}&mock=1&review=1`,
      { state: { evaluation } }
    );
  };

  return (
    <div className={styles.wrapper}>
      <PageBar title="模拟练习">
      </PageBar>
      <div className={`${styles.table_wrapper} ${examData.length===0 ? styles.empty : ''}`}>
        {
          examData.length > 0 ? (
            <>
              <div className={styles.btn_group}>
                <CButton text="开始模拟练习" size="big" width={160} onClick={onStartMock} />
              </div>
              <Table
                bordered
                dataSource={examData}
                columns={[
                  {
                    title: '测评名称',
                    // width: 80,
                    render: () => '青少年编程能力模拟练习',
                  },
                  {
                    title: '测评科目',
                    dataIndex: 'subjectName',
                    key: 'subjectName',
                    // width: 80,
                    render: (text: any, record: any) => `${text}（${SubjectLevelName[record.level]}级）`
                  },
                  {
                    title: '测评时间',
                    dataIndex: 'createTime',
                    key: 'createTime',
                    // width: 180,
                  },
                  {
                    title: '姓名',
                    // width: 180,
                    dataIndex: 'evaluatorName',
                    key: 'evaluatorName',
                  },
                  {
                    title: '得分',
                    dataIndex: 'totalScore',
                    key: 'totalScore',
                    // width: 180,
                  },
                  {
                    title: '操作',
                    align: 'center',
                    // width: 180,
                    render: (text: any, record: any) => (
                      <div className={styles.operator}>
                        {
                          record.answerStatus===2 ? (
                            <Button
                              type="link"
                              style={{color:'#52C41A'}}
                              onClick={() => onContinuePaper(record)}
                            >
                              继续练习
                            </Button>
                          ) : null
                        }
                        {
                          record.answerStatus===3 ? (
                            <Button
                              type="link"
                              onClick={() => onViewPaper(record)}
                            >
                              查看试卷
                            </Button>
                          ) : null
                        }
                      </div>
                    )
                  },
                ]}
                rowKey="evaluationId"
                pagination={pagination}
                onChange={(evt: any) => {
                  getExamData(evt.current);
                }}
              />
            </>
          ) : (
            <Empty
              message="没有模拟练习记录"
              btnText="开始模拟练习"
              onBtnClick={onStartMock}
            />
          )
        }
      </div>
      {/* 选择测评人 */}
      {
        showChooseUser ? (
          <Panel title="选择练习人" width={466} onClose={() => setShowChooseUser(false)}>
            <div>
              <Select
                style={{width:'100%', marginBottom:24}}
                value={evaluatorIndex}
                onChange={(value: any) => setEvaluatorIndex(value)}
              >
                {
                  evaluatorList.map((user: any, index: number) => (
                    <Select.Option key={user.evaluatorId} value={index}>{user.evaluatorName}</Select.Option>
                  ))
                }
              </Select>
              <CButton
                text="确定"
                onClick={() => navigate('/env-check?type=mock', { state: { user: evaluatorList[evaluatorIndex] } })}
              />
            </div>
          </Panel>
        ) : null
      }
    </div>
  );
};
