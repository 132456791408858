import { Button, Input, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { ESubjectType } from '../..';
import { runCode, runMockCode } from '../../../../utils/api';
import styles from './index.module.scss';

interface Iprop {
    isMock: boolean;
    evaluation: any;
    data: any;
}

export const RunCode = (props: Iprop) => {
    const { isMock, evaluation, data } = props;
    const [paramStr, setParamStr] = useState('');
    const [outputs, setOutputs] = useState('');
    const [errors, setErrors] = useState('');

    useEffect(() => {
        setParamStr('');
        setOutputs('');
        setErrors('');
    }, [data]);

    const onRunClick = () => {
        if (!data.userAnswer) {
            message.error('请输入代码再运行');
            return;
        }
        setOutputs('');
        setErrors('');
        const dataInput = paramStr ? paramStr.split('\n') : [];
        if (data.type === ESubjectType.Cpp) {
            runCppCode('c++', dataInput);
        } else if (data.type === ESubjectType.Python) {
            runCppCode('python3', dataInput);
        }
    };

    const runCppCode = (lang: string, dataInput: string[]) => {
        const apiMethod = isMock ? runMockCode : runCode;
        apiMethod({
            lang,
            evaluatorId: evaluation.evaluatorId,
            typedCode: (lang==='python3' ? '' : '') + data.userAnswer,
            dataInput,
            questionId: data.questionId,
        }).then((result: any) => {
            const { stdOutputList, errors } = result.body;
            setOutputs((stdOutputList || []).join(''));
            setErrors(errors);
        }).catch((error: any) => {
            message.error(error.msg);
        });
    };

    return (
        <div className={styles.run_code_wrapper}>
            <div className={styles.btn_area}>
                {
                    data.exampleList && data.exampleList.length ? (
                        <Input.TextArea
                            rows={3}
                            // style={{resize:'none'}}
                            maxLength={1000}
                            placeholder="多个参数请用回车换行"
                            value={paramStr}
                            onChange={(evt: any) => setParamStr(evt.target.value)}
                        />
                    ) : null
                }
                <Button type="primary" onClick={onRunClick}>运行</Button>
            </div>
            <div className={styles.run_result}>
                <div className={styles.success}>{outputs}</div>
                <div className={styles.error}>{errors}</div>
            </div>
        </div>
    );
}
