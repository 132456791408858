import { UploadOutlined } from '@ant-design/icons';
import { Button, Input, message, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import { getStatic } from '../../utils/request';
import { analyzeSb3 } from '../../utils/util';

export default function Test (props: any) {
  const [sb3Url, setSb3Url] = useState('http://cpa-alpha.oss-cn-beijing.aliyuncs.com/cpatest/exam/question/scratch/useranswer/1600114920256262144-1599226187944411137.sb3');
  const [sb3Data, setSb3Data] = useState('');

  useEffect(() => {
    
  }, []);

  const onLoadRemoteSb3 = () => {
    getStatic(sb3Url, { responseType: 'blob' }).then((data: any) => {
      analyzeSb3(data).then((projectData:any) => {
        onSb3Data(projectData);
      });
    }).catch(() => message.error('加载失败'));
  };

  const onSb3Data = (projectData:any) => {
    const roles = projectData.targets.filter((item: any) => !item.isStage);
    for (let key in roles[0].blocks) {
      const block = roles[0].blocks[key];
      const { fields, inputs } = block;
      // console.log(fields, inputs);
      console.log('----- inputs -----');
      for (let key2 in inputs) {
        console.log(key2, inputs[key2]);
      }
    }
  };

  const sortBlocks = (_sb3Data:string) => {
    const blocks = JSON.parse(_sb3Data);
    const blockList = [];
    for (let key in blocks) {
      const block = blocks[key];
      const { opcode, topLevel, parent, next, shadow, fields, inputs } = block;
      blockList.push({key, opcode, topLevel, parent, next, shadow, fields, inputs});
    }
    console.log(blockList);
    const sortBlocks = [];
    let currentBlock:any = blockList.find((blk: any) => blk.topLevel);
    sortBlocks.push(currentBlock);
    while (currentBlock.next) {
      currentBlock = blockList.find((blk: any) => blk.key === currentBlock.next);
      sortBlocks.push(currentBlock);
    }
    console.log(sortBlocks);
  };

  return (
    <div style={{ height:'80vh', padding:20, backgroundColor:'#f1f1f1' }}>
      <h1>测试页面</h1>
      <div style={{ display:'flex', marginBottom:10 }}>
        <div style={{ display:'flex', flex:1, marginRight:20 }}>
          <Input
            placeholder="请输入sb3链接"
            maxLength={1000}
            value={sb3Url}
            onChange={(evt:any) => setSb3Url(evt.target.value)}
          />
          <Button type="primary" onClick={onLoadRemoteSb3}>加载并解析</Button>
        </div>
        <div style={{ display:'flex' }}>
          <Upload
            accept=".sb3"
            fileList={[]}
            beforeUpload={(file:any) => {
              analyzeSb3(file).then((projectData:any) => onSb3Data(projectData));
              return false;
            }}
          >
            <Button type="primary" icon={<UploadOutlined />}>选择本地sb3文件</Button>
          </Upload>
        </div>
      </div>
      <Input.TextArea
        rows={10}
        readOnly
        placeholder="解析结果数据"
        value={sb3Data}
        style={{ resize:'none' }}
      />
    </div>
  );
}
