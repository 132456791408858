import React, { useState } from 'react';
import styles from './index.module.scss';
      
interface InputProps {
  value?: string;
  icon?: 'phone' | 'pwd' | 'smscode';
  type?: 'text' | 'password' | 'number';
  placeholder?: string;
  maxLength?: number;
  onChange?: (value: string) => void;
  errorInfo?: string;
  tipInfo?: string;
  noMargin?: boolean;
  children?: any;
  label?: string;
  labelWidth?: number;
  required?: boolean;
  disable?: boolean;
  mini?: boolean;
}

export const Input = (props: InputProps) => {
  const {
    value = '',
    icon,
    type = 'text',
    placeholder = '请输入',
    maxLength = 100,
    onChange,
    errorInfo,
    tipInfo,
    noMargin = false,
    children,
    label,
    labelWidth = 80,
    required = true,
    disable = false,
    mini = false,
  } = props;
  // 密码类型时，是否展示掩码
  const [pwdIsHide, setPwdIsHide] = useState(true);
  const [isFocus, setIsFocus] = useState(false);
  
  return (
    <div className={`${styles.input_row} ${mini ? styles.mini : ''} ${disable ? styles.disable : ''} ${noMargin ? styles.no_margin : ''}`}>
      {
        label ? (
          <span
            className={`${styles.label} ${required ? '' : styles.unrequired}`}
            style={{ width: labelWidth }}
          >
            {label}
          </span>
        ) : null
      }
      <div
        className={`${styles.input_border} ${isFocus ? styles.focus : ''} ${errorInfo ? styles.error : ''}`}
      >
        {
          icon ? (
            <div className={`${styles.icon} ${styles[icon]}`} />
          ) : null
        }
        <input
          value={value}
          readOnly={disable}
          className={icon ? styles.hasicon : ''}
          type={type==='password' ? (pwdIsHide ? 'password' : 'text') : 'text'}
          placeholder={placeholder}
          maxLength={maxLength}
          onFocus={() => setIsFocus(true)}
          onBlur={() => setIsFocus(false)}
          onChange={(e: any) => {
            let value = e.target.value;
            if (type === 'number') {
              value = value.replace(/[^\d]/g, '');
            }
            onChange && onChange(value);
          }}
        />
        {
          type === 'password' ? (
            <div
              className={`${styles.icon} ${pwdIsHide ? styles.seepwd : styles.hidepwd}`}
              onClick={() => setPwdIsHide(!pwdIsHide)}
            />
          ) : null
        }
        {
          errorInfo ? <div className={styles.error}>{errorInfo}</div> : null
        }
        {
          tipInfo && !errorInfo ? <div className={styles.tip}>{tipInfo}</div> : null
        }
      </div>
      { children }
    </div>
  );
};
