import React, { useRef, useState } from 'react';
import styles from './index.module.scss';
import { QRCodeCanvas } from 'qrcode.react';
import { Button } from '../../../../components/button';
import { message } from 'antd';
import { certificateOrder, certificateOrderStatus } from '../../../../utils/api';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

interface Iprop {
  addressInfo: any;
  certificate:any;
}

export const PayInfo = (props:Iprop) => {
  const { addressInfo, certificate } = props;
  const userInfo = useSelector((state: any) => state.userInfo);
  const [payType, setPayType] = useState(2); // 1-微信支付 2-支付宝支付
  const [orderInfo, setOrderInfo] = useState<any>(null);
  const iframe = useRef<any>(null);
  const [wxQRCodeValue, setWxQRCodeValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [paySuccess, setPaySuccess] = useState(false);
  const navigate = useNavigate();

  const submitOrder = () => {
    let param = {
      ...addressInfo,
      certificateId: certificate.certificateId,
      userId: userInfo.id,
      payType,
    }
    setIsLoading(true);
    certificateOrder(param).then((result: any) => {
      setIsLoading(false);
      setOrderInfo(result.body);
      if (payType === 1) {
        setWxQRCodeValue(result.body.payInfo);
      }
      if (payType === 2) {
        const doc = iframe.current.contentWindow.document;
        doc.open();
        doc.write(result.body.payInfo);
        doc.close();
      }
    }).catch((error: any) => {
      setIsLoading(false);
      message.error(error.msg);
    });
  };

  const checkOrderStatus = () => {
    const param = {
      orderId: orderInfo.orderId,
    };
    certificateOrderStatus(param).then((result: any) => {
      // result.body.status 订单状态（0 待支付 1 已支付 2退款中 3已退款 4已取消）
      if (result.body.status === 1) {
        setPaySuccess(true);
      } else {
        message.error([
          '订单还未支付，请扫码支付',
          '订单已支付',
          '订单退款中，请联系客服',
          '订单已退款，请重新下单',
          '订单已取消，请重新下单',
        ][result.body.status]);
      }
    }).catch((error: any) => {
      message.error(error.msg);
    });
  };

  return (
    <>
      <div className={styles.success_bar}>
        {paySuccess ? '支付成功' : '订单提交成功，请尽快付款'}
      </div>
      {
        !paySuccess ? (
          <>
            <div className={styles.payinfo}>
            <div className={styles.left_side} style={{ flex: orderInfo ? 1 : 0 }}>
              <div className={styles.row}>
                <span className={styles.label}>应付金额：</span>
                <span className={styles.price}>¥48</span>
              </div>
              {
                orderInfo ? (
                  <div className={styles.row}>
                    <span className={styles.label}>订单编号：</span>
                    <span className={styles.value}>{orderInfo.orderId}</span>
                  </div>
                ) : null
              }
              <div className={styles.row}>
                <span className={styles.label}>{orderInfo ? '' : '请选择'}支付方式：</span>
              </div>
              <div className={orderInfo ? styles.disable : ''}>
                <div
                  className={`${styles.pay_type} ${styles.wechat} ${payType===1 ? styles.active : ''}`}
                  onClick={() => setPayType(1)}
                >
                  微信支付
                </div>
                <div
                  className={`${styles.pay_type} ${styles.zfb} ${payType===2 ? styles.active : ''}`}
                  onClick={() => setPayType(2)}
                >
                  支付宝支付
                </div>
              </div>
            </div>
            <div className={styles.right_side} style={{display:orderInfo ? 'flex' : 'none'}}>
              {
                payType === 1 ? (
                  <div className={styles.qrcode}>
                    <QRCodeCanvas value={wxQRCodeValue} size={184} />
                  </div>
                ) : null
              }
              {
                payType === 2 ? (
                  <div className={styles.frame_border}>
                    <iframe frameBorder={0} ref={iframe} />
                  </div>
                ) : null
              }
            </div>
          </div>
          <div className={styles.btnbar}>
            <Button
              size="big"
              text={ orderInfo ? '确认已支付' : (isLoading ? '处理中...' : '支付') }
              disable={isLoading}
              width={240}
              onClick={() => orderInfo ? checkOrderStatus() : submitOrder()}
            />
          </div>
          </>
        ) : (
          <div className={styles.paysuccess}>
            <div className={styles.summary}>证书补发预计在30天内完成，请耐心等待</div>
            <Button
              size="big"
              text="确认"
              width={240}
              onClick={() => navigate('/orderlist')}
            />
          </div>
        )
      }
    </>
  );
};
