import { Button, ConfigProvider, Menu, message, Modal, Select, Table } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import styles from './index.module.scss';
import { cancelOrder, getEvaluatorList, getAllSubjects, getOrderList, getOrderStatus, payOrder, certificateOrderList, certificateOrderCancel, certificateOrderPay, certificateOrderStatus } from '../../utils/api';
import { useSelector } from 'react-redux';
import { QRCodeCanvas } from 'qrcode.react';
import { Empty } from '../../components/empty';
import { useNavigate } from 'react-router-dom';
import { PageBar } from '../../components/page-bar';
import { SubjectLevelName } from '../../utils/constant';
import { Reason } from '../../components/Reason';
// import { AppstoreOutlined, MailOutlined } from '@ant-design/icons';
      
export const OrderList = (props: any) => {
  const navigate = useNavigate();
  const menuItems = [
    {
      label: '报名订单',
      key: 'baoming',
      // icon: <MailOutlined />,
    },
    {
      label: '证书补办订单',
      key: 'bufa',
      // icon: <AppstoreOutlined />,
    },
  ];
  const [currentMenu, setCurrentMenu] = useState<'baoming' | 'bukao'>('baoming');
  const userInfo = useSelector((state: any) => state.userInfo);
  const statusName = ['未支付', '待审核', '审核失败', '已支付', '支付已取消', '已退款', '退款中'];
  const [orderData, setOrderData] = useState<any[]>([]);
  const [bukaoData, setBukaoData] = useState<any[]>([]);
  const [subjects, setSubjects] = useState<any[]>([]);
  const [evaluatorList, setEvaluatorList] = useState<any[]>([]);
  const [query, setQuery] = useState<any>({ subjectId: '', evaluatorId: '' });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
    showTotal: (total: any) => `共 ${total} 条`,
  });
  const [showPayModal, setShowPayModal] = useState(false);
  const [orderInfo, setOrderInfo] = useState<any>({});
  const iframe = useRef<any>(null);
  const [wxQRCodeValue, setWxQRCodeValue] = useState('');
  const [nowPayType, setNowPayType] = useState(1);

  const orderColumns:any[] = [
    {
      title: '测评科目',
      dataIndex: 'subjectName',
      key: 'subjectName',
      ellipsis: true,
      render: (text: any, record: any) => `${text}（${SubjectLevelName[record.level]}级）`
    },
    {
      title: '订单编号',
      dataIndex: 'orderId',
      key: 'orderId',
      ellipsis: true,
    },
    {
      title: '报名价格',
      dataIndex: 'payAmount',
      key: 'payAmount',
      ellipsis: true,
      render: (text: any) => (text/100).toFixed(2)+'元',
    },
    {
      title: '支付日期',
      ellipsis: true,
      render: (text: any, record: any) => record.status === 3 ? record.payTime : '/'
    },
    {
      title: '测评人姓名',
      dataIndex: 'evaluatorName',
      key: 'evaluatorName',
      ellipsis: true,
    },
    {
      title: '考试时间',
      width: 200,
      render: (text: any, record: any) => (
        <>
          <span>{record.sessionsTime}</span>
        </>
      )
    },
    {
      title: '订单状态',
      dataIndex: 'status',
      key: 'status',
      render: (text: any, record: any) => (
        <div style={{display:'flex', alignItems:'center'}}>
          <span>{statusName[text]}</span>
          {
            text === 2 ? (
              <Reason text={record.examineReason} />
            ) : null
          }
        </div>
      ),
    },
    {
      title: '操作',
      align: 'center',
      render: (text: any, record: any) => (
        <div className={styles.operator}>
          {
            record.status === 0 ? (
              <Button
                type="link"
                style={{color:'#FAAD14'}}
                onClick={() => onPayNow(record)}
              >
                立即支付
              </Button>
            ) : null
          }
          {
            record.status === 3 ? (
              <Button
                type="link"
                onClick={() => navigate('/examination')}
              >
                查看测评信息
              </Button>
            ) : null
          }
          {
            ![3,4,5,6].includes(record.status) ? (
              <Button
                type="link"
                style={{color:'red'}}
                onClick={() => onCancelOrder(record)}
              >
                取消报名
              </Button>
            ) : null
          }
        </div>
      )
    },
  ];

  const bukaoColumns:any[] = [
    {
      title: '测评科目',
      dataIndex: 'subjectName',
      key: 'subjectName',
      ellipsis: true,
      render: (text: any, record: any) => `${text}（${SubjectLevelName[record.subjectLevel]}级）`
    },
    {
      title: '订单编号',
      dataIndex: 'orderId',
      key: 'orderId',
      ellipsis: true,
    },
    {
      title: '价格',
      dataIndex: 'payAmount',
      key: 'payAmount',
      ellipsis: true,
      render: (text: any) => (text/100).toFixed(2)+'元',
    },
    {
      title: '支付日期',
      ellipsis: true,
      dataIndex: 'payTime',
      key: 'payTime',
      render: (text: any, record: any) => record.orderStatus === 1 ? text : '/'
    },
    {
      title: '姓名',
      dataIndex: 'evaluatorName',
      key: 'evaluatorName',
      ellipsis: true,
    },
    {
      title: '订单状态',
      dataIndex: 'orderStatus',
      key: 'orderStatus',
      render: (text: any, record: any) => ['待支付', '已支付', '退款中', '已退款', '已取消'][text]
    },
    {
      title: '运单编号',
      dataIndex: 'express',
      key: 'express',
      ellipsis: true,
    },
    {
      title: '操作',
      align: 'center',
      render: (text: any, record: any) => (
        <div className={styles.operator}>
          {
            record.orderStatus === 0 ? (
              <>
                <Button
                  type="link"
                  style={{color:'#FAAD14'}}
                  onClick={() => onPayNow(record)}
                >
                  立即支付
                </Button>
                <Button
                  type="link"
                  onClick={() => onCancelOrder(record)}
                >
                  取消订单
                </Button>
              </>
            ) : null
          }
        </div>
      )
    },
  ];

  useEffect(() => {
    getOrderData();
    getEvaluatorData();
    getPlanData();
  }, []);

  const getOrderData = (pageNum: number = 1) => {
    const param = {
      ...query,
      userId: userInfo.id,
      pageNum,
      pageSize: pagination.pageSize,
    };
    getOrderList(param).then((result: any) => {
      setOrderData(result.body.rows);
      setPagination({ ...pagination, current: pageNum, total: result.body.total });
    }).catch((error: any) => {
      message.error(error.msg);
    });
  };

  const getBukaoData = (pageNum: number = 1) => {
    const param = {
      userId: userInfo.id,
      pageNum,
      pageSize: pagination.pageSize,
    };
    certificateOrderList(param).then((result: any) => {
      setBukaoData(result.body.rows);
      setPagination({ ...pagination, current: pageNum, total: result.body.total });
    }).catch((error: any) => {
      message.error(error.msg);
    });
  };

  const getEvaluatorData = () => {
    getEvaluatorList({}).then((result: any) => {
      const list = result.body.list || [];
      setEvaluatorList([{ evaluatorId: '', evaluatorName: '全部' }, ...list]);
    }).catch((error: any) => console.log('获取测评人数据失败', error.msg));
  };

  const getPlanData = () => {
    getAllSubjects({}).then((result: any) => {
      const list = result.body || [];
      setSubjects([{ id: '', subjectName: '全部' }, ...list]);
    }).catch((error: any) => {
      console.log('获取科目数据失败', error.msg);
    });
  };

  const onQueryClick = () => {
    getOrderData();
  };

  const onCancelOrder = (record: any) => {
    Modal.confirm({
      title: `确定取消${currentMenu==='baoming' ? '报名' : '证书补办'}吗？`,
      okText: '确定',
      cancelText: '再想想',
      onOk: () => {
        const { orderId, evaluatorId } = record;
        const param: any = currentMenu==='baoming' ? {
          userId: userInfo.id,
          evaluatorId,
          orderId,
        } : {
          orderId,
        };
        const apiMethod = currentMenu==='baoming' ? cancelOrder : certificateOrderCancel;
        apiMethod(param).then(() => {
          message.success('已取消');
          currentMenu==='baoming' ? getOrderData() : getBukaoData();
        }).catch((error: any) => message.error(error.msg));
      },
    });
  };

  const onPayNow = (record: any) => {
    setShowPayModal(true);
    setOrderInfo(record);
    if (record.orderType) {
      payNow(record);
    }
  };

  const payNow = (record: any) => {
    const { orderId, evaluatorId } = record;
    const param = currentMenu==='baoming' ? {
      userId: userInfo.id,
      orderId,
      evaluatorId,
      payType: record.orderType,
    } : {
      orderId,
      payType: record.orderType,
    };
    const apiMethod = currentMenu==='baoming' ? payOrder : certificateOrderPay;
    apiMethod(param).then((result: any) => {
      if (record.orderType === 2) {
        const doc = iframe.current.contentWindow.document;
        doc.open();
        doc.write(result.body.payInfo);
        doc.close();
      } else {
        setWxQRCodeValue(result.body.payInfo);
      }
    }).catch((error: any) => message.error(error.msg));
  };

  const checkOrderStatus = () => {
    const param = currentMenu==='baoming' ? {
      orderId: orderInfo.orderId,
      evaluatorId: orderInfo.evaluatorId,
      userId: userInfo.id,
    } : {
      orderId: orderInfo.orderId,
    };
    const apiMethod = currentMenu==='baoming' ? getOrderStatus : certificateOrderStatus;
    apiMethod(param).then((result: any) => {
      if (result.body.status === (currentMenu==='baoming' ? 3 : 1)) {
        message.success('支付成功');
        currentMenu==='baoming' ? getOrderData(pagination.current) : getBukaoData(pagination.current);
        setShowPayModal(false);
      } else {
        message.error((currentMenu==='baoming' ? [
          '订单还未支付，请扫码支付',
          '订单待审核，请稍后再试',
          '订单审核失败，请联系客服',
          '订单已支付',
          '订单已取消，请重新下单',
        ] : [
          '订单还未支付，请扫码支付',
          '订单已支付',
          '订单退款中，请联系客服',
          '订单已退款，请重新下单',
          '订单已取消，请重新下单',
        ])[result.body.status]);
      }
    }).catch((error: any) => {
      message.error(error.msg);
    });
  };

  return (
    <div className={styles.wrapper}>
      <PageBar title="">
        <div style={{ flex:1 }}>
          <Menu
            onClick={(e:any) => {
              setCurrentMenu(e.key);
              e.key==='baoming' ? getOrderData() : getBukaoData();
            }}
            selectedKeys={[currentMenu]}
            mode="horizontal"
            items={menuItems}
          />
        </div>
        {
          currentMenu === 'baoming' ? (
            <div className={styles.query_bar}>
              <div className={styles.label}>测评科目</div>
              <Select
                style={{width:200,marginRight:24}}
                value={query.subjectId}
                onChange={(value: any) => setQuery({ ...query, subjectId: value })}
              >
                {
                  subjects.map((subject: any) => (
                    <Select.Option key={subject.id} value={subject.id}>{subject.subjectName}</Select.Option>
                  ))
                }
              </Select>
              <div className={styles.label}>测评人</div>
              <Select
                style={{width:200,marginRight:24}}
                value={query.evaluatorId}
                onChange={(value: any) => setQuery({ ...query, evaluatorId: value })}
              >
                {
                  evaluatorList.map((evaluator: any) => (
                    <Select.Option key={evaluator.evaluatorId} value={evaluator.evaluatorId}>{evaluator.evaluatorName}</Select.Option>
                  ))
                }
              </Select>
              <Button type="primary" onClick={onQueryClick} style={{width:88}}>查询</Button>
            </div>
          ) : null
        }
      </PageBar>
      <div className={`${styles.table_wrapper} ${orderData.length===0 ? styles.empty : ''}`}>
        {
          orderData.length > 0 ? (
            <ConfigProvider renderEmpty={() => <div>暂无订单</div>}>
              <Table
                bordered
                dataSource={currentMenu==='baoming' ? orderData : bukaoData}
                columns={currentMenu==='baoming' ? orderColumns : bukaoColumns}
                rowKey="orderId"
                pagination={pagination}
                onChange={(evt: any) => {
                  getOrderData(evt.current);
                }}
              />
            </ConfigProvider>
          ) : (
            <Empty
              message={ query.subjectId || query.evaluatorId ? '没有符合条件的订单' : '当前无测评报名订单，您未报名！' }
              btnText={ query.subjectId || query.evaluatorId ? '' : '去报名' }
              onBtnClick={() => navigate('/enter-online')}
            />
          )
        }
      </div>
      <Modal
        title="请尽快付款"
        width={600}
        visible={showPayModal}
        closable={false}
        maskClosable={false}
        destroyOnClose={true}
        okText={orderInfo.orderType ? '确认已支付' : '去支付'}
        cancelText="取消支付"
        onCancel={() => {
          getOrderData(pagination.current);
          setShowPayModal(false);
        }}
        onOk={() => {
          if (orderInfo.orderType) {
            checkOrderStatus();
          } else {
            const orderinfo = { ...orderInfo, orderType: nowPayType };
            setOrderInfo(orderinfo);
            payNow(orderinfo);
          }
        }}
      >
        <div className={styles.modal_wrapper}>
          <div className={styles.side_area}>
            <div className={styles.row}>
              <span className={styles.label}>订单金额：</span>
              <span className={styles.price}>{`¥${(orderInfo.payAmount/100).toFixed(2)}`}</span>
            </div>
            <div className={styles.row}>
              <span className={styles.label}>订单编号：</span>
              <span>{orderInfo.orderId}</span>
            </div>
            {
              orderInfo.orderType ? (
                <div className={styles.row}>
                  <span className={styles.label}>支付方式：</span>
                  <span>{['微信支付','支付宝支付'][orderInfo.orderType-1]}</span>
                </div>
              ) : null
            }
            <div className={styles.row}>
              <span className={styles.label}>测评科目：</span>
              <span>{orderInfo.subjectName}</span>
            </div>
            <div className={styles.row}>
              <span className={styles.label}>测评人：</span>
              <span>{orderInfo.evaluatorName}</span>
            </div>
          </div>
          <div className={styles.side_area}>
            {
              orderInfo.orderType ? (
                <>
                  {
                    orderInfo.orderType === 1 ? (
                      <div className={styles.qrcode}>
                        <QRCodeCanvas value={wxQRCodeValue} size={200} />
                      </div>
                    ) : null
                  }
                  {
                    orderInfo.orderType === 2 ? (
                      <iframe id="orderlist_pay_iframe" frameBorder={0} ref={iframe} />
                    ) : null
                  }
                </>
              ) : (
                <div>
                  <div className={styles.paytype_title}>请选择支付方式：</div>
                  <div
                    className={`${styles.pay_type} ${styles.wechat} ${nowPayType===1 ? styles.active : ''}`}
                    onClick={() => setNowPayType(1)}
                  >
                    微信支付
                  </div>
                  <div
                    className={`${styles.pay_type} ${styles.zfb} ${nowPayType===2 ? styles.active : ''}`}
                    onClick={() => setNowPayType(2)}
                  >
                    支付宝支付
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </Modal>
    </div>
  );
};
