import React from 'react';
import styles from './index.module.scss';

interface Iprop {
  text: string;
  width?: number;
  size?: 'normal' | 'big',
  type?: 'blue' | 'green' | 'outline' | 'outgrayline';
  disable?: boolean;
  onClick?: () => void;
  style?: any;
}
      
export const Button = (props: Iprop) => {
  const { text, width='100%', type='blue', size='normal', disable=false, onClick, style } = props;

  return (
    <div
      className={
        `${styles.btn}
        ${type==='green' ? styles.green : ''}
        ${type==='outline' ? styles.outline : ''}
        ${type==='outgrayline' ? styles.outgrayline : ''}
        ${disable ? styles.disable : ''}`
      }
      style={size==='big' ? {fontSize:16, height:40, width, ...style} : {width, ...style}}
      onClick={onClick}
    >
      {text}
    </div>
  );
};
