import React from 'react';
import styles from './index.module.scss';

export default function About (props: any) {

  return (
    <div className={styles.wrapper}>
      <img src="https://static.cpa-test.cn/web-assets/image/about_detail_2.png" />
    </div>
  )
}