import { message } from 'antd';
import React, { useEffect, useState } from 'react';
import { getEvaluatorInfo } from '../../../../utils/api';
import { SubjectLevelName } from '../../../../utils/constant';
import styles from './index.module.scss';

interface Iprop {
  evalution: any;
}

export const EvalutionInfo = (props: Iprop) => {
  const { evalution } = props;
  const [evaUserInfo, setEvaUserInfo] = useState<any>({ ...evalution });

  useEffect(() => {
    const { evaluatorId } = evalution;
    getEvaluatorInfo({ evaluatorId }).then((result: any) => {
      setEvaUserInfo(result.body || {});
    }).catch((error: any) => message.error(error.msg));
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.avatar}>
        <img src={evaUserInfo.avatar} />
      </div>
      <div>
        <div className={styles.info}><label>姓名：</label>{evaUserInfo.evaluatorName}</div>
        <div className={styles.info}><label>证件号：</label>{evaUserInfo.cardNumber}</div>
        <div className={styles.info}><label>测评科目：</label>{evalution.subjectName}{SubjectLevelName[evalution.level]}级</div>
        <div className={styles.info}><label>测评得分：</label>{evalution.totalScore}</div>
      </div>
    </div>
  );
};
