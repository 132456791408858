import { Button, message, Modal, Table } from 'antd';
import { Button as CButton } from '../../components/button'
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Empty } from '../../components/empty';
import { PageBar } from '../../components/page-bar';
import { Panel } from '../../components/panel';
import { getCertificateList } from '../../utils/api';
import styles from './index.module.scss';
import { SubjectLevelName } from '../../utils/constant';
import { EditAddress } from '../my-center/components/edit-address';
import { PayInfo } from './components/payInfo';

interface Iprop {

}

export const Certificate = (props: Iprop) => {
  const navigate = useNavigate();
  const [certificateData, setCertificateData] = useState<any[]>([]);
  const [certificateUrl, setCertificateUrl] = useState('');
  const [certificate, setCertificate] = useState<any>(null);
  const [addressInfo, setAddressInfo] = useState<any>(null);
  const [showConfirmInfo, setShowConfirmInfo] = useState(false);
  const [showEditAddress, setShowEditAddress] = useState(false);
  const [showPayInfo, setShowPayInfo] = useState(false);

  useEffect(() => {
    getCertificateData();
  }, []);

  const getCertificateData = () => {
    getCertificateList({}).then((result: any) => {
      setCertificateData(result.body || []);
    }).catch((error: any) => {
      message.error(error.msg);
    });
  };

  const onConfirmCheckInfo = () => {
    setShowConfirmInfo(false);
    Modal.confirm({
      title: '支付成功后，系统将不再支持退费，请再次确认信息无误后点击确认',
      okText: '确认',
      cancelText: '取消',
      onOk: () => {
        setShowEditAddress(true);
      }
    });
  };

  return (
    <div className={styles.wrapper}>
      <PageBar title="我的测评证书" />
      <div className={`${styles.table_wrapper} ${certificateData.length===0 ? styles.empty : ''}`}>
        {
          certificateData.length > 0 ? (
            <Table
              bordered
              dataSource={certificateData}
              columns={[
                {
                  title: '证书编号',
                  dataIndex: 'certificateNum',
                  key: 'certificateNum',
                  ellipsis: true,
                  // width: 80,
                },
                {
                  title: '测评科目',
                  dataIndex: 'subjectName',
                  key: 'subjectName',
                  ellipsis: true,
                  // width: 80,
                },
                {
                  title: '测评时间',
                  dataIndex: 'evaluationDate',
                  key: 'evaluationDate',
                  ellipsis: true,
                  // width: 80,
                  render: (text: any) => text.split('.')[0].replace('T', ' ')
                },
                {
                  title: '测评人姓名',
                  dataIndex: 'evaluatorName',
                  key: 'evaluatorName',
                  ellipsis: true,
                  // width: 80,
                },
                {
                  title: '测评结果',
                  dataIndex: 'evaluationResult',
                  key: 'evaluationResult',
                  ellipsis: true,
                  // width: 80,
                },
                {
                  title: '操作',
                  render: (text:any, record:any) => (
                    <>
                      <Button
                        type="link"
                        onClick={() => {
                          if (record.certificateUrl) {
                            setCertificateUrl(record.certificateUrl);
                          } else {
                            Modal.info({ title: '暂未提供电子版证书', okText: '知道了' })
                          }
                        }}
                      >
                        查看证书
                      </Button>
                      <Button
                        type="link"
                        onClick={() => {
                          if (record.certificateUrl) {
                            setCertificate(record);
                            setShowConfirmInfo(true);
                          } else {
                            Modal.info({ title: '暂未提供电子版证书', okText: '知道了' })
                          }
                        }}
                      >
                        证书补发
                      </Button>
                    </>
                  )
                },
              ]}
              rowKey="certificateNum"
              pagination={false}
            />
          ) : (
            <Empty
              message="暂无证书"
              btnText="去报名"
              onBtnClick={() => navigate('/enter-online')}
            />
          )
        }
        {
          certificateUrl ? (
            <Panel title="电子证书" width={1000} onClose={() => setCertificateUrl('')}>
              <img style={{ width:'100%' }} src={certificateUrl} />
            </Panel>
          ) : null
        }
        {
          showConfirmInfo ? (
            <Panel title="核对信息" width={620} onClose={() => setShowConfirmInfo(false)}>
              <div className={styles.check_info}>
                <p><strong>补办说明：</strong></p>
                <p>1、工本费：48元/次/人，补办时长30天内</p>
                <p>2、请核对以下信息是否准确，无误后提交申请</p>
                <div className={styles.user_info}>
                  <img className={styles.headpic} src={certificate.evaluationImageUrl} />
                  <div className={styles.rightinfo}>
                    <p>考生姓名：{certificate.evaluatorName}</p>
                    <p>测评课目：{certificate.subjectName}（{SubjectLevelName[certificate.level]}级）</p>
                    <p>测评成绩：{certificate.evaluationResult}</p>
                    <p>测评时间：{certificate.evaluationDate}</p>
                    <p>证书编号：{certificate.certificateNum}</p>
                  </div>
                </div>
                <div className={styles.btnbar}>
                  <CButton size="big" text="确认" width={150} onClick={onConfirmCheckInfo} />
                  <CButton size="big" text="取消" width={150} type="outline" onClick={() => setShowConfirmInfo(false)} />
                </div>
              </div>
            </Panel>
          ) : null
        }
        {
          showEditAddress ? (
            <Panel
              title="填写证书邮寄地址"
              width={560}
              onClose={() => setShowEditAddress(false)}
            >
              <EditAddress
                showCancel
                onCancel={() => setShowEditAddress(false)}
                onSuccess={(value:any) => {
                  setAddressInfo(value);
                  setShowEditAddress(false);
                  setShowPayInfo(true);
                }}
                tipText="该联系地址仅用于补办证书邮寄使用，请认真填写"
              />
            </Panel>
          ) : null
        }
        {
          showPayInfo ? (
            <Panel title="证书补发需要支付48元工本费" width={630} onClose={() => setShowPayInfo(false)}>
              <PayInfo addressInfo={addressInfo} certificate={certificate} />
            </Panel>
          ) : null
        }
      </div>
    </div>
  );
};
