import { Button, message, Modal, Select, Table } from 'antd';
import { Button as CButton } from '../../components/button';
import React, { useEffect, useRef, useState } from 'react';
import styles from './index.module.scss';
import { getAllSubjects, getEvaluatorList, getExamList, updateSessionId } from '../../utils/api';
import { useSelector } from 'react-redux';
import { Empty } from '../../components/empty';
import { useNavigate } from 'react-router-dom';
import { PageBar } from '../../components/page-bar';
import { Panel } from '../../components/panel';
import { EnterCard } from '../exam-guide/components/enter-card';
import { EditTime } from './components/edit-time';
import { ExamLogin } from './components/exam-login';
import { ExamStatusName, SubjectLevelName } from '../../utils/constant';
import { Reason } from '../../components/Reason';
import { EditAddress } from '../my-center/components/edit-address';
import { dateStr2Date } from '../../utils/util';
      
export const Examination = (props: any) => {
  const navigate = useNavigate();

  const userInfo = useSelector((state: any) => state.userInfo);
  const [examData, seExamData] = useState<any[]>([]);
  const [subjects, setSubjects] = useState<any[]>([]);
  const [evaluatorList, setEvaluatorList] = useState<any[]>([]);
  const [query, setQuery] = useState<any>({ subjectId: '', evaluatorId: '' });
  const [evaluator, setEvaluator] = useState<any>(null);
  const [examInfo, setExamInfo] = useState<any>(null);
  const [showEnterCard, setShowEnterCard] = useState(false);
  const [showEditTime, setShowEditTime] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
    showTotal: (total: any) => `共 ${total} 条`,
  });
  const [showLogin, setShowLogin] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [showEditAddress, setShowEditAddress] = useState(false);

  useEffect(() => {
    getExamData();
    getEvaluatorData();
    getPlanData();
  }, []);

  const getExamData = (pageNum: number = 1) => {
    const param = {
      ...query,
      userId: userInfo.id,
      pageNum,
      pageSize: pagination.pageSize,
    };
    getExamList(param).then((result: any) => {
      const arr = result.body.rows || [];
      arr.forEach((item: any) => item.timestamp = result.timestamp);
      seExamData(arr);
      setPagination({ ...pagination, current: pageNum, total: result.body.total });
    }).catch((error: any) => {
      message.error(error.msg);
    });
  };

  const getEvaluatorData = () => {
    getEvaluatorList({}).then((result: any) => {
      const list = result.body.list || [];
      setEvaluatorList([{ evaluatorId: '', evaluatorName: '全部' }, ...list]);
    }).catch((error: any) => console.log('获取测评人数据失败', error.msg));
  };

  const getPlanData = () => {
    getAllSubjects({}).then((result: any) => {
      const list = result.body || [];
      setSubjects([{ id: '', subjectName: '全部' }, ...list]);
    }).catch((error: any) => {
      console.log('获取科目数据失败', error.msg);
    });
  };

  const onQueryClick = () => {
    getExamData();
  };

  const editExamTime = (sessionsId: string) => {
    const param = {
      userId: userInfo.id,
      evaluatorId: evaluator.evaluatorId,
      evaluationId: examInfo.evaluationId,
      orderId: examInfo.orderId,
      sessionsId,
    };
    updateSessionId(param).then(() => {
      message.success('修改成功');
      getExamData(pagination.current);
    }).catch((error: any) => message.error(error.msg));
  };

  const getEvaluatorById = (evaluatorId: any) => {
    if (evaluatorList.length > 0) {
      const curEva = evaluatorList.find((eva: any) => eva.evaluatorId === evaluatorId);
      return curEva;
    }
    return null;
  };

  const getExamFinishBtns = (record: any) => {
    return (
      record.fbStatus===1 ? (
        record.scoreValid===0 ? (
          <>
            <Button
              type="link"
              onClick={() => {
                setExamInfo(record);
                setShowResult(true);
                // message.info('暂未开放');
              }}
            >
              查看成绩
            </Button>
            <Button
              type="link"
              style={{color:'#FF7227'}}
              onClick={() => {
                if (record.expressType === 0) {
                  setShowEditAddress(true);
                } else {
                  Modal.info({
                    title: `证书由${record.orgName}渠道统一发放，无需填写邮寄地址`,
                    okText: '知道了',
                  });
                }
              }}
            >
              填写证书快递地址
            </Button>
          </>
        ) : (
          <div style={{display:'flex', alignItems:'center', justifyContent: 'center'}}>
            <span>成绩无效</span>
            <Reason label="成绩无效原因" text={record.invalidReason} toLeft />
          </div>
        )
      ) : (
        <span style={{color:'#aaaaaa'}}>成绩未发布</span>
      )
    );
  };

  const getNoStartBtns = (record: any) => (
    <>
      <Button
        type="link"
        onClick={() => {
          setExamInfo(record);
          const curEva = getEvaluatorById(record.evaluatorId);
          if (curEva) {
            setEvaluator(curEva);
            setShowEnterCard(true);
          }
        }}
      >
        查看测评证
      </Button>
      {
        record.status === 5 ? null : (
          <Button
            type="link"
            onClick={() => {
              setExamInfo(record);
              const curEva = getEvaluatorById(record.evaluatorId);
              if (curEva) {
                setEvaluator(curEva);
                setShowEditTime(true);
              }
            }}
          >
            更改测评时间
          </Button>
        )
      }
    </>
  );

  const getStartBtn = (record: any) => (
    <Button
      type="link"
      style={{color:'#52C41A'}}
      onClick={() => {
        setExamInfo(record);
        const curEva = getEvaluatorById(record.evaluatorId);
        if (curEva) {
          setEvaluator(curEva);
          setShowLogin(true);
        } else {
          message.error('测评人不存在');
        }
      }}
    >
      开始正式测评
    </Button>
  );

  const getBKbtn = (record: any) => {
    const timeNow = new Date(Number(record.timestamp));
    const startDate = dateStr2Date(record.sessionsTime);
    if (
      timeNow.getFullYear() === startDate.getFullYear() &&
      timeNow.getMonth() === startDate.getMonth() &&
      timeNow.getDate() === startDate.getDate()
    ) {
      return getStartBtn(record);
    } else {
      return getNoStartBtns(record);
    }
  };

  return (
    <>
      <div className={styles.toptip}>官网为测评成绩唯一发布渠道，测评成绩以官网公布信息为准。请不要相信其它渠道信息！</div>
      <div className={styles.wrapper}>
        <PageBar title="正式测评">
          <div className={styles.query_bar}>
            <div className={styles.label}>测评科目</div>
            <Select
              style={{width:200,marginRight:24}}
              value={query.subjectId}
              onChange={(value: any) => setQuery({ ...query, subjectId: value })}
            >
              {
                subjects.map((subject: any) => (
                  <Select.Option key={subject.id} value={subject.id}>{subject.subjectName}</Select.Option>
                ))
              }
            </Select>
            <div className={styles.label}>姓名</div>
            <Select
              style={{width:200,marginRight:24}}
              value={query.evaluatorId}
              onChange={(value: any) => setQuery({ ...query, evaluatorId: value })}
            >
              {
                evaluatorList.map((evaluator: any) => (
                  <Select.Option key={evaluator.evaluatorId} value={evaluator.evaluatorId}>{evaluator.evaluatorName}</Select.Option>
                ))
              }
            </Select>
            <Button type="primary" onClick={onQueryClick} style={{width:88}}>查询</Button>
          </div>
        </PageBar>
        <div className={`${styles.table_wrapper} ${examData.length===0 ? styles.empty : ''}`}>
          {
            examData.length > 0 ? (
              <Table
                bordered
                dataSource={examData}
                columns={[
                  {
                    title: '测评名称',
                    dataIndex: 'planName',
                    key: 'planName',
                  },
                  {
                    title: '测评科目',
                    dataIndex: 'subjectName',
                    key: 'subjectName',
                    render: (text: any, record: any) => `${text}（${SubjectLevelName[record.level]}级）`
                  },
                  {
                    title: '测评证号',
                    dataIndex: 'evaluationNum',
                    key: 'evaluationNum',
                  },
                  {
                    title: '测评时间',
                    dataIndex: 'sessionsTime',
                    key: 'sessionsTime',
                  },
                  {
                    title: '姓名',
                    dataIndex: 'evaluatorName',
                    key: 'evaluatorName',
                  },
                  {
                    title: '运单编号',
                    dataIndex: 'express',
                    key: 'express',
                    render: (text: any) => text ? (<div dangerouslySetInnerHTML={{ __html:text.replace(':', '<br />') }} />) : '',
                  },
                  {
                    title: '测评状态',
                    dataIndex: 'status',
                    key: 'status',
                    render: (text: any) => ExamStatusName[text],
                  },
                  {
                    title: '操作',
                    align: 'center',
                    render: (text: any, record: any) => (
                      <div className={styles.operator}>
                        {
                          record.status === 1 ? getNoStartBtns(record) : null
                        }
                        {
                          record.status === 2 ? getStartBtn(record): null
                        }
                        {
                          record.status === 3 ? (
                            getExamFinishBtns(record)
                          ) : null
                        }
                        {
                          record.status === 5 ? getBKbtn(record) : null
                        }
                        {
                          process.env.NODE_ENV === 'development' ? (
                            <Button
                              type="link"
                              style={{color:'#52C41A'}}
                              onClick={() => {
                                setExamInfo(record);
                                const curEva = getEvaluatorById(record.evaluatorId);
                                if (curEva) {
                                  setEvaluator(curEva);
                                  setShowLogin(true);
                                }
                              }}
                            >
                              进入测评（本地）
                            </Button>
                          ) : null
                        }
                      </div>
                    )
                  },
                ]}
                rowKey="evaluationId"
                pagination={pagination}
                onChange={(evt: any) => {
                  getExamData(evt.current);
                }}
              />
            ) : (
              <Empty
                message={ query.subjectId || query.evaluatorId ? '没有符合条件的测评' : '当前无正式测评，您未报名！' }
                btnText={ query.subjectId || query.evaluatorId ? '' : '去报名' }
                onBtnClick={() => navigate('/enter-online')}
              />
            )
          }
        </div>
      </div>
      {/* 测评证 */}
      {
        showEnterCard ? (
          <Panel
            title="打印/下载测评证"
            width={740}
            alignTop={true}
            onClose={() => setShowEnterCard(false)}
            topChildren={
              <div className={`${styles.toptip} ${styles.inpanel}`}>
                请核对信息与参与测评人信息一致且照片为本人一寸证件照（若通过测评，此照片会用于证书照片，请按要求上传！）如信息或照片与本人不符，请重新点击查看测评证并修改个人信息。如有疑问，请拨打 4009903330 咨询。
              </div>
            }
          >
            <EnterCard userInfo={evaluator} examInfo={examInfo} />
          </Panel>
        ) : null
      }
      {/* 修改测评时间 */}
      {
        showEditTime ? (
          <Panel
            title="更改测评时间"
            width={560}
            onClose={() => setShowEditTime(false)}
          >
            <EditTime
              examInfo={examInfo}
              onCancel={() => setShowEditTime(false)}
              onConfirm={(sessionId: string) => {
                setShowEditTime(false);
                editExamTime(sessionId);
              }}
            />
          </Panel>
        ) : null
      }
      {/* 登录 */}
      {
        showLogin && examInfo ? (
          <ExamLogin
            examInfo={examInfo}
            onClose={() => setShowLogin(false)}
            onSuccess={() => {
              navigate(`/env-check?type=exam`, { state: { exam: examInfo, user: evaluator } });
            }}
          />
        ) : null
      }
      {/* 查询成绩 */}
      {
        showResult ? (
          <Panel
            title="查询成绩"
            width={430}
            onClose={() => setShowResult(false)}
          >
            <div className={styles.result_row}>
              <span>科目/等级</span>
              {examInfo.subjectName} {SubjectLevelName[examInfo.level]}级
            </div>
            <div className={styles.result_row}>
              <span>成绩</span>
              {examInfo.evaluationResult}
            </div>
            <div className={styles.result_row}>
              <span></span>
              <CButton size="big" text="我已知晓" width={120} style={{marginTop:24}} onClick={() => setShowResult(false)} />
            </div>
          </Panel>
        ) : null
      }
      {
        showEditAddress ? (
          <Panel
          title="填写证书邮寄地址"
          width={560}
          onClose={() => setShowEditAddress(false)}
          >
            <EditAddress
              showCancel
              onCancel={() => setShowEditAddress(false)}
              onSuccess={() => setShowEditAddress(false)}
            />
          </Panel>
        ) : null
      }
    </>
  );
};
