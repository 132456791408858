import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import styles from './App.module.scss';

import Layout from './components/layout';
import Home from './pages/home';
import About from './pages/about';
import Test from './pages/test';
import { store } from './store';
import { Empty } from './pages/empty';
import { EnterOnline } from './pages/enter-online';
import { OrderList } from './pages/order-list';
import { EnterForm } from './pages/enter-form';
import { Evaluator } from './pages/evaluator';
import { EvaluatorEdit } from './pages/evaluator-edit';
import { MyCenter } from './pages/my-center';
import { ExamGuide } from './pages/exam-guide';
import { Examination } from './pages/examination';
import { EnvCheck } from './pages/env-check';
import { ExamMock } from './pages/exam-mock';
import { ExamMockStart } from './pages/exam-mock-start';
import { ExaminationStart } from './pages/examination-start';
import { ExamFormal } from './pages/exam-formal';
import { Certificate } from './pages/certificate';
import { CertificateQuery } from './pages/certificate-query';
import { Article } from './pages/article';

function App() {
  return (
    <Provider store={store}>
    <div className={styles.App}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout><Home /></Layout>} />
          <Route path="exam-guide" element={<Layout styleType={2}><ExamGuide /></Layout>} />
          <Route path="enter-online" element={<Layout styleType={2}><EnterOnline /></Layout>} />
          <Route path="enter-form" element={<Layout styleType={2}><EnterForm /></Layout>} />
          <Route path="examination" element={<Layout styleType={2}><Examination /></Layout>} />
          <Route path="exam-mock" element={<Layout styleType={2}><ExamMock /></Layout>} />
          <Route path="about" element={<Layout><About /></Layout>} />
          <Route path="orderlist" element={<Layout styleType={2}><OrderList /></Layout>} />
          <Route path="evaluator" element={<Layout styleType={2}><Evaluator /></Layout>} />
          <Route path="evaluator-edit" element={<Layout styleType={2}><EvaluatorEdit /></Layout>} />
          <Route path="my-center" element={<Layout styleType={2}><MyCenter /></Layout>} />
          <Route path="certificate" element={<Layout styleType={2}><Certificate /></Layout>} />
          <Route path="certificate-query" element={<Layout styleType={2}><CertificateQuery /></Layout>} />
          <Route path="article" element={<Layout><Article /></Layout>} />
          <Route path="test" element={<Layout><Test /></Layout>} />
          <Route path="*" element={<Layout styleType={2}><Empty /></Layout>} />
          {/* 以下是测评相关的路由 */}
          <Route path="env-check" element={<Layout styleType={3}><EnvCheck /></Layout>} />
          <Route path="exam-mock-start" element={<Layout styleType={3}><ExamMockStart /></Layout>} />
          <Route path="examination-start" element={<Layout styleType={3}><ExaminationStart /></Layout>} />
          <Route path="exam-formal" element={<Layout styleType={3}><ExamFormal /></Layout>} />
        </Routes>
      </BrowserRouter>
    </div>
    </Provider>
  );
}

export default App;
