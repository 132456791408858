import React from 'react';
import styles from './index.module.scss';

interface Iprop {
  title: string;
  children?: any;
}
      
export const PageBar = (props: Iprop) => {
  const { title, children } = props;
  return (
    <div className={styles.page_bar}>
      { title ? <span className={styles.title}>{title}</span> : null }
      { children }
    </div>
  );
};
