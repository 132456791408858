import { message, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '../../components/button';
import { NavigationBar } from '../../modules/navigation-bar';
import { ActionType } from '../../store';
import { getMockAnswerInfo, getRealAnswerInfo, submitUserAnswer, submitUserPaper, reportExamError } from '../../utils/api';
import { dateStr2Date, getQuery, requestFullscreen } from '../../utils/util';
import { AnswerCard, AnswerCardLegend } from './components/answer-card';
import { EvalutionInfo } from './components/evalution-info';
import { Loading } from './components/loading';
import { Preview } from './components/preview';
import { Question } from './components/question';
import { SubmitSuccess } from './components/submit-success';
import { Timer } from './components/timer';
import { VideoCamera } from './components/video-camera';
import styles from './index.module.scss';

export enum EQuestionType {
  Single = 1,
  Multi = 2,
  Judge = 3,
  Code = 4,
}

export const QuestionTypeName = ['未知', '单选', '多选', '判断', '编程'];

export enum ESubjectType {
  Cpp = 0,
  Python = 1,
  Scratch = 2,
}

let answerTime = 0; // 单个题目的答题时间（秒）

interface Iprop {

}

export const ExamFormal = (props: Iprop) => {
  const location = useLocation();
  const navigate = useNavigate();
  const evaluation = location.state?.evaluation;
  const examInfo = location.state?.exam;
  const evaluationId = getQuery('evaluationId');
  const paperId = getQuery('paperId');
  const isMock = getQuery('mock') === '1';
  const isReview = getQuery('review') === '1';
  const userInfo = useSelector((state: any) => state.userInfo);
  const examErrors = useSelector((state: any) => state.examErrors);
  const [totalTime, setTotalTime] = useState(-1);
  const [questionList, setQuestionList] = useState<any[]>([]);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [leftHide, setLeftHide] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const publishInfo = useSelector((state: any) => state.publishInfo);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!evaluation) {
      message.error('未选择测评人');
      setTimeout(() => {
        navigate('/', { replace: true });
      }, 1000);
    } else {
      if (isMock) {
        getMockAnswerData();
      } else {
        getRealAnswerData();
      }
      // 检查全局store是否已有推流地址，如果没有，则重新设置
      if (!isReview) {
        if (!publishInfo.url) {
          dispatch({
            type: ActionType.UPDATE_PUBLISHINFO,
            // value: { url: isMock ? 'mock' : examInfo.pushUrl }, // 正式测评也不推流
            value: { url: 'mock' },
          });
        } else {
          requestFullscreen();
        }
      }
    }
    return () => {
      Modal.destroyAll();
      dispatch({
        type: ActionType.CLEAR_EXAM_ERROR,
      });
    }
  }, []);

  const getMockAnswerData = () => {
    if (!evaluationId || !paperId) {
      message.error('页面参数有误');
      return;
    }
    setIsLoading(true);
    getMockAnswerInfo({
      evaluationId,
      paperId,
      evaluatorId: evaluation.evaluatorId,
      showRigthAnswer: isReview ? 0 : 1,
    }).then((result: any) => {
      setIsLoading(false);
      const arr = decorateQuestion(result.body || []);
      setQuestionList(arr);
      answerTime = (new Date()).getTime(); // 记录一个时间戳，计算答题时间
      setTotalTime(3600); // 模拟考试 固定1小时
    }).catch((error: any) => {
      setIsLoading(false);
      message.error(error.msg);
    });
  };

  const getRealAnswerData = () => {
    if (!evaluationId) {
      message.error('页面参数有误');
      return;
    }
    setIsLoading(true);
    getRealAnswerInfo({
      userId: userInfo.id,
      evaluationId,
      evaluatorId: evaluation.evaluatorId,
      isBk: examInfo.status===5 ? 1 : 0, // 是否补考
    }).then((result: any) => {
      setIsLoading(false);
      const arr = decorateQuestion(result.body.questionList || []);
      setQuestionList(arr);
      answerTime = (new Date()).getTime(); // 记录一个时间戳，计算答题时间
      const nowTimeMillionSeconds = new Date(Number(result.timestamp)).getTime();
      const endTimeMillionSeconds = dateStr2Date(examInfo.sessionsEndTime).getTime();
      const offsetSeconds = Math.floor((endTimeMillionSeconds - nowTimeMillionSeconds) / 1000);
      if(offsetSeconds > 0) {
        setTotalTime(offsetSeconds);
      } else {
        navigate(-1);
        message.error('考试已结束');
      }
    }).catch((error: any) => {
      setIsLoading(false);
      message.error(error.msg);
      navigate(-1);
    });
  };

  const decorateQuestion = (arr: any[]) => {
    arr.forEach((item: any, index: number) => {
      item.questionIndex = index;
      item.complete = !!item.userAnswer; // 是否已做
      item.check = false; // 是否待查
    });
    return arr;
  };

  const changeQuestionIndex = (index: number) => {
    if (isReview) {
      setQuestionIndex(index);
    } else {
      doSubmitUserAnswer(() => {
        answerTime = (new Date()).getTime(); // 每次切题，更新答题时间戳
        setQuestionIndex(index);
      });
    }
  };

  useEffect(() => {
    if (!isMock) {
      if(examErrors.length > 0 && questionList.length > 0) {
        const param = {
          evaluatorId: evaluation.evaluatorId,
          evaluationId,
          questionId: questionList[questionIndex].questionId,
          questionType: questionList[questionIndex].questionType,
          type: examErrors[0].type,
        }
        reportExamError(param).then(() => {
          console.log('异常上报成功，类型：', examErrors[0].type);
        }).catch((error: any) => console.log(error.msg));
      }
    }
  }, [examErrors.length]);

  const doSubmitUserAnswer = (callback: any) => {
    const { questionId, questionType, evaluatorAnswerId, userAnswer, score } = questionList[questionIndex];
    if (!userAnswer) {
      callback();
      return;
    }
    const answerTimeNow = (new Date()).getTime();
    const answerTimeOffset = Math.ceil((answerTimeNow - answerTime) / 1000);
    console.log(`第${questionIndex+1}题，用时：${answerTimeOffset}秒`);
    const param = {
      evaluatorId: evaluation.evaluatorId,
      evaluatorAnswerId, // 更新答案时要传，每次提交答案后，要更新这个
      evaluationId,
      evaluationType: isMock ? 0 : 1,
      paperId,
      questionId,
      questionType,
      answerTime: answerTimeOffset,
      answer: userAnswer, // 单选-A 多选-A,B 判断-T/F 编程-代码
      score,
    };
    setIsLoading(true);
    submitUserAnswer(param).then((result: any) => {
      setIsLoading(false);
      const arr = [...questionList];
      arr[questionIndex].complete = true;
      arr[questionIndex].evaluatorAnswerId = result.body.evaluatorAnswerId;
      callback();
    }).catch((error: any) => {
      setIsLoading(false);
      if (error.code === 700001) {
        doSubmitPaper();
      } else {
        message.error(error.msg);
      }
    });
  };

  const onTimeup = () => {
    if (isMock) {
      Modal.confirm({
        title: '考试时间到',
        content: '是否继续测评？（正式测评时，不允许继续测评，系统会自动交卷）',
        okText: '交卷',
        cancelText: '继续测评',
        onOk: () => {
          submitPaper();
        }
      });
    } else {
      message.info('考试时间已到');
      Modal.destroyAll();
      submitPaper(false);
    }
  };

  // 提交试卷（含试卷检查）
  const submitPaper = (checkAnswer: boolean = true) => {
    doSubmitUserAnswer(() => {
      if (checkAnswer) {
        for (let i=0; i<questionList.length; i++) {
          let reason = '';
          if (!questionList[i].userAnswer) {
            reason = '还有未作答的题目，确定交卷吗？';
          }
          if (questionList[i].check) {
            reason = '还有待查的题目，确定交卷吗？';
          }
          if (reason) {
            Modal.confirm({
              title: reason,
              okText: '交卷',
              cancelText: '再想想',
              onOk: () => doSubmitPaper(),
            });
            return;
          }
        }
      }
      doSubmitPaper();
    });
  };

  // 提交试卷
  const doSubmitPaper = () => {
    Modal.destroyAll();
    const param = {
      evaluatorId: evaluation.evaluatorId,
      evaluationId,
      paperId,
      type: isMock ? 0 : 1,
    };
    setIsLoading(true);
    submitUserPaper(param).then((result: any) => {
      setIsLoading(false);
      setSubmitSuccess(true);
      dispatch({
        type: ActionType.UPDATE_PUBLISHINFO,
        value: { url: '' },
      });
    }).catch((error: any) => {
      setIsLoading(false);
      Modal.info({
        content: error.msg,
        okText: '重试',
        onOk: () => doSubmitPaper(),
      });
    });
  }

  return (
    <div className={styles.wrapper}>
      {/* 导航区域 */}
      <NavigationBar
        easyMode
        centerChildren={
          totalTime === -1 || isReview ?
            null :
            <Timer totalTime={totalTime} onTimeup={onTimeup} />
        }
        rightChildren={
          !isReview ? (
            <div
              className={`${styles.btn} ${styles.shadow} ${questionList.length ? '' : styles.disable}`}
              style={{marginRight:40}}
              onClick={() => submitPaper()}
            >
              交 卷
            </div>
          ) : (
            <div
              className={`${styles.btn} ${styles.shadow}`}
              style={{marginRight:40}}
              onClick={() => {
                if (isMock) {
                  navigate('/exam-mock', { replace: true });
                } else {
                  navigate('/examination', { replace: true });
                }
              }}
            >
              返 回
            </div>
          )
        }
      />
      <div className={styles.content}>
        {/* 左侧区域 */}
        <div className={`${styles.leftside} ${leftHide ? styles.hide : ''}`}>
          <div className={styles.left_content}>
            <div className={styles.left_title}>
              <span>{ isReview ? '参评人信息' : '实时影像' }</span>
              <div className={styles.btn_shouqi} onClick={() => setLeftHide(!leftHide)} />
            </div>
            {
              isReview ? (
                evaluation ? <EvalutionInfo evalution={evaluation} /> : null
              ) : (
                submitSuccess ? null : <VideoCamera />
              )
            }
            {/* 答题卡 */}
            {
              questionList.length ? (
                <AnswerCard
                  questionList={questionList}
                  questionIndex={questionIndex}
                  isReview={isReview}
                  onChange={(value: number) => changeQuestionIndex(value)}
                />
              ) : null
            }
          </div>
          {
            leftHide ? <div className={styles.btn_shouqi} onClick={() => setLeftHide(!leftHide)} /> : null
          }
        </div>
        {/* 右侧区域 */}
        <div className={styles.rightside}>
          {
            questionList.length > 0 ? (
              <>
                <Question
                  evaluationId={evaluationId || ''}
                  evaluation={evaluation}
                  data={questionList[questionIndex]}
                  isMock={isMock}
                  isReview={isReview}
                  onUserAnswer={(value: any) => {
                    const arr = [...questionList];
                    if (arr[questionIndex].userAnswer && arr[questionIndex].userAnswer !== value) {
                      dispatch({
                        type: ActionType.ADD_EXAM_ERROR,
                        value: { type: 3, time: (new Date()).getTime() },
                      });
                    }
                    arr[questionIndex].userAnswer = value;
                    setQuestionList(arr);
                  }}
                />
                {
                  isMock ? <div className={styles.modal_mock} /> : null
                }
                {/* <Preview questionList={questionList} /> */}
              </>
            ) : null
          }
        </div>
      </div>
      {/* 底部按钮区域 */}
      <div className={styles.footer}>
        <AnswerCardLegend isReview={isReview} />
        {
          questionList.length > 0 ? (
            <span className={styles.question_count}>
              第{questionIndex+1}题（{QuestionTypeName[questionList[questionIndex].questionType]}题）
              / 共{questionList.length}题
            </span>
          ) : null
        }
        <div style={{display:'flex'}}>
          {
            questionList.length > 0 && !isReview ? (
              <div
                className={`${styles.btn} ${questionList[questionIndex].check ? styles.ghost : ''}`}
                onClick={() => {
                  const arr = [...questionList];
                  arr[questionIndex].check = !arr[questionIndex].check;
                  setQuestionList(arr);
                  dispatch({
                    type: ActionType.ADD_EXAM_ERROR,
                    value: { type: arr[questionIndex].check ? 5 : 6, time: (new Date()).getTime() },
                  });
                }}
              >
                { questionList[questionIndex].check ? '取消待查' : '待查' }
              </div>
            ) : null
          }
          {
            questionIndex > 0 ? (
              <Button
                text="上一题"
                size="big"
                width={112}
                style={{marginLeft:8}}
                onClick={() => changeQuestionIndex(questionIndex-1)}
              />
            ) : null
          }
          {
            questionIndex < questionList.length - 1 ? (
              <Button
                text="下一题"
                size="big"
                width={112}
                style={{marginLeft:8}}
                onClick={() => changeQuestionIndex(questionIndex+1)}
              />
            ) : null
          }
        </div>
      </div>
      {/* 全屏loaidng状态 */}
      {
        isLoading ? (<Loading />) : null
      }
      {/* 交卷完成 */}
      {
        submitSuccess ? (<SubmitSuccess isMock={isMock} />) : null
      }
    </div>
  );
};
