import { message } from 'antd';
import React, { useState } from 'react';
import { AWSCslide } from '../../../../components/AWSC';
import md5 from 'js-md5';
import { sendSms, updatePwd } from '../../../../utils/api';
import styles from './index.module.scss';
import { Input } from '../../../../components/input';
import { useSelector } from 'react-redux';
import { Panel } from '../../../../components/panel';

interface LoginProps {
  onClose?: () => void;
}

export const EditPwd = (props: LoginProps) => {
  const userInfo = useSelector((state: any) => state.userInfo);
  const { onClose } = props;
  const [formData, setFormData] = useState<any>({
    phone: { value: userInfo.telphone, error: '' },
    smsCode: { value: '', error: '' },
    password2: { value: '', error: '' },
    password2Confirm: { value: '', error: '' },
  });
  const [awscInfo, setAwscInfo] = useState<any>({ sessionId: '', sig: '', token: '' });
  const [isLoging, setIsLoging] = useState(false);
  const [codeTimer, setCodeTimer] = useState(0); // 验证码倒计时

  const canLogin = () => {
    const valied =
      formData.phone.value.trim() &&
      formData.smsCode.value.trim() &&
      formData.password2.value.trim() &&
      formData.password2Confirm.value.trim();
    return valied;
  };

  const onSmsCodeClick = () => {
    const phone = formData.phone.value;
    if (phone.length !== 11) {
      setFormData({ ...formData, phone: { value: phone, error: '手机号格式有误' } });
      return;
    }
    const param = {
      ...awscInfo,
      phone,
      scene: 'UPDATE_PWD',
    };
    sendSms(param).then(() => {
      message.success('短信已发送');
      startSmsTimer();
    }).catch((error: any) => {
      message.error(error.msg);
      window.nc.reset();
      setAwscInfo({ sessionId: '', sig: '', token: '' });
    });
  };

  // 验证码倒计时
  const startSmsTimer = () => {
    const timeFunc = (t: number) => {
      setTimeout(() => {
        if (t > 0) {
          setCodeTimer(t - 1);
          timeFunc(t - 1);
        } else {
          console.log('倒计时结束', t);
        }
      }, 1000);
    };
    const time = 60;
    setCodeTimer(time);
    timeFunc(time);
  };

  /** 点击提交 */
  const onLoginClick = () => {
    const phone = formData.phone.value;
    const smsCode = formData.smsCode.value;
    const pwd2 = formData.password2.value.trim();
    const pwd2Cfm = formData.password2Confirm.value.trim();
    // 验证入参合法性
    if (phone.length !== 11) {
      setFormData({ ...formData, phone: { value: phone, error: '手机号格式有误' } });
      return;
    } 
    if (smsCode.length !== 6) {
      setFormData({ ...formData, smsCode: { value: smsCode, error: '验证码格式有误' } });
      return;
    }
    if (pwd2.length < 8) {
      setFormData({ ...formData, password2: { value: pwd2, error: '密码长度不能少于8个字符' } });
      return;
    }
    if (pwd2 !== pwd2Cfm) {
      setFormData({ ...formData, password2Confirm: { value: pwd2Cfm, error: '两次输入密码不一致' } });
      return;
    }
    // 根据类型组织参数
    let param: any = { phone, smsCode, pwd: md5(pwd2) };
    setIsLoging(true);
    updatePwd(param).then((result: any) => {
      message.success('密码修改成功');
      setTimeout(() => {
        setIsLoging(false);
        onClose && onClose();
      }, 800);
    }).catch((error: any) => {
      setIsLoging(false);
      window.nc.reset();
      setAwscInfo({ sessionId: '', sig: '', token: '' });
      message.error(error.msg);
    });
  };
  
  return (
    <Panel title="修改密码" onClose={onClose}>
      <Input
        label="登录手机号"
        labelWidth={95}
        type="number"
        // icon="phone"
        disable
        value={formData.phone.value}
      />
      <div className={styles.row} style={{marginBottom:24}}>
        <div className={styles.space} />
        <div>
          <AWSCslide
            appkey={process.env.REACT_APP_AWSC_APPKEY || ''}
            scene={process.env.REACT_APP_AWSC_SCENE || ''}
            success={data => {
              setAwscInfo(data);
            }}
          />
        </div>
      </div>
      <Input
        label="验证码"
        labelWidth={95}
        type="number"
        // icon="smscode"
        placeholder="请输入验证码"
        maxLength={6}
        value={formData.smsCode.value}
        onChange={(value: string) => {
          setFormData({ ...formData, smsCode: { value, error: ''} });
        }}
        errorInfo={formData.smsCode.error}
      >
        <div
          className={`
            ${styles.btn_smscode}
            ${(formData.phone.value.trim() && awscInfo.token && codeTimer===0) ? '' : styles.disable}
          `}
          onClick={onSmsCodeClick}
        >
          { codeTimer>0 ? `${codeTimer}s重新获取` : '获取验证码' }
        </div>
      </Input>
      <Input
        label="新密码"
        labelWidth={95}
        // icon="pwd"
        placeholder="请输入密码"
        type="password"
        maxLength={30}
        value={formData.password2.value}
        onChange={(value: string) => {
          setFormData({ ...formData, password2: { value, error: ''} });
        }}
        errorInfo={formData.password2.error}
      />
      <Input
        label="确认密码"
        labelWidth={95}
        // icon="pwd"
        placeholder="请再次输入密码"
        type="password"
        maxLength={30}
        value={formData.password2Confirm.value}
        onChange={(value: string) => {
          setFormData({ ...formData, password2Confirm: { value, error: ''} });
        }}
        errorInfo={formData.password2Confirm.error}
      />
      <div className={styles.row} style={{marginTop:40}}>
        <div className={styles.space} />
        <div>
          <div
            className={`${styles.btn_login} ${(canLogin() && !isLoging) ? '' : styles.disable}`}
            onClick={onLoginClick}
          >
            { isLoging ? '提交中...' : '提交' }
          </div>
        </div>
      </div>
    </Panel>
  );
};
