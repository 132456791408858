import React, { useEffect } from 'react';
import { EQuestionType, ESubjectType } from '../..';
import CodeMirror from '@uiw/react-codemirror';
import { python } from '@codemirror/lang-python';
import { cpp } from '@codemirror/lang-cpp';
import styles from './index.module.scss';
import { uploadFile } from '../../../../utils/api';
import { message } from 'antd';
import { RunCode } from './runCode';

interface Iprop {
  evaluationId: string;
  data: any;
  evaluation: any;
  isMock: boolean;
  isReview: boolean;
  onUserAnswer?: (value: any) => void;
}

let _audio: any = null;

const JudgeOptText: any = {
  T: '✅',
  F: '❌',
}

export const Question = (props: Iprop) => {
  const { evaluationId, data, evaluation, isMock, isReview, onUserAnswer } = props;

  useEffect(() => {
    return () => {
      pauseClearAudio();
    }
  }, []);

  useEffect(() => {
    pauseClearAudio();
  }, [data]);

  useEffect(() => {
    window.onmessage = (evt: any) => {
      const allowOrigin = [
        'https://cpa.compsens.com',
        'https://cpa-test.cn',
        'http://localhost:8601',
      ];
      if (allowOrigin.includes(evt.origin)) {
        if (evt.data.type && evt.data.type === 'onScratchEditorSave') {
          const folder = 'cpatest/exam/question/scratch/useranswer/';
          let scratchFileName = `${evaluationId}-${data.questionId}.sb3`;
          uploadFile(evt.data.data, folder, scratchFileName).then((result: any) => {
            onUserAnswer && onUserAnswer(result.ossUrl);
            message.success('已保存');
          }).catch((error: any) => message.error(error.msg));
        }
      }
    };
  }, [onUserAnswer]);

  const getSourceParam = () => {
    const answer = data.userAnswer ? data.userAnswer : (data.partCode ? data.partCode : '')
    if (answer) {
      return `&source=${encodeURIComponent(answer)}${isReview ? '&answer=user' : ''}`;
    }
    return '';
  };

  const pauseClearAudio = () => {
    if (_audio) {
      _audio.pause();
      _audio = null;
    }
  };

  const getOptActive = (opt: string) => {
    return data.userAnswer ? (data.userAnswer.includes(opt)) : false;
  };

  const onVoiceClick = (evt: any, voiceUrl: string) => {
    evt.stopPropagation();
    pauseClearAudio();
    _audio = new Audio();
    _audio.src = voiceUrl;
    _audio.play();
  };

  const userAnswerCorrect = () => {
    if (data.questionType === EQuestionType.Multi) {
      const userArr = (data.userAnswer || '').split(',').sort();
      const rightArr = (data.rightAnswer.answer || '').split(',').sort();
      return userArr.join(',') === rightArr.join(',');
    } else {
      return data.userAnswer === data.rightAnswer.answer;
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.stem_wrapper}>
        <div className={styles.ques_txt}>
          {
            data.stemVoiceUrl ? (
              <span className={styles.btn_audio} onClick={e => onVoiceClick(e, data.stemVoiceUrl)} />
            ) : null
          }
          <span className={styles.number}>{data.questionIndex+1}.</span>
          <div className={styles.text}>
            {
              data.stem ? (
                <div
                  className={styles.rich_text}
                  style={{ marginBottom:(data.images && data.images.length) ? 24 : 0 }}
                  dangerouslySetInnerHTML={{__html:data.stem}}
                />
              ) : null
            }
            {
              data.images && data.images.length>0 ? (
                <div className={styles.ques_image}>
                  {
                    data.images.map((optimg: any) => <img key={optimg.imageUrl} src={optimg.imageUrl} />)
                  }
                </div>
              ) : null
            }
          </div>
        </div>
      </div>
      <div className={`${styles.option_wrapper} ${data.questionType === EQuestionType.Code ? styles.code : ''}`}>
        {
          (data.optionList || []).map((option: any, optindex: number) => (
            <React.Fragment key={option.opt}>
              <div
                className={`${styles.ques_txt} ${styles.option} ${getOptActive(option.opt) ? styles.active : ''} ${isReview ? styles.disable : ''}`}
                onClick={() => {
                  if (isReview) {
                    return;
                  }
                  let answer = option.opt;
                  if (data.questionType === EQuestionType.Multi) {
                    const arr = data.userAnswer ? data.userAnswer.split(',') : [];
                    const idx = arr.indexOf(answer);
                    if (idx > -1) {
                      arr.splice(idx, 1);
                    } else {
                      arr.push(answer);
                    }
                    answer = arr.join(',');
                  }
                  onUserAnswer && onUserAnswer(answer);
                }}
              >
                {
                  option.optVoiceUrl ? (
                    <span className={styles.btn_audio} onClick={e => onVoiceClick(e, option.optVoiceUrl)} />
                  ) : null
                }
                <span className={styles.number}>{option.opt}.</span>
                <div className={styles.text}>
                  {
                    option.optTxt ? 
                      <div dangerouslySetInnerHTML={{__html:option.optTxt}} /> :
                      data.questionType===EQuestionType.Judge ? (JudgeOptText[option.opt]) : ''
                  }
                  {
                    option.optImageList && option.optImageList.length>0 ? (
                      <div className={styles.ques_image}>
                        {
                          option.optImageList.map((optimg: any) => <img key={optimg.imageUrl} src={optimg.imageUrl} />)
                        }
                      </div>
                    ) : null
                  }
                </div>
              </div>
            </React.Fragment>
          ))
        }
        {
          data.questionType === EQuestionType.Code ? (
            data.type === ESubjectType.Scratch ? (
              <>
                {
                  !isReview ? <div className={styles.savetip}>作答完毕，一定要点击保存！</div> : null
                }
                <div className={styles.frame_border}>
                  <iframe
                    frameBorder={0}
                    src={`${process.env.REACT_APP_SCRATCH_URL}${getSourceParam()}`}
                    width="100%"
                    height="100%"
                  />
                  {
                    isReview ? (
                      <iframe
                        frameBorder={0}
                        src={`${process.env.REACT_APP_SCRATCH_URL}&source=${encodeURIComponent(data.rightAnswer.answer)}&answer=right`}
                        width="100%"
                        height="100%"
                      />
                    ) : null
                  }
                </div>
              </>
            ) : (
              <div className={styles.code_wrapper}>
                <div className={styles.code_com}>
                  <div className={styles.codetitle}>
                    {isReview ? '考生答案：' : `在下方输入${data.type===ESubjectType.Python ? 'Python3' : 'C++'}代码：`}
                  </div>
                  <CodeMirror
                    editable={!isReview}
                    value={data.userAnswer ? data.userAnswer : (data.partCode ? data.partCode : '')}
                    width="calc((100vw - 328px) / 2)"
                    height="calc(100vh - 194px)"
                    // theme="dark"
                    extensions={[
                      data.type === ESubjectType.Cpp ? cpp() : python(),
                    ]}
                    onChange={(value: any) => {
                      if (isReview) {
                        return;
                      }
                      onUserAnswer && onUserAnswer(value);
                    }}
                  />
                </div>
                {
                  isReview ? (
                    <div className={styles.code_com}>
                      <div className={styles.codetitle}>参考答案：</div>
                      <CodeMirror
                        editable={!isReview}
                        value={data.rightAnswer.answer ? data.rightAnswer.answer : ''}
                        height="calc(100vh - 194px)"
                        // theme="dark"
                        extensions={[
                          data.type === ESubjectType.Cpp ? cpp() : python(),
                        ]}
                      />
                    </div>
                  ) : (
                    <div className={styles.code_com}>
                      <div className={styles.codetitle}>运行代码：</div>
                      <RunCode isMock={isMock} evaluation={evaluation} data={data} />
                    </div>
                  )
                }
              </div>
            )
          ) : null
        }
      </div>
      {/* 正确答案回显 */}
      {
        isReview ? (
          <div className={styles.review_wrapper}>
            {
              data.questionType === EQuestionType.Code ? <></>: (
                <div className={userAnswerCorrect() ? '' : styles.wrong}>
                  正确答案：{data.rightAnswer.answer}
                </div>
              )
            }
          </div>
        ) : null
      }
    </div>
  );
};
