import React from 'react';
import styles from './index.module.scss';

interface Iprop {

}

export const Loading = (props: Iprop) => {
  return (
    <div className={styles.wrapper}>
      <img src="https://static.cpa-test.cn/web-assets/image/submit_loading.gif" />
    </div>
  );
};
