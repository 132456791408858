import React from 'react';
import styles from './index.module.scss';

interface Iprop {

}

export const Article = (props: Iprop) => {
  return (
    <div className={styles.wrapper}>
      <h1>青少年编程能力等级评测（CPA）</h1>

      <h2>详情</h2>
      <p>青少年编程能力等级评测（CPA）是由中国青少年宫协会主办，为落实《新一代人工智能发展规划的通知》、《全民科学素质行动规划纲要》中关于青少年人工智能教育和青少年科学素质行动相关要求，推动青少年编程教育的普及发展。面向全国青少年群体的编程能力测评，是行业内青少年编程能力的权威认证。</p>

      <h2>组织机构：</h2>
      <p>
        主办单位：中国青少年宫协会<br/>
        合作单位：乾行讯科（北京）科技有限公司
      </p>

      <h2>测评目的：</h2>
      <p>青少年编程能力等级评测（CPA）致力于提升孩子对计算机思维力逻辑能力分析与判断的提升。通过对编程语言的理解和使用，提升孩子对于信息化逻辑判断与构思能力、以达到学习成绩提高的效果。</p>
      
      <h2>主办单位</h2>
      <p>中国青少年宫协会成立于1988年9月15日，是在国家民政部注册，由各类青少年宫、青年宫、少年宫、青少年活动中心、儿童活动中心、青少年科技馆、青少年活动营地等青少年活动场所和机构自愿结成的全国性、行业性社会团体，是非营利性社会组织。</p>

      <h2>官方网站</h2>
      <p>中国青少年宫协会2023年青少年编程能力等级测评活动启事</p>

      <h2>考务说明</h2>
      <h3>1 报考对象</h3>
      <p>青少年编程能力等级测评不设年龄限制，任何年龄阶段青少年均可报名。</p>
      <h3>2 考试时间</h3>
      <p>2023年度四次测评时间分别为3月25日、26日两天；5月27日、28日两天；9月23日、24日两天；12月9日、10日两天。</p>
      <h3>3 考试形式</h3>
      <p>考试方式：青少年编程能力等级测评活动将统一采取线上报名、线上测评的形式进行；采取个人报名和团体报名两种形式，参与测评的青少年请登录指定测评网站进行注册报名和测评。</p>
      <h3>4 证书发放</h3>
      <p>测评合格后将获得中国青少年工协会出具的测评等级证书。</p>
      <h3>5 考试科目</h3>
      <p>图形化编程1级、2级、3级；Python编程1级、2级、3级、4级；C++语言编程1级、2级、3级、4级、5级和机器人编程测评。</p>
      <h3>6 考试标准</h3>
      <p>
        《T/CERACU/AFCEC/SIA/CNYPA 100.1-2019青少年编程能力等级 第1部分：图形化编程》、<br />
        《T/CERACU/AFCEC/SIA/CNYPA 100.2-2019青少年编程能力等级 第2部分：Python编程》、<br />
        《T/CERACU/AFCEC 100.3-2020青少年编程能力等级 第3部分：机器人编程》、<br />
        《T/CERACU/AFCEC/SIA/ 100.4-2020青少年编程能力等级 第4部分：C++编程》
      </p>
      <h3>7 证书展示</h3>
      <p>
        <img src="http://cpa-prod.oss-cn-beijing.aliyuncs.com/web-assets/image/cpa-zhengshu-shili.png" />
      </p>
    </div>
  );
};
