import { Checkbox, message, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { AWSCslide } from '../../components/AWSC';
import md5 from 'js-md5';
import { getOrganizationList, getUserInfo, loginByPwd, loginBySmsCode, register, sendSms, updatePwd } from '../../utils/api';
import styles from './index.module.scss';
import { Input } from '../../components/input';
import { Profile } from './components/profile';
import { getQuery } from '../../utils/util';

interface LoginProps {
  onClose?: () => void;
  onLoginSuccess?: (data: any) => void;
}

export const Login = (props: LoginProps) => {
  const { onClose, onLoginSuccess } = props;
  const [formData, setFormData] = useState<any>({
    phone: { value: '', error: '' },
    password: { value: '', error: '' },
    smsCode: { value: '', error: '' },
    password2: { value: '', error: '' },
    password2Confirm: { value: '', error: '' },
  });
  const [awscInfo, setAwscInfo] = useState<any>({ sessionId: '', sig: '', token: '' });
  const [loginType, setLoginType] = useState(1); // 1-账号密码登录 2-手机验证码登录 3-注册 4-忘记密码
  const loginTypeName = ['账号登录', '手机号登录', '注册账号', '找回密码'];
  const loginBtnName = ['登录', '登录', '注册', '提交'];
  const [isLoging, setIsLoging] = useState(false);
  const [codeTimer, setCodeTimer] = useState(0); // 验证码倒计时
  const [showProfile, setShowProfile] = useState(false);
  const [agreeProfile, setAgreeProfile] = useState(false);
  const [organizationInfo, setOrganizationInfo] = useState<any>(null);

  useEffect(() => {
    const redirect = getQuery('redirect');
    if (redirect) {
      const tmparr = redirect.split('?');
      if (tmparr.length > 1) {
        const parr = tmparr[1].split('&');
        let organizationId = parr.find(item => item.includes('organizationId'));
        if (organizationId) {
          organizationId = organizationId.split('=')[1];
          organizationId && getOrganizationData(organizationId);
        }
      }
    }
  }, []);

  const getOrganizationData = (organizationId: string) => {
    getOrganizationList({}).then((result: any) => {
      const curOrg = (result.body || []).find((org: any) => org.id === parseInt(organizationId));
      setOrganizationInfo(curOrg);
    }).catch((error: any) => {
      console.log('获取机构列表失败', error);
    });
  };

  const canLogin = () => {
    if (loginType === 1) {
      const { sessionId, sig, token } = awscInfo;
      if (!sessionId || !sig || !token) {
        return false;
      }
      return formData.phone.value.trim() && formData.password.value.trim();
    }
    if (loginType === 2) {
      return formData.phone.value.trim() && formData.smsCode.value.trim();
    }
    if ([3, 4].includes(loginType)) {
      const valied =
        formData.phone.value.trim() &&
        formData.smsCode.value.trim() &&
        formData.password2.value.trim() &&
        formData.password2Confirm.value.trim();
      return valied;
    }
  };

  const onSmsCodeClick = () => {
    const phone = formData.phone.value;
    if (phone.length !== 11) {
      setFormData({ ...formData, phone: { value: phone, error: '手机号格式有误' } });
      return;
    }
    const scene: any = {
      2: 'LOGIN',
      3: 'REGISTER',
      4: 'UPDATE_PWD'
    }
    const param = {
      ...awscInfo,
      phone,
      scene: scene[loginType],
    };
    sendSms(param).then(() => {
      message.success('短信已发送');
      startSmsTimer();
    }).catch((error: any) => {
      message.error(error.msg);
      window.nc.reset();
      setAwscInfo({ sessionId: '', sig: '', token: '' });
    });
  };

  // 验证码倒计时
  const startSmsTimer = () => {
    const timeFunc = (t: number) => {
      setTimeout(() => {
        if (t > 0) {
          setCodeTimer(t - 1);
          timeFunc(t - 1);
        } else {
          console.log('倒计时结束', t);
        }
      }, 1000);
    };
    const time = 60;
    setCodeTimer(time);
    timeFunc(time);
  };

  /** 点击登录/注册/提交 */
  const onLoginClick = () => {
    const phone = formData.phone.value;
    const pwd = formData.password.value.trim();
    const smsCode = formData.smsCode.value;
    const pwd2 = formData.password2.value.trim();
    const pwd2Cfm = formData.password2Confirm.value.trim();
    // 验证入参合法性
    if (phone.length !== 11) {
      setFormData({ ...formData, phone: { value: phone, error: '手机号格式有误' } });
      return;
    }
    if ([2,3,4].includes(loginType)) {
      if (smsCode.length !== 6) {
        setFormData({ ...formData, smsCode: { value: smsCode, error: '验证码格式有误' } });
        return;
      }
    }
    if ([3, 4].includes(loginType)) {
      const match1 = pwd2.match(/[A-Za-z]/g);
      const match2 = pwd2.match(/\d/g);
      if (pwd2.length < 8 || match1 === null || match2 === null) {
        setFormData({ ...formData, password2: { value: pwd2, error: '请输入8～18位数字+字母组合' } });
        return;
      }
      if (pwd2 !== pwd2Cfm) {
        setFormData({ ...formData, password2Confirm: { value: pwd2Cfm, error: '两次输入密码不一致' } });
        return;
      }
    }
    if (loginType === 3 && !agreeProfile) {
      message.error('请阅读并同意《用户服务协议》');
      return;
    }
    // 根据类型组织参数
    let param: any = { phone };
    if (loginType === 1) {
      param = { ...param, pwd: md5(pwd), ...awscInfo };
    }
    if (loginType === 2) {
      param.smsCode = smsCode;
    }
    if ([3, 4].includes(loginType)) {
      param.smsCode = smsCode;
      param.pwd = md5(pwd2);
    }
    setIsLoging(true);
    const apiMethod = [
      loginByPwd,
      loginBySmsCode,
      register,
      updatePwd,
    ][loginType - 1];
    apiMethod(param).then((result: any) => {
      if ([1,2].includes(loginType)) {
        localStorage.setItem('token', result.body.token);
        doGetUserInfo(phone);
      } else {
        setIsLoging(false);
        message.success(`${loginType===3 ? '注册' : '修改密码'}成功`);
        setLoginType(1);
        resetFormData();
      }
    }).catch((error: any) => {
      setIsLoging(false);
      window.nc.reset();
      setAwscInfo({ sessionId: '', sig: '', token: '' });
      message.error(error.msg);
    });
  };

  const doGetUserInfo = (phone: any) => {
    getUserInfo({}).then((result: any) => {
      setIsLoging(false);
      onLoginSuccess && onLoginSuccess({ id: result.body.userId, telphone: phone });
      message.success('登录成功');
    }).catch((error: any) => {
      setIsLoging(false);
      window.nc.reset();
      setAwscInfo({ sessionId: '', sig: '', token: '' });
      message.error(error.msg);
    });
  };

  const resetFormData = () => {
    setFormData({
      phone: { value: '', error: '' },
      password: { value: '', error: '' },
      smsCode: { value: '', error: '' },
      password2: { value: '', error: '' },
      password2Confirm: { value: '', error: '' },
    });
    window.nc.reset();
    setAwscInfo({ sessionId: '', sig: '', token: '' });
  };
  
  return (
    <div className={styles.wrapper}>
      <div className={`${styles.panel} ${[3,4].includes(loginType) ? styles.big : ''}`}>
        <div className={styles.btn_close} onClick={() => onClose && onClose()} />
        <div className={styles.content}>
          <div className={styles.logo} />
          <div className={styles.form_title}>
            <div className={styles.main}>{loginTypeName[loginType-1]}</div>
            {
              organizationInfo ? (
                <div className={styles.summary}>测评人员报名机构通道：<span>{organizationInfo.name}</span></div>
              ) : null
            }
          </div>
          <Input
            label={[3,4].includes(loginType) ? '手机号' : ''}
            icon="phone"
            type="number"
            placeholder="请输入手机号"
            maxLength={11}
            value={formData.phone.value}
            onChange={(value: string) => {
              setFormData({ ...formData, phone: { value, error: ''} });
            }}
            errorInfo={formData.phone.error}
          />
          {
            loginType === 1 ? (
              <Input
                icon="pwd"
                placeholder="请输入密码"
                type="password"
                maxLength={18}
                value={formData.password.value}
                onChange={(value: string) => {
                  setFormData({ ...formData, password: { value, error: ''} });
                }}
                errorInfo={formData.password.error}
              />
            ) : null
          }
          <div className={styles.row} style={{marginBottom:24}}>
            <div className={styles.space} />
            <div>
              <AWSCslide
                appkey={process.env.REACT_APP_AWSC_APPKEY || ''}
                scene={process.env.REACT_APP_AWSC_SCENE || ''}
                success={data => {
                  setAwscInfo(data);
                }}
              />
            </div>
          </div>
          {
            [2,3,4].includes(loginType) ? (
              <Input
                label={[3,4].includes(loginType) ? '验证码' : ''}
                icon="smscode"
                type="number"
                placeholder="请输入验证码"
                maxLength={6}
                value={formData.smsCode.value}
                onChange={(value: string) => {
                  setFormData({ ...formData, smsCode: { value, error: ''} });
                }}
                errorInfo={formData.smsCode.error}
                noMargin={[1,2].includes(loginType)}
              >
                <div
                  className={`
                    ${styles.btn_smscode}
                    ${(formData.phone.value.trim() && awscInfo.token && codeTimer===0) ? '' : styles.disable}
                  `}
                  onClick={onSmsCodeClick}
                >
                  { codeTimer>0 ? `${codeTimer}s重新获取` : '获取验证码' }
                </div>
              </Input>
            ) : null
          }
          {
            [3,4].includes(loginType) ? (
              <>
                <Input
                  label={[3,4].includes(loginType) ? (loginType===3 ? '密码' : '新密码') : ''}
                  icon="pwd"
                  placeholder="请输入密码"
                  type="password"
                  maxLength={18}
                  value={formData.password2.value}
                  onChange={(value: string) => {
                    setFormData({ ...formData, password2: { value, error: ''} });
                  }}
                  errorInfo={formData.password2.error}
                />
                <Input
                  label={[3,4].includes(loginType) ? '确认密码' : ''}
                  icon="pwd"
                  placeholder="请再次输入密码"
                  type="password"
                  maxLength={18}
                  value={formData.password2Confirm.value}
                  onChange={(value: string) => {
                    setFormData({ ...formData, password2Confirm: { value, error: ''} });
                  }}
                  errorInfo={formData.password2Confirm.error}
                />
              </>
            ) : null
          }
          <div className={styles.row} style={{marginTop:40}}>
            <div className={styles.space} />
            <div>
              <div
                className={`${styles.btn_login} ${(canLogin() && !isLoging) ? '' : styles.disable}`}
                onClick={onLoginClick}
              >
                { isLoging ? `${loginBtnName[loginType-1]}中...` : `${loginBtnName[loginType-1]}` }
              </div>
              <div className={styles.profile_wrapper}>
                {
                  loginType !== 4 ? (
                    <>
                      {
                        loginType === 3 ?
                          <Checkbox
                            style={{marginRight:10}}
                            checked={agreeProfile}
                            onChange={(evt: any) => setAgreeProfile(evt.target.checked)}
                          /> :
                          null
                      }
                      {
                        loginType === 3 ? '我已阅读并同意' : '登录即代表同意'
                      }
                      <a className={styles.profile} target="_blank" href="https://static.cpa-test.cn/web-assets/pdf/service.pdf">《用户服务协议》</a>
                      <a className={styles.profile} target="_blank" href="https://static.cpa-test.cn/web-assets/pdf/privicy.pdf">《用户隐私政策》</a>
                    </>
                  ) : null
                }
              </div>
              <div className={styles.btn_group}>
                <div
                  className={styles.btn}
                  onClick={() => {
                    setLoginType(loginType===1 ? 2 : 1);
                    resetFormData();
                  }}
                >
                  {loginType===1 ? '手机号验证登录' : '账号密码登录'}
                </div>
                <div style={{flex:1}} />
                {
                  [1,2].includes(loginType) ? (
                    <div
                      className={styles.btn}
                      onClick={() => {
                        setLoginType(3);
                        resetFormData();
                      }}
                    >
                      注册账号
                    </div>
                  ) : null
                }
                {
                  [1].includes(loginType) ? (
                    <div
                      className={styles.btn}
                      style={{marginLeft:16}}
                      onClick={() => {
                        setLoginType(4);
                        resetFormData();
                      }}
                    >
                      忘记密码
                    </div>
                  ) : null
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* 用户隐私协议 */}
      <Modal
        width={900}
        visible={showProfile}
        cancelText="关闭"
        okText={loginType===3 ? '已阅读并同意' : ''}
        mask={false}
        maskClosable={false}
        bodyStyle={{padding:0}}
        onCancel={() => setShowProfile(false)}
        onOk={() => {
          setShowProfile(false);
          if (loginType === 3) {
            setAgreeProfile(true);
          }
        }}
      >
        <Profile />
      </Modal>
    </div>
  );
};
