import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';

interface Iprop {
  totalTime: number;
  className?: string;
  onTimeup?: () => void;
}

let currentTime = 0;
let _timer: any;

const formatLeftTime = (time: number) => {
  let hour = Math.floor(time / 3600);
  const hourStr = hour > 9 ? hour.toString() : `0${hour}`;
  const minute = Math.floor((time - hour * 3600) / 60);
  const minuteStr = minute > 9 ? minute.toString() : `0${minute}`;
  const second = time - hour * 3600 - minute * 60;
  const secondStr = second > 9 ? second.toString() : `0${second}`;
  return `${hourStr}:${minuteStr}:${secondStr}`;
};

export const Timer = (props: Iprop) => {
  const { totalTime, className, onTimeup } = props;
  const [leftTimeStr, setLeftTimeStr] = useState(formatLeftTime(totalTime));

  useEffect(() => {
    // 启动倒计时
    currentTime = totalTime;
    if (currentTime > 0) {
      _timer = setTimeout(onTimer, 1000);
    }
    return () => {
      if (_timer) {
        clearTimeout(_timer);
      }
    }
  }, []);

  const onTimer = () => {
    currentTime--;
    setLeftTimeStr(formatLeftTime(currentTime));
    if (currentTime > 0) {
      _timer = setTimeout(onTimer, 1000);
    } else {
      onTimeup && onTimeup();
    }
  };

  return (
    className ? (
      <div className={className}>{leftTimeStr}</div>
    ) : (
      <div className={styles.wrapper}>{leftTimeStr}</div>
    )
  );
};
