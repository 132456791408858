import React from 'react';
import styles from './index.module.scss';
      
export const Empty = (props: any) => {
  return (
    <div className={styles.wrapper}>
      <img src="https://static.cpa-test.cn/web-assets/image/icon-404.png" />
      <span>暂未开放</span>
    </div>
  );
};
