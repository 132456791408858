import React, { useEffect, useRef, useState } from 'react';
import styles from './index.module.scss';
import { Swiper } from '../../components/swiper';
import { getHomeBanner, getOrganizationList } from '../../utils/api';
import { useLocation, useNavigate } from 'react-router-dom';
import { getQuery } from '../../utils/util';

/** 首页模块标题 */
function ModuleTitle (prop: {title:string, id?:string}) {
  const { title, id } = prop;
  return (
    <div className={styles.module_title} id={id}>
      <span className={styles.line} />
      <span className={styles.icon} style={{marginLeft:27}} />
      <span className={styles.title}>{title}</span>
      <span className={styles.icon} style={{marginRight:27}} />
      <span className={styles.line} />
    </div>
  );
}

const effectCompleted: any[] = [];
let organizationCount = 0;

/** 首页 */
export default function Home (props: any) {
  const [homeInfo, setHomeInfo] = useState<any>({
    banner: [
      {
        id: 1,
        image_url: 'https://static.cpa-test.cn/web-assets/image/home_top_banner315.png',
        jump_url: 'http://www.cnypa.org/hdxq/2080945.jhtml'
      },
      {
        id: 1,
        image_url: 'https://static.cpa-test.cn/web-assets/image/index-banner-result-publish.png',
        jump_url: '/examination'
      }
    ],
    advert: [
      {
        id: 1,
        image_url: 'https://static.cpa-test.cn/web-assets/image/home_middle_banner.png',
        jump_url: ''
      },
    ],
  });
  const [banners, setBanners] = useState<any[]>([]);
  const [adverts, setAdverts] = useState<any[]>([]);
  const [organizations, setOrganizations] = useState<any[]>([]);
  const [orgAddItems, setOrgAddItems] = useState<any[]>([]);
  let organizaition = useRef<any>(null);
  let testScince = useRef<any>(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    getOrganizationData();
    getHomeBannerData(0);
    getHomeBannerData(1);
    window.onresize = onWindowResize;
    window.onscroll = onWindowScroll;
    return () => {
      window.onresize = null;
      window.onscroll = null;
    };
  }, []);

  useEffect(() => {
    const posto = getQuery('posto');
    if (posto) {
      const postoDom = document.getElementById(posto);
      if (postoDom) {
        postoDom.scrollIntoView();
      }
    }
  }, [location.search]);

  useEffect(() => {
    if (organizations.length > 0) {
      onWindowScroll();
      onWindowResize();
    }
  }, [organizations.length]);

  const getOrganizationData = () => {
    getOrganizationList({}).then((result: any) => {
      organizationCount = result.body.length;
      setOrganizations(result.body);
    }).catch((error: any) => {
      console.log('获取机构列表失败', error);
    });
  };

  const getHomeBannerData = (type:number) => {
    getHomeBanner({ type }).then((result: any) => {
      if (type === 0) {
        setBanners(result.body || []);
      } else {
        setAdverts(result.body || []);
      }
    }).catch((error: any) => {
      console.log('获取轮播图失败', error);
    });
  }

  const onWindowScroll = () => {
    if (organizaition.current && effectCompleted.indexOf(organizaition.current) === -1) {
      doDomEffect(organizaition.current);
    }
    if (effectCompleted.indexOf(testScince.current) === -1) {
      doDomEffect(testScince.current);
    }
  }

  const doDomEffect = (dom: any) => {
    const windowHeight = document.documentElement.clientHeight;
    const divTop = dom.getBoundingClientRect().top;
    const offset = dom.clientHeight / 3;
    if (windowHeight - divTop >= offset) {
      const childNodes = dom.childNodes;
      childNodes.forEach((node: any) => {
        node.style.transition = `all .8s ${Math.random()}s`;
        node.style.opacity = 1;
        node.style.transform = 'translateY(0px)';
      });
      effectCompleted.push(dom);
    }
  }

  const onWindowResize = () => {
    const parentWidth = document.body.clientWidth - 80;
    const rowCount = Math.floor(parentWidth / 328);
    const lastCount = organizationCount % rowCount;
    let addItems = [];
    if (lastCount > 1) {
      const addCount = rowCount - lastCount;
      addItems = new Array(addCount).fill({});
    }
    console.log('addItems', addItems);
    setOrgAddItems(addItems);
  };

  return (
    <div className={styles.wrapper}>
      {/* 顶部轮播 */}
      <Swiper
        data={banners}
      />
      {/* 广告轮播 */}
      <div className={styles.advert_wrapper}>
        <Swiper
          borderRadius={12}
          data={adverts}
        />
      </div>
      {/* 团体报名通道 */}
      <ModuleTitle title="团体报名通道" id="groupchannel" />
      {
        organizations && organizations.length > 0 ? (
          <div className={styles.organizations} ref={organizaition}>
            {
              organizations.map((item: any) => (
                <div
                  className={styles.org_item}
                  key={item.id}
                  onClick={() => {
                    const token = localStorage.getItem('token');
                    const isLogin = token !== null && token !== undefined && token != '';
                    const toRoute = `/enter-online?oranization=1&organizationId=${item.id}`;
                    if (!isLogin) {
                      window.location.replace(`/?redirect=${encodeURIComponent(toRoute)}`);
                      return;
                    }
                    navigate(toRoute);
                  }}
                >
                  <img src={item.imageUrl} />
                </div>
              ))
            }
            {
              orgAddItems.map((item: any, index: number) => (
                <div className={styles.org_space} key={index} />
              ))
            }
          </div>
        ) : null
      }
      {/* 证书样式 */}
      <ModuleTitle title="证书样式" />
      <div className={styles.certificate}>
        <img src="https://static.cpa-test.cn/web-assets/image/home_zhengshu.png" />
      </div>
      {/* 测评科技 */}
      <ModuleTitle title="测评科技" />
      <div className={styles.test_scince} ref={testScince}>
        {/* <div className={styles.card}>
          <div className={styles.card_content}>
            <img className={styles.cover} src="https://static.cpa-test.cn/web-assets/image/img_index_intro_card1%402x.png" />
            <div className={styles.title}>个性化测评报告</div>
            <p className={styles.summary}>通过完整的过程和结果评价，为每位参评人生成个性化测评报告，使参评人直观了解自己的编程能力。</p>
          </div>
        </div> */}
        <div className={styles.card}>
          <div className={styles.card_content}>
            <img className={styles.cover} src="https://static.cpa-test.cn/web-assets/image/home_scene_card1-1.png" />
            <div className={styles.title}>活体检测</div>
            <p className={styles.summary}>进入测评时进行人脸识别，保证参评人和报名所用证件一致，测评全程人脸识别+动态活体检测，避免作弊。</p>
          </div>
        </div>
        <div className={styles.card}>
          <div className={styles.card_content}>
            <img className={styles.cover} src="https://static.cpa-test.cn/web-assets/image/home_scene_card2.png" />
            <div className={styles.title}>切屏监控</div>
            <p className={styles.summary}>测评过程中离开测评界面会自动记录屏幕画面，帮助监考老师判断是否存在作弊行为。</p>
          </div>
        </div>
        <div className={styles.card}>
          <div className={styles.card_content}>
            <img className={styles.cover} src="https://static.cpa-test.cn/web-assets/image/home_scene_card3.png" />
            <div className={styles.title}>AI辅助读题</div>
            <p className={styles.summary}>为低年龄的参评青少年提供AI读题功能，避免由于某些字不认识而影响测评的发挥。</p>
          </div>
        </div>
      </div>
    </div>
  )
}
