import { Cascader, message } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "../../../../components/button";
import { Input } from "../../../../components/input";
import { getCitys, getUserInfo, updateAddress } from "../../../../utils/api";
import styles from './index.module.scss';

interface Iprop {
  showCancel?: boolean;
  onSuccess?: (value?:any) => void;
  onCancel?: () => void;
  tipText?: string;
}

export const EditAddress = (props: Iprop) => {
  const { showCancel=false, onSuccess, onCancel, tipText } = props;
  const userInfo = useSelector((state: any) => state.userInfo);
  const [cityData, setCityData] = useState<any[]>([]);
  const [contactInfo, setContactInfo] = useState<any>({
      province: '',
      city: '',
      area: '',
      address: '',
      liaison: '',
      liaisonPhone: '',
  });

  useEffect(() => {
    getUserDetail();
    getCitys().then((result: any) => {
      setCityData(result.cityData);
    });
  }, []);
    
  const getUserDetail = () => {
    getUserInfo({}).then((result: any) => {
      const { province, city, area, address, liaison, liaisonPhone } = result.body;
      setContactInfo({
        province: province || '',
        city: city || '',
        area: area || '',
        address: address || '',
        liaison: liaison || '',
        liaisonPhone: liaisonPhone || '',
      });
    }).catch((error: any) => {
      console.log('获取用户信息失败', error.msg);
    });
  };
    
  const onEditAddress = () => {
    if (!contactInfo.province) {
      message.error('请选择地区');
      return;
    }
    if (!contactInfo.address) {
      message.error('请输入详细地址');
      return;
    }
    if (!contactInfo.liaison) {
      message.error('请输入联系人');
      return;
    }
    if (contactInfo.liaisonPhone.length !== 11) {
      message.error('请输入正确的联系人号码');
      return;
    }
    const param = {
      userId: userInfo.id,
      ...contactInfo
    };
    updateAddress(param).then(() => {
      message.success('修改成功');
      onSuccess && onSuccess(contactInfo);
    }).catch((error: any) => message.error(error.msg));
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.form_item}>
        <div className={styles.input_border}>
          <div className={styles.label}>选择地区</div>
          <div className={styles.content}>
            <Cascader
              fieldNames={{ value: 'label' }}
              style={{width:320}}
              options={cityData}
              allowClear={false}
              onChange={(value: any) => {
                setContactInfo({
                  ...contactInfo,
                  province: value[0],
                  city: value[1],
                  area: value[2],
                });
              }}
              placeholder="选择省/市/区(县)"
              value={contactInfo.province ? [contactInfo.province, contactInfo.city, contactInfo.area] : undefined}
            />
          </div>
          <div className={styles.error}></div>
        </div>
      </div>
      <div className={styles.form_item}>
        <Input
          label="详细住址"
          maxLength={200}
          labelWidth={100}
          mini
          value={contactInfo.address}
          errorInfo=""
          onChange={(value: any) => setContactInfo({ ...contactInfo, address: value })}
        />
      </div>
      <div className={styles.form_item}>
        <Input
          label="联系人"
          maxLength={50}
          labelWidth={100}
          mini
          value={contactInfo.liaison}
          errorInfo=""
          onChange={(value: any) => setContactInfo({ ...contactInfo, liaison: value })}
        />
      </div>
      <div className={styles.form_item}>
        <Input
          label="联系人号码"
          type="number"
          maxLength={11}
          labelWidth={100}
          mini
          value={contactInfo.liaisonPhone}
          errorInfo=""
          onChange={(value: any) => setContactInfo({ ...contactInfo, liaisonPhone: value })}
        />
      </div>
      <div className={styles.btn_group}>
        <Button
          text="保存"
          size="big"
          width={showCancel ? 120 : 320}
          onClick={onEditAddress}
        />
        {
          showCancel ? (
            <Button
              text="取消"
              size="big"
              type="outgrayline"
              width={120}
              style={{marginLeft:8}}
              onClick={onCancel}
            />
          ) : null
        }
      </div>
      <div className={styles.btn_group}>
        <div className={styles.tip_text}>{tipText || '该联系地址将用于测评成绩合格后的证书邮寄，请认真填写。'}</div>
      </div>
    </div>
  );
};
