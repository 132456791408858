import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { menus } from './data';
import styles from './index.module.scss';

interface Iprop {
  onLogin?: () => void;
  onLogout?: () => void;
  onMenuChange?: (value: any) => void;
  easyMode?: boolean;
  centerChildren?: any;
  rightChildren?: any;
}

export const NavigationBar = (props: Iprop) => {
  const { onLogin, onLogout, onMenuChange, easyMode=false, centerChildren, rightChildren } = props;
  const userInfo: any = useSelector((state: any) => state.userInfo);

  const navigate = useNavigate();
  const location = useLocation();

  let menuIndex = -1;
  let childMenuIndex = 0;
  for (let i=0; i<menus.length; i++) {
    if (menus[i].route === location.pathname) {
      menuIndex = i;
      break;
    } else if (menus[i].children && menus[i].children.length) {
      for (let j=0; j<menus[i].children.length; j++) {
        if (menus[i].children[j].route === location.pathname+location.search) {
          menuIndex = i;
          childMenuIndex = j;
          break;
        }
      }
    }
  }

  const onMenuClick = (menu: any) => {
    onMenuChange && onMenuChange(menu);
  }

  const onLoginClick = () => {
    onLogin && onLogin();
  };

  const onLogoutClick = () => {
    onLogout && onLogout();
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.logo} />
      {
        !easyMode ? (
          <>
            <div className={styles.menu_bar}>
              {
                menus.map((menu: any, index: number) => (
                  <div
                    className={
                      `${styles.menu}
                      ${menuIndex === index ? styles.active : ''}
                      ${menu.children && menu.children.length ? styles.drop : ''}`
                    }
                    key={menu.route}
                    onClick={() => onMenuClick(menu)}
                  >
                    <span>{menu.name}</span>
                    {
                      menu.children && menu.children.length ? (
                        <div className={styles.child_menu}>
                          {
                            menu.children.map((child: any, cindex: number) => (
                              <li
                                className={(menuIndex===index && childMenuIndex===cindex) ? styles.active : ''}
                                key={child.route}
                                onClick={(evt: any) => {
                                  evt.stopPropagation();
                                  onMenuClick(child);
                                }}
                              >
                                {child.name}
                              </li>
                            ))
                          }
                        </div>
                      ) : null
                    }
                  </div>
                ))
              }
            </div>
            <div className={styles.btn_bar}>
              <div className={styles.telphone}>
                咨询电话<br />
                <a href="tel:4009903330">4009903330</a>
              </div>
              <div
                className={`${styles.btn_login} ${styles.red}`}
                onClick={() => onMenuClick({ route: '/env-check' })}
              >
                考前环境检测
              </div>
              {
                userInfo.id ? (
                  <div className={styles.user_info}>
                    <div className={styles.avator} />
                    <span
                      className={styles.user_phone}
                      // onClick={() => navigate('/exam-guide')}
                    >
                      {userInfo.telphone}
                    </span>
                    <div className={styles.child_menu}>
                      <li onClick={() => navigate('/exam-guide')}>
                        <span className={`${styles.icon} ${styles.testcenter}`} />测评中心
                      </li>
                      <li onClick={onLogoutClick}>
                      <span className={`${styles.icon} ${styles.logout}`} />退出登录
                      </li>
                    </div>
                    {/* <div className={styles.line} /> */}
                    {/* <div className={styles.btn_logout} onClick={onLogoutClick}>退出登录</div> */}
                  </div>
                ) : (
                  <div className={styles.btn_login} onClick={onLoginClick}>登录/注册</div>
                )
              }
            </div>
          </>
        ) : (
          <>
            <div className={styles.center_wrapper}>{centerChildren}</div>
            <div className={styles.right_wrapper}>{rightChildren}</div>
          </>
        )
      }
    </div>
  );
};
